import React, { useState, useEffect } from 'react'
import { Checkbox, Button, Modal, Fade, Backdrop } from '@material-ui/core'

import fetchData from '../../utility/endpointFetch'

import HierarchyRow from './HierarchyRow'
import MachineActions from './MachineActions'

import { getAppStyles } from '../App/AppStyles'
import { getHierarchyStyles } from './HierarchyStyles'
import categoryInfo from '../../StaticData/categoryInfo'

const getSubCategories = (rawCategoryData, orderedCategories, id) =>
  rawCategoryData
    .filter(c => c.parent_id === id)
    .map(c => ({
      id: c.group_id,
      name: c.group_name,
      notes: c.notes,
      subCategories: getSubCategories(rawCategoryData, orderedCategories, c.group_id)
    }))

const getOrderedCategories = rawCategoryData => {
  const orderedCategories = []
  rawCategoryData
    .filter(c => c.parent_id === 0)
    .forEach(c => {
      orderedCategories.push({
        id: c.group_id,
        name: c.group_name,
        notes: c.notes,
        subCategories: getSubCategories(rawCategoryData, orderedCategories, c.group_id)
      })
    })
  return orderedCategories
}

const saveConfigurationMask = (setSaveModalOpen, machineId, selected) => {
  setSaveModalOpen(true)
  const fetchUrl = `/securityconfigurations/mask`
  const fetchParams = {
    method: 'POST',
    dataType: 'json',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      machine_id: machineId,
      mask_secids: selected
    })
  }
  ;(async () => {
    await fetchData(fetchUrl, 'Error sending save mask configuration request', fetchParams).promise
  })()
}

const Hierarchy = props => {
  const {
    rawCategoryData,
    leafRows,
    mitigate,
    scan,
    setRollBackTypeValue,
    selected,
    setSelected,
    machineId
  } = props
  const classes = getAppStyles()
  const hierarchyClasses = getHierarchyStyles()
  const [orderedCategories, setOrderedCategories] = useState([])
  const [categoryRow, setCategoryRow] = useState()
  const [categoryInfoOpen, setCategoryInfoOpen] = useState(false)
  const [saveModalOpen, setSaveModalOpen] = useState(false)

  useEffect(() => {
    setOrderedCategories(getOrderedCategories(rawCategoryData))
  }, [rawCategoryData])

  return (
    <div className={hierarchyClasses.Hierarchy__base}>
      <div className={hierarchyClasses.Hierarchy__actionsWrapper}>
        <div>
          <Checkbox
            onChange={e => setSelected(e.target.checked ? leafRows.map(l => l.secid) : [])}
            checked={selected.length === leafRows.length}
            color='primary'
          />
          <span>Enforce All</span>
          <Button
            size='small'
            variant='contained'
            color='primary'
            style={{ marginLeft: '1rem' }}
            onClick={() => saveConfigurationMask(setSaveModalOpen, machineId, selected)}
          >
            Save Selections
          </Button>
        </div>
        <MachineActions mitigate={mitigate} scan={scan} setRollBackTypeValue={setRollBackTypeValue} />
      </div>
      {orderedCategories.map(c => (
        <HierarchyRow
          key={c.id}
          {...{ selected, setSelected, leafRows, setCategoryRow, setCategoryInfoOpen }}
          category={c}
          level={0}
          hiddenFromAbove={false}
        />
      ))}
      <MachineActions mitigate={mitigate} scan={scan} setRollBackTypeValue={setRollBackTypeValue} />
      <Modal
        aria-labelledby='category-info-modal-title'
        aria-describedby='category-info-modal-description'
        className={classes.modal}
        open={categoryInfoOpen}
        onClose={() => setCategoryInfoOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={categoryInfoOpen}>
          <div className={classes.modalPaper}>
            <h2 id='category-info-modal-title'>{categoryRow && categoryInfo[categoryRow.id].title}</h2>
            <p id='category-info-modal-description' style={{ fontSize: '1rem' }}>
              {categoryRow && categoryInfo[categoryRow.id].content}
            </p>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby='spring-modal-title'
        aria-describedby='spring-modal-description'
        className={classes.modal}
        open={saveModalOpen}
        onClose={() => {
          setSaveModalOpen(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={saveModalOpen}>
          <div className={classes.modalPaper}>
            <h2 id='spring-modal-title'>Security Configuration Mask Submitted!</h2>
            <p id='spring-modal-description' style={{ fontSize: '1rem' }}>
              The configuration you save for this machine will be used for all future operations on this machine.
              <br /><br />
              If you wish to save a new configuration, just make your changes and save again. The existing configuration
              will be replaced.
              <br /><br />
              If you decide to restore this machine&apos;s configuration to defaults, uncheck all boxes and save again.
              The existing configuration will be discarded.
            </p>
            <Button
              variant='contained'
              style={{ backgroundColor: '#128C01', color: '#FFFFFF', maxWidth: '300px', marginTop: '10px' }}
              size='small'
              onClick={() => setSaveModalOpen(false)}
            >
              Continue In Security Report
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default Hierarchy
