import React, { useState, useEffect } from 'react'
import {
  Container,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress
} from '@material-ui/core'

import fetchData from '../../utility/endpointFetch'
import Footer from '../Footer'
import { getAppStyles } from '../App/AppStyles'
import PageTitle from '../PageTitle'
import DisplaySubscriptionDetails from './DisplaySubscriptionDetails'

const Payments = () => {
  const classes = getAppStyles()
  const [isLoading, setIsLoading] = useState(true)
  const [subscriptionDetails, setSubscriptionDetails] = useState(false)

  useEffect(() => {
    const { promise, cancel } = fetchData('/payments/subscription', 'Error getting subscription details')
    ;(async () => {
      const response = await promise
      setSubscriptionDetails(response.stripe_subscription_details)
      setIsLoading(false)
    })()
    return cancel
  }, [])

  return (
    <>
      <Container maxWidth='xl' className={classes.container}>
        <Paper className={classes.paper}>
          <PageTitle title='Payment Management' />
          <Typography style={{ marginTop: '2rem' }}>
            We offer a subscription based on the number of machines you manage through our service.  You are billed monthly, so you will receive a bill one month after you start
            your service based on the max number of machines managed within our service.  We will accept your credit card at the beginning of the subscription period and that card
            will be billed for our services at the end of each subscription period.
          </Typography>
          <Table style={{ marginTop: '2rem' }}>
            <TableHead>
              <TableRow>
                <TableCell>number of machines</TableCell>
                <TableCell>cost per machine</TableCell>
                <TableCell>flat fee</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1-10</TableCell>
                <TableCell>$0</TableCell>
                <TableCell>$10.00</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>10-50</TableCell>
                <TableCell>$1.00</TableCell>
                <TableCell>$0</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>51-100</TableCell>
                <TableCell>$.95</TableCell>
                <TableCell>$0</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>101-250</TableCell>
                <TableCell>$.90</TableCell>
                <TableCell>$0</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>250+</TableCell>
                <TableCell colSpan={2}>Contact Us</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} align='center'>
                  {isLoading ? <CircularProgress /> : <DisplaySubscriptionDetails subscription_details={subscriptionDetails} /> }
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Container>
      <Footer />
    </>
  )
}

export default Payments
