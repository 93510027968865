import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Container,
  Paper,
  Typography,
  Grid,
  Button,
  Modal,
  Backdrop,
  TableBody,
  Fade,
  Table,
  TableRow,
  TableCell,
  TextField,
  MenuItem
} from '@material-ui/core'
import { usePaymentInputs, PaymentInputsWrapper } from 'react-payment-inputs/es/'
import images from 'react-payment-inputs/images'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'

import PageTitle from '../PageTitle'
import Footer from '../Footer'
import { login } from '../Auth/auth'
import { stateNameList } from '../../StaticData/stateNameList'
import { stateAbbreviationList } from '../../StaticData/stateAbbreviationList'
import hofs from '../../utility/hofs'
import fetchData from '../../utility/endpointFetch'
import { getAppStyles } from '../App/AppStyles'

// successful payment cc = 4242424242424242
// failed payment cc  = 4000000000009995
// requires authentication cc = 4000002500003155

const address1ErrorMessage = 'Business address is required'
const cityErrorMessage = 'City is required'
const stateErrorMessage = 'State is required'
const postalCodeErrorMessage = 'Postal code is required'
const nameOnCardErrorMessage = 'Insert name on card'
const creditCardNumberErrorMessage = 'Insert your credit card number'
const expiryErrorMessage = 'Insert your credit card expiration date'
const cvcNumberErrorMessage = 'Insert CVC number from the back of your credit card'

const PaymentsStart = ( {} ) => {
  const classes = getAppStyles()
  const history = useHistory()

  const [creditCardNumber, setCreditCardNumber] = useState('')
  const [expiry, setExpiry] = useState('')
  const [cvcNumber, setcvcNumber] = useState('')
  const [processingErrorMessage, setProcessingErrorMessage] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [processingPayment, setProcessingPayment] = useState(false)

  const [creditCardNumberError, setCreditCardNumberError] = useState(false)
  const [expiryError, setExpiryError] = useState(false)
  const [cvcNumberError, setcvcNumberError] = useState(false)

  const [nameOnCardError, setNameOnCardError] = useState(false)
  const [address1Error, setAddress1Error] = useState(false)
  const [cityError, setCityError] = useState(false)
  const [stateError, setStateError] = useState(false)
  const [postalCodeError, setPostalCodeError] = useState(false)

  const [nameOnCard, setNameOnCard] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [postalCode, setPostalCode] = useState('')

  const { wrapperProps, meta, getCardNumberProps, getExpiryDateProps, getCVCProps, getZIPProps, getCardImageProps } =
    usePaymentInputs()

  const handleChangeCardNumber = e => setCreditCardNumber(e.target.value)
  const handleChangeExpiryDate = e => setExpiry(e.target.value)
  const handleChangeCVC = e => setcvcNumber(e.target.value)
  const handleChangeZip = e => setPostalCode(e.target.value)
  const handleNameOnCardChange = e => setNameOnCard(e.target.value)
  const handleAddress1Change = e => setAddress1(e.target.value)
  const handleAddress2Change = e => setAddress2(e.target.value)
  const handleCityChange = e => setCity(e.target.value)
  const handleStateChange = e => setState(e.target.value)

  const onSubmit = e => {
    e.preventDefault()
    let errorCount = 0

    //need to check name and address fields before submission
    if (hofs.isPopulated(nameOnCard)) setNameOnCardError(false)
    else {
      setNameOnCardError(true)
      errorCount++
    }

    if (hofs.isPopulated(address1)) setAddress1Error(false)
    else {
      setAddress1Error(true)
      errorCount++
    }

    if (hofs.isPopulated(city)) setCityError(false)
    else {
      setCityError(true)
      errorCount++
    }

    if (hofs.isPopulated(state)) setStateError(false)
    else {
      setStateError(true)
      errorCount++
    }

    if (hofs.isPopulated(postalCode)) setPostalCodeError(false)
    else {
      setPostalCodeError(true)
      errorCount++
    }

    if (hofs.isPopulated(creditCardNumber)) setCreditCardNumberError(false)
    else {
      setCreditCardNumberError(true)
      errorCount++
    }

    if (hofs.isPopulated(expiry)) setExpiryError(false)
    else {
      setExpiryError(true)
      errorCount++
    }

    if (hofs.isPopulated(cvcNumber)) setcvcNumberError(false)
    else {
      setcvcNumberError(true)
      errorCount++
    }

    if (errorCount === 0) {

      console.log("there are no errors in the cc form")

      setProcessingPayment(true)

      const fetchParams = {
        method: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          card: creditCardNumber,
          expiration: expiry,
          cvc: cvcNumber,
          postal_code_card: postalCode,
          billing_address_1: address1,
          billing_address_2: address2,
          billing_city: city,
          billing_state: state,
          name_on_card: nameOnCard
        })
      }
      const errorMessage = 'Error setting up customer account for stripe'
      ;(async () => {
        const response = await fetchData('/payments', errorMessage, fetchParams).promise
        if (response.processing_error) {
          // there was an error processing payment
          setProcessingPayment(false)
          setModalOpen(true)
          setProcessingErrorMessage(response.processing_error_message)
        } else {
          login(response.new_jwt_token)
          setProcessingErrorMessage(false)
          setProcessingPayment(false)
          history.push('/payments/complete')
        }
      })()

    }
  }

  return (
    <>
      <Container maxWidth='xl' className={classes.container}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <PageTitle title='Payment Details' />
            <Typography>
              To start your subscription, please insert your credit card details below. Once submitted, we will setup
              your payment details with the Stripe payment gateway to automatically bill each month for your service.
            </Typography>
            <Container>
              <form onSubmit={onSubmit} className={classes.paymentform}>
                <LoadingOverlay
                  active={processingPayment}
                  spinner
                  text='Processing Your Payment...Do not hit refresh or leave the screen until done...'
                  fadeSpeed={200}
                >
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell align='center'>
                          <PaymentInputsWrapper {...wrapperProps}>
                            <svg {...getCardImageProps({ images })} />
                            <input
                              {...getCardNumberProps({ onChange: handleChangeCardNumber })}
                              value={creditCardNumber}
                            />
                            <input {...getExpiryDateProps({ onChange: handleChangeExpiryDate })} value={expiry} />
                            <input {...getCVCProps({ onChange: handleChangeCVC })} value={cvcNumber} />
                            <input {...getZIPProps({ onChange: handleChangeZip })} value={postalCode} />
                            {meta.isTouched && meta.error && <span>Error: {meta.error}</span>}
                          </PaymentInputsWrapper>
                          <br />
                          <br />
                          <Container>
                            <Table size='small'>
                              <TableBody>
                                <TableRow>
                                  <TableCell className={classes.cardErrorMessages}>
                                    {creditCardNumberError ? creditCardNumberErrorMessage : null}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell className={classes.cardErrorMessages}>
                                    {expiryError ? expiryErrorMessage : null}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell className={classes.cardErrorMessages}>
                                    {cvcNumberError ? cvcNumberErrorMessage : null}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell className={classes.cardErrorMessages}>
                                    {postalCodeError ? postalCodeErrorMessage : null}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Container>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Grid container spacing={2}>
                            <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                              <TextField
                                name='name_on_card'
                                variant='outlined'
                                fullWidth={true}
                                value={nameOnCard}
                                id='nameOnCard'
                                label='Name On Card'
                                onChange={handleNameOnCardChange}
                                helperText={nameOnCardError ? nameOnCardErrorMessage : null}
                                error={nameOnCardError}
                                inputProps={{ maxLength: 100 }}
                              />
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                              <TextField
                                variant='outlined'
                                fullWidth
                                id='address_1'
                                label='Address Line 1'
                                name='address_1'
                                onChange={handleAddress1Change}
                                value={address1}
                                helperText={address1Error ? address1ErrorMessage : null}
                                error={address1Error}
                                inputProps={{ maxLength: 100 }}
                              />
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                              <TextField
                                variant='outlined'
                                fullWidth
                                id='address_2'
                                label='Address Line 2'
                                name='address_2'
                                autoComplete='address_2 name'
                                value={address2}
                                onChange={handleAddress2Change}
                                inputProps={{ maxLength: 100 }}
                              />
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                              <TextField
                                name='city'
                                variant='outlined'
                                fullWidth
                                id='city'
                                label='City'
                                onChange={handleCityChange}
                                value={city}
                                helperText={cityError ? cityErrorMessage : null}
                                error={cityError}
                                inputProps={{ maxLength: 50 }}
                              />
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                              <TextField
                                id='state'
                                name='state'
                                select
                                label='State'
                                value={state}
                                onChange={handleStateChange}
                                variant='outlined'
                                fullWidth
                                helperText={stateError ? stateErrorMessage : null}
                                error={stateError}
                                inputProps={{ maxLength: 25 }}
                              >
                                {stateNameList.map((option, key) => (
                                  <MenuItem key={key} value={stateAbbreviationList[key]}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Button type='submit' variant='contained' color='primary'>
                            Submit Payment Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </LoadingOverlay>
              </form>
            </Container>
          </Grid>
        </Paper>
      </Container>
      <Modal
        aria-labelledby='spring-modal-title'
        aria-describedby='spring-modal-description'
        className={classes.modal}
        open={modalOpen}
        onClose={() => {
          setModalOpen(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={modalOpen}>
          <div className={classes.modalPaper}>
            <h2 id='spring-modal-title'>There Was An Error With Your Payment Attempt</h2>
            <p id='spring-modal-description' style={{ fontSize: '1rem' }}>
              The specific error returned is:
              <br /><br />
              <Typography variant="h6">{processingErrorMessage}</Typography>
              <br /><br />
              If you feel you made an error or wish to try another card click try again and reinsert you card details
            </p>
            <Button
              variant='contained'
              style={{ backgroundColor: '#128C01', color: '#FFFFFF', maxWidth: '300px', marginTop: '10px' }}
              size='small'
              onClick={() => {
                setModalOpen(false)
                setProcessingErrorMessage('')
              }}
            >
              Return To Payment
            </Button>
          </div>
        </Fade>
      </Modal>
      <Footer />
    </>
  )
}

export default PaymentsStart
