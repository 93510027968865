import React, { useEffect, useState } from 'react'
import { Container, Grid, TableContainer, Paper, Typography } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'

import fetchData from '../../../utility/endpointFetch'
import { getAppStyles } from '../../App/AppStyles'
import columns from './AdminLicensesColumns'

const AdminLicenses = () => {
  const classes = getAppStyles()

  const [loading, setLoading] = useState(true)
  const [allLicenses, setAllLicenses] = useState([])

  // This is simply to trigger a reload of data.
  // The value itself does not matter, only that it reruns the fetch effect when changed
  const [reloadData, setReloadData] = useState({})

  useEffect(() => {
    const { promise, cancel } = fetchData('/licenses/admin', 'Error retrieving licenses for admin users')
    ;(async () => {
      const response = await promise
      setAllLicenses(response.all_licenses)
      setLoading(false)
    })()
    return cancel
  }, [reloadData])

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'vertical',
    selectableRows: 'none',
    onRowClick: (rowData, rowState) => {

    }
  }

  return (
    <Container maxWidth='xl' className={classes.container}>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography align='left' variant='h5' style={{ fontWeight: '700' }}>Full License Key List</Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <LoadingOverlay
                active={loading}
                spinner
                text='Loading Licenses...'
                fadeSpeed={200}
              >
                <MUIDataTable
                  title=''
                  data={allLicenses}
                  columns={columns(setLoading, setReloadData)}
                  options={options}
                />
              </LoadingOverlay>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}

export default AdminLicenses
