import React from 'react'
import { Container, Typography, Paper } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import Footer from '../Footer'
import { getAppStyles } from '../App/AppStyles'
import KeysUnpaid from './KeysUnpaid'
import KeysPaid from './KeysPaid'
import hofs from '../../utility/hofs'

const Keys = () => {
  const history = useHistory()
  const classes = getAppStyles()
  const currentRole = hofs.getRole()

  if (!hofs.checkLogStatus()) {
    // either no token or expired token...login
    history.push('/login')
  } else {
    return (
      <>
        <Container maxWidth='xl' className={classes.container}>
          <Paper className={classes.paper}>
            <Typography align='left' variant='h5' className={classes.contentTitle}>
              Account Licenses
            </Typography>
            {currentRole === 'unpaid_user' ? <KeysUnpaid /> : <KeysPaid />}
          </Paper>
        </Container>
        <Footer />
      </>
    )
  }
}
export default Keys
