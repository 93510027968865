import React from 'react'
import { Doughnut } from 'react-chartjs-2'

const classificationData = data => ({
  labels: ['No Risk - Mitigated', 'Low Risk', 'Medium Risk', 'High Risk'],
  datasets: [{
    label: 'Severity of Risk Items',
    data,
    backgroundColor: [
      'rgba(75, 192, 192, 0.2)',
      'rgba(255, 206, 86, 0.2)',
      'rgba(255, 159, 64, 0.2)',
      'rgba(255, 99, 132, 0.2)',
    ],
    borderColor: [
      'rgba(75, 192, 192, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(255, 159, 64, 1)',
      'rgba(255, 99, 132, 1)',
    ],
    borderWidth: 1
  }]
})

const doughnutOptions = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: 5
  },
  plugins: {
    legend: {
      position: 'left'
    },
  },
  scale: {
    ticks: { beginAtZero: true }
  }
}

const SeverityDonutChart = ({ data }) => (
  <div>
    {data.length ? (
      <Doughnut data={classificationData(data)} options={doughnutOptions} />
    ) : (
      <div style={{ marginTop: '1rem' }}>Run an analysis on your machine to get a report!</div>
    )}
  </div>
)

export default SeverityDonutChart
