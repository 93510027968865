import { Typography, TableContainer, Table, TableRow, TableCell, TableBody, Paper, Button } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'

import { getLoginStyles } from './LoginStyles'

const PasswordResetSuccess = () => {
  const classes = getLoginStyles()
  return (
    <TableContainer component={Paper} style={{ marginTop: '20px' }}>
      <Table className={classes.table} aria-label='simple table'>
        <TableBody>
          <TableRow key='some_key'>
            <TableCell>
              <CheckCircle fontSize='large' className={classes.checkicon} />
            </TableCell>
            <TableCell>
              <Typography>
                <p>Your password has been successfully reset!</p>
                <p>You can now login with your new password.</p>
                <Button variant='contained' color='primary' href='/login'>
                  Login Now
                </Button>
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PasswordResetSuccess
