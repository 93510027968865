import React, { useEffect, useState } from 'react'
import { Container, Grid, TableContainer, Typography, Paper } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import MUIDataTable from 'mui-datatables'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'

import fetchData from '../../../utility/endpointFetch'
import { getAppStyles } from '../../App/AppStyles'
import columns from './AdminAccountsColumns'

const options = history => ({
  filter: true,
  filterType: 'dropdown',
  responsive: 'vertical',
  selectableRows: 'none',
  onRowClick: rowData => {
    // edit this account
    history.push(`/admin/account/edit/${rowData[0]}`)
  }
})

const AdminAccounts = () => {
  const classes = getAppStyles()
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(true)
  const [allAccounts, setAllAccounts] = useState([])

  useEffect(() => {
    const { promise, cancel } = fetchData('/account/admin', 'Error retrieving accounts for admin users')
    ;(async () => {
      const response = await promise
      setAllAccounts(response.all_accounts)
      setIsLoading(false)
    })()
    return cancel
  }, [])

  return (
    <Container maxWidth='xl' className={classes.container}>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography align='left' variant='h5' style={{ fontWeight: '700' }}>Account Information</Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading License Keys...'
                fadeSpeed={200}
              >
                <MUIDataTable
                  title={'Full Accounts List'}
                  data={allAccounts}
                  columns={columns}
                  options={options(history)}
                />
              </LoadingOverlay>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}

export default AdminAccounts
