import { CheckCircle, Cancel } from '@material-ui/icons'

import UserActions from './UserActions'

export const userHistoryColumns = [
  {
    name: 'user_id',
    label: '',
    options: {
      display: false,
      filter: false,
      sort: false,
      viewColumns: false,
      searchable: false
    }
  }, {
    name: 'action',
    label: 'Action',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, {
    name: 'action_details',
    label: 'Details',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, {
    name: 'action_taken_at',
    label: 'Date',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: value => <div>{new Date(value).toLocaleDateString('en-US')}</div>
    }
  }
]

export const activeUserColumns = (setLoading, setReloadData) => [
  {
    name: 'user_id',
    label: '',
    options: {
      display: false,
      filter: false,
      sort: false,
      viewColumns: false,
      searchable: false
    }
  }, {
    name: 'first_name',
    label: 'First Name',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, {
    name: 'last_name',
    label: 'Last Name',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, {
    name: 'email',
    label: 'Email',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, {
    name: 'created_at',
    label: 'Created',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: value => <div>{new Date(value).toLocaleDateString('en-US')}</div>
    }
  }, {
    name: 'is_active',
    label: 'Active?',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: (value, tableMeta) =>
        value === 1
          // this user is active
          ? <CheckCircle style={{ color: '#038231' }} />
          // this user is inactive
          : <Cancel style={{ color: '#E81202' }} />,
      filterType: 'dropdown',
      customFilterListOptions: {
        render: value => value === 1 ? 'active' : 'inactive'
      },
      filterOptions: {
        renderValue: value => value === 0 ? 'inactive' : 'active'
      }
    }
  }, {
    name: '',
    label: 'Actions',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => (
        <UserActions
          enabled={tableMeta.rowData[5] === 1}
          userId={tableMeta.rowData[0]}
          setLoading={setLoading}
          setReloadData={setReloadData}
        />
      ),
      customHeadLabelRender: () => null
    }
  }

]
