import React, { useState, useEffect, useMemo } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { CssBaseline, CircularProgress } from '@material-ui/core'

import fetchData from '../../utility/endpointFetch'
import hofs from '../../utility/hofs'
import { useAuth } from '../Auth/auth'

import Sidebar from '../Sidebar'
import Dashboard from '../Dashboard/'
import Login from '../Login/'
import Logout from '../Login/Logout'
import PasswordReset from '../Login/PasswordReset'
import PasswordResetVerify from '../Login/PasswordResetVerify'
import PasswordResetError from '../Login/PasswordResetError'
import Register from '../Register/'
import RegistrationComplete from '../Register/RegistrationComplete'
import Account from '../Account/'
import AccountEdit from '../AccountEdit/'
import Users from '../Users/'
import UserHistory from '../Users/UserHistory'
import UsersNew from '../Users/UsersNew'
import Keys from '../Keys/'
import KeysDisabled from '../Keys/KeysDisabled'
import KeysHistory from '../Keys/KeyHistory'
import Payments from '../Payments/'
import PaymentsStart from '../Payments/PaymentStart'
import PaymentsComplete from '../Payments/PaymentsComplete'
import Machines from '../Machines/'
import MachinesHistory from '../Machines/MachinesHistory'
import MachineDetails from '../Machines/MachineDetails'
import AdminDashboard from '../Admin/AdminDashboard'
import AdminUsers from '../Admin/AdminUsers'
import AdminMachines from '../Admin/AdminMachines'
import AdminLicenses from '../Admin/AdminLicenses'
import AdminAccounts from '../Admin/AdminAccounts'
import AdminAccountEdit from '../Admin/AdminAccounts/AdminAccountEdit'
import PageHeader from '../PageHeader'
import DownloadAccess from '../DownloadAccess'
import AdminInvitationCodes from '../Admin/AdminInvitationCodes/AdminInvitationCodes'
import AdminInvitationCodesNew from '../Admin/AdminInvitationCodes/AdminInvitationCodesNew'
import AdminConfigurations from '../Admin/AdminConfigurations/AdminConfigurations'
import LoginCollectCCInfo from '../Login/LoginCollectCCInfo'
import NoMachineSetup from '../Machines/NoMachineSetup'
import RegisterStepComplete from '../Register/RegisterStepComplete'

import { MuiThemeProvider } from '@material-ui/core/styles'
import { getCommonTheme } from './AppStyles'

const renderAdminRoute = (ChildComponent, rest) => props => {
  console.log("top of renderAdminRoute")
  console.dir(rest)
  console.dir(ChildComponent)
  const { loggedIn, userInfo: { role } } = rest
  if (!loggedIn || role !== 'admin') return <Redirect to='/login' />
  if (!ChildComponent && props.renderComponent) return props.renderComponent(props)
  return <ChildComponent {...props} />
}

const renderClientRoute = (ChildComponent, rest) => props => {
  const { loggedIn, userInfo: { role } } = rest
  if (!loggedIn || !['client_user', 'client_admin', 'unpaid_user'].includes(role)) return <Redirect to='/login' />
  if (!ChildComponent && rest.renderComponent) return rest.renderComponent(props)
  return <ChildComponent {...props} />
}

const AdminRoute = ({ component: ChildComponent, ...rest }) =>
  <Route {...rest} render={renderAdminRoute(ChildComponent, rest)} />

const App = () => {
  const [loggedIn] = useAuth()
  const [userInfo, setUserInfo] = useState({})
  const [ccCollected, setCCCollected] = useState(false)
  const [firstMachine, setFirstMachine] = useState(false)
  const [loading, setLoading] = useState(false)
  const [darkMode, setDarkMode] = useState(false)
  const [theme, setTheme] = useState(getCommonTheme(darkMode))
  // const commonTheme = useMemo(() => getCommonTheme(darkMode), [darkMode])

  const ClientRoute = ({ component: ChildComponent, ...rest }) => {
    if (!ccCollected) {
      return <Route {...rest} render={renderClientRoute(LoginCollectCCInfo, rest)} />
    } else if (!firstMachine) {
      return <Route {...rest} render={renderClientRoute(NoMachineSetup, rest)} />
    } else {
      return <Route {...rest} render={renderClientRoute(ChildComponent, rest)} />
    }
  }

  useEffect(() => {
    if (loggedIn && hofs.getRole() !== userInfo.role) {
      setLoading(true)
      ;(async () => {
        const response = await fetchData('/users/user', 'Error retrieving active user details').promise
        console.log("response in App is: ")
        console.dir(response)
        setUserInfo(response)
        setCCCollected(response.cc_collected)
        setFirstMachine(response.any_machines)
        setLoading(false)
      })()
    }
  }, [loggedIn, userInfo.role, userInfo.ccCollected])

  useEffect(() => {
    setTheme(getCommonTheme(darkMode))
  }, [darkMode])

  if (loading) return <CircularProgress />

  console.log("loggedIn is: ")
  console.dir(loggedIn)
  console.log("ccCollected is: ")
  console.dir(ccCollected)
  console.log("firstMachine is: ")
  console.dir(firstMachine)
  console.log("userInfo is: ")
  console.dir(userInfo)

  return (
    <div className='App'>
      <MuiThemeProvider theme={theme}>
        <div
          style={{
            display: 'flex',
            textAlign: 'center',
            backgroundImage: theme.palette.type === 'dark' ? 'linear-gradient(#1E1E2F, #1E1E24)' : 'none'
          }}
        >
          <CssBaseline />
          { (loggedIn && ((ccCollected && firstMachine) || (userInfo.role == 'admin'))) ? <Sidebar /> : null}
          <main style={{ display: 'flex', flexDirection: 'column', height: '100vh', flex: 1, minWidth: 0 }}>
            {/* spreading userInfo here to force page header to rerender each time app does...its a little hacky */}
            <PageHeader {...{ darkMode, setDarkMode, userInfo: {...userInfo} }} />
            <Switch>
              <Route exact path='/login/reset' component={PasswordReset} />
              <Route exact path='/logout' component={Logout} />
              <Route exact path='/login/resetverify/:token' component={PasswordResetVerify} />
              <Route exact path='/login/resetverify/error' component={PasswordResetError} />
              <Route path='/login' component={Login} />
              <Route exact path='/logout' component={Logout} />
              <Route exact path='/register/verifyemail' component={RegisterStepComplete} />
              <Route exact path='/register' component={Register} />
              {/*<Route exact path='/cccollect' component={LoginCollectCCInfo} />
              <Route exact path='/nomachine' component={NoMachineSetup} /> */}
              {/* "{...{ loggedIn, userInfo }}" is the same as "loggedIn={loggedIn} userInfo={userInfo}" */}
              <ClientRoute {...{ loggedIn, userInfo }} exact path='/account/edit' component={AccountEdit} />
              <ClientRoute {...{ loggedIn, userInfo }} exact path='/account' component={Account} />
              <ClientRoute {...{ loggedIn, userInfo }} exact path='/users/new' component={UsersNew} />
              <ClientRoute {...{ loggedIn, userInfo }} exact path='/users' component={Users} />
              <ClientRoute {...{ loggedIn, userInfo }} exact path='/users/history/:user' component={UserHistory} />
              <ClientRoute {...{ loggedIn, userInfo }} exact path='/licenses/history/:key' component={KeysHistory} />
              <ClientRoute {...{ loggedIn, userInfo }} exact path='/licenses/disable' component={KeysDisabled} />
              <ClientRoute {...{ loggedIn, userInfo }} exact path='/licenses' component={Keys} />
              <ClientRoute {...{ loggedIn, userInfo }} exact path='/machines/history/:machine' component={MachinesHistory} />
              <ClientRoute {...{ loggedIn, userInfo }} exact path='/machines/details/:machine' component={MachineDetails} />
              <ClientRoute {...{ loggedIn, userInfo }} exact path='/machines/:filter' component={Machines} />
              <ClientRoute {...{ loggedIn, userInfo }} exact path='/payments/complete' component={PaymentsComplete} />
              <ClientRoute {...{ loggedIn, userInfo }} exact path='/payments/start' renderComponent={props => <PaymentsStart {...props} setUserInfo={setUserInfo} userInfo={userInfo} />} />
              <ClientRoute {...{ loggedIn, userInfo }} exact path='/payments' component={Payments} />
              <ClientRoute {...{ loggedIn, userInfo }} exact path='/download' component={DownloadAccess} />
              <ClientRoute {...{ loggedIn, userInfo }} exact path='/' renderComponent={props => <Dashboard {...props} userInfo={userInfo} />} />
              <AdminRoute {...{ loggedIn, userInfo }} exact path='/admin/dashboard' component={AdminDashboard} />
              <AdminRoute {...{ loggedIn, userInfo }} exact path='/admin/users/history/:user' component={UserHistory} />
              <AdminRoute {...{ loggedIn, userInfo }} exact path='/admin/users' component={AdminUsers} />
              <AdminRoute {...{ loggedIn, userInfo }} exact path='/admin/licenses/history/:key' component={KeysHistory} />
              <AdminRoute {...{ loggedIn, userInfo }} exact path='/admin/licenses' component={AdminLicenses} />
              <AdminRoute {...{ loggedIn, userInfo }} exact path='/admin/machines/history/:machine' component={MachinesHistory} />
              <AdminRoute {...{ loggedIn, userInfo }} exact path='/admin/machines' component={AdminMachines} />
              <AdminRoute {...{ loggedIn, userInfo }} exact path='/admin/account/edit/:accountId' component={AdminAccountEdit} />
              <AdminRoute {...{ loggedIn, userInfo }} exact path='/admin/accounts' component={AdminAccounts} />
              <AdminRoute {...{ loggedIn, userInfo }} exact path='/admin/invitationcodes/new' component={AdminInvitationCodesNew} />
              <AdminRoute {...{ loggedIn, userInfo }} exact path='/admin/invitationcodes' component={AdminInvitationCodes} />
              <AdminRoute {...{ loggedIn, userInfo }} exact path='/admin/configurations' component={AdminConfigurations} />
              <AdminRoute {...{ loggedIn, userInfo }} exact path='/admin/' component={AdminAccounts} />
            </Switch>
          </main>
        </div>
      </MuiThemeProvider>
    </div>
  )
}

export default App
