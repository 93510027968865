import React, { useEffect, useState, useRef } from 'react'
import { Grid, Modal, Fade, Backdrop, Paper, CircularProgress, Typography, Button } from '@material-ui/core'
import { Category, Equalizer, Warning, CheckCircle, Cancel } from '@material-ui/icons'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'

import fetchData from '../../utility/endpointFetch'
import MachineRiskDial from '../Machines/MachineRiskDial'
import MachineClassificationChart from '../Machines/MachineClassificationChart'
import PageTitle from '../PageTitle'
import { getAppStyles } from '../App/AppStyles'
import Hierarchy from '../Hierarchy'
import SeverityDonutChart from '../DashboardUnpaid/SeverityDonutChart'
import ReportLastDateDisplay from './ReportLastDateDisplay'

// import { mockSecurityReportData } from '../../StaticData/mockSecurityReportData'

const loadPage = (machineId, setData, setLoading, setSelected) => () => {
  setLoading(true)
  const url = `/securityconfigurations/${machineId}`
  const { promise, cancel } = fetchData(url, 'Error retrieving account information')
  ;(async () => {
    const response = await promise
    const newSeverityData = [
      response.security_report.filter(r => r.secstate === 1).length, // no risk
      response.security_report.filter(r => r.secstate === 0 && r.severity === 0).length, // low risk
      response.security_report.filter(r => r.secstate === 0 && r.severity === 1).length, // moderate risk
      response.security_report.filter(r => r.secstate === 0 && r.severity === 2).length // high risk
    ]
    setData({
      groups: response.security_groups,
      report: response.security_report,
      title: `Security Report for ${response.machine_details.machine_name}`,
      severityData: newSeverityData,
      stats: response.report_stats,
      riskScore: response.report_stats.last_report_date !== 0
        ? parseFloat(response.report_stats.security_score.toFixed(2))
        : 1
    })
    if (response.security_configuration_mask.length > 0) setSelected(response.security_configuration_mask)
    else setSelected(response.security_report.map(l => l.secid))
    setLoading(false)
  })()
  return cancel
}

const checkRequestStatus = (machineId, setRequestState, checkingStatusCount, setCheckingStatusCount, setCheckingStatus, setReloadData, setLoading, setCompleteReport, setRequestError) => {
  if (checkingStatusCount < 6) {
    const fetchUrl = `/toolqueue/requeststatus`
    const fetchParams = {
      method: 'POST',
      dataType: 'json',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        machine_id: machineId
      })
    }

    ;(async () => {
      var check_request_response = await fetchData(fetchUrl, 'Error getting request status', fetchParams).promise

      if (check_request_response.request_status === 1) {
        //request is complete as report has been received
        //reload the data because another report was received
        setReloadData({}) //go ahead and start behind the scenes
        setRequestState("Request complete! New report received.  Refreshing report")
        setCompleteReport(true)
        setTimeout( () => {
          setCheckingStatus(false)
          setCheckingStatusCount(0)
          setRequestState("")
          setCompleteReport(false)
          }, 2500);
        return
      } else if (check_request_response.request_status === 2) {
        //machine notified of request.  report should be received shortly
        setRequestState('Machine notified of request...report to come')
        setTimeout( () => { setCheckingStatusCount(checkingStatusCount + 1) }, 3000)
        return
      } else if (check_request_response.request_status === 3) {
        //machine has not been notified of request
        setRequestState('Machine to be notified of request')
        setTimeout( () => { setCheckingStatusCount(checkingStatusCount + 1) }, 3000)
        return
      } else {
        //not sure what is wrong
        //console.log("request_status is returned but not recognized")
        setRequestState('Error processing request...report not to come.  Please try again')
      }

      //gets here because not doing again by error
      //but go ahead and show the message for a second then close it down
      setRequestError(true)
      setTimeout( () => {
        setCheckingStatus(false)
        setCheckingStatusCount(0)
        setRequestState("")
        setRequestError(false)
        setLoading(false)
        }, 3000);

    })()
  } else {
    //gets here because max attempts exceeded or slipped by other if
    setRequestError(true)
    setRequestState('Machine never received request.  Your request is still in the queue and will be processed when the machine connects to the service again')
    setTimeout( () => {
      setCheckingStatus(false)
      setCheckingStatusCount(0)
      setRequestState("")
      setRequestError(false)
      setLoading(false)
    }, 3000);
  }


}

const applyChanges = (setLoading, machineId, selected, setRequestState, setCheckingStatus) => () => {
  setRequestState('Sending mitigation request...')
  setLoading(true)
  const fetchUrl = `/securityconfigurations/${machineId}`
  const fetchParams = {
    method: 'POST',
    dataType: 'json',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      machine_id: machineId,
      mitigate_secids: selected
    })
  }
  ;(async () => {
    //console.log("sending mitigate request for machine id:" + machineId + " with selected secid's of : " + selected)
    var response = await fetchData(fetchUrl, 'Error sending mitigation request', fetchParams).promise
    //console.log("response from mitigate request:")
    //console.dir(response)
    setCheckingStatus(true)
  })()
}

const sendScanRequest = (setLoading, machineId, setRequestState, setCheckingStatus) => () => {
  //console.log("top of sendScanRequest")
  setRequestState('Sending scan request....')
  setLoading(true)
  const fetchUrl = `/toolqueue/scan`
  const fetchParams = {
    method: 'POST',
    dataType: 'json',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ machine_id: machineId })
  }
  ;(async () => {
    //console.log("sending scan request for machine id:" + machineId)
    var response = await fetchData(fetchUrl, 'Error sending scan request', fetchParams).promise
    //console.log("response from scan request:")
    //console.dir(response)
    //variable that starts the process of checking status of a request
    setCheckingStatus(true)
  })()
}

const sendRollBackRequest = (setLoading, machineId, rollBackType, setRequestState, setCheckingStatus) => {
  setRequestState('Sending rollback request....')
  setLoading(true)
  const fetchUrl = `/toolqueue/rollback`
  const fetchParams = {
    method: 'POST',
    dataType: 'json',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      machine_id: machineId,
      rollback_type: rollBackType
    })
  }
  ;(async () => {
    //console.log("sending rollback request for machine id:" + machineId + " with rollback type of: " + rollBackType)
    var response = await fetchData(fetchUrl, 'Error sending rollback request', fetchParams).promise
    //console.log("response from mitigate request:")
    //console.dir(response)
    setCheckingStatus(true)
  })()

}

const initialState = {
  groups: [],
  report: {},
  title: '',
  severityData: {},
  stats: {},
  riskScore: 1
}

const SecurityReport = ({ machineId }) => {
  const classes = getAppStyles()
  const isFirstRender = useRef(true)

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(initialState)
  //const [modalOpen, setModalOpen] = useState(false)
  //const [modalType, setModalType] = useState('')
  const [rollBackType, setRollBackType] = useState(0)
  const [selected, setSelected] = useState([])
  const [reloadData, setReloadData] = useState({})
  const [checkingStatus, setCheckingStatus] = useState(false)
  const [checkingStatusCount, setCheckingStatusCount] = useState(0)
  const [completeReport, setCompleteReport] = useState(false)
  const [requestError, setRequestError] = useState(false)

  const [requestState, setRequestState] = useState('Loading Security Report...')

  useEffect(() => {
    //console.log("top of useEffect on rollBackType")
    //console.log(rollBackType)
    if (isFirstRender.current) {
      // toggle flag after first render/mounting
      isFirstRender.current = false
      return
    } else if ([1, 2].includes(rollBackType)) {
      if (sendRollBackRequest(setLoading, machineId, rollBackType, setRequestState, setCheckingStatus)) {
        setReloadData({})
      }
      setRollBackType(0) //hmmmm but does not do anything if 0
      return
    }
  }, [rollBackType, machineId])

  useEffect(() => {
    return loadPage(machineId, setData, setLoading, setSelected)()
  }, [machineId, reloadData])

  useEffect(() => {
    if ((checkingStatus === true) && (checkingStatusCount < 5)) {
      //need to check the status of request
      checkRequestStatus(machineId, setRequestState, checkingStatusCount, setCheckingStatusCount, setCheckingStatus, setReloadData, setLoading, setCompleteReport, setRequestError)
    } else if (checkingStatusCount >= 5) {
      setRequestState('Machine never received request, did not properly respond with a report based on that request. Your request is still in the queue and will be processed when the machine next connects to the service')
      setRequestError(true)
    } else {
      //checkingStatus is false or checkingStatusCount >= 6
      //reset both incase just one triggered this but only the one that needs and save renders
      if (checkingStatus) setCheckingStatus(false)
      if (checkingStatusCount !== 0) setCheckingStatusCount(0)
      if (loading) setLoading(false)
    }
  }, [checkingStatus, checkingStatusCount, machineId])

  const closeModalManually = () => {
    setCheckingStatus(false)
    setCheckingStatusCount(0)
    setRequestState("")
    setRequestError(false)
    setLoading(false)
  }

  return (
    <LoadingOverlay active={loading} spinner text='Loading Report Data....' fadeSpeed={200}>
      <PageTitle title={data.title} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper className={classes.chartContainer}>
            <div className={classes.containerLabel}>
              <Warning className={classes.iconAndSpacer} />
              Risk Factor
            </div>
            <MachineRiskDial securityRiskScore={data.riskScore} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={classes.chartContainer}>
            <div className={classes.containerLabel}>
              <Equalizer className={classes.iconAndSpacer} />
              Risk Distribution Of Security Items
            </div>
            <SeverityDonutChart data={data.severityData} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={classes.chartContainer}>
            <div className={classes.containerLabel}>
              <Category className={classes.iconAndSpacer} />
              Security Element Classification
            </div>
            <MachineClassificationChart data={data.stats} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <ReportLastDateDisplay
            reportLastDate={data.stats ? data.stats.last_report_date : undefined}
            lastReportType={data.stats.last_report_type}
            refreshPageData={() => loadPage(machineId, setData, setLoading, setSelected)()}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.chartContainer}>
            <Hierarchy
              rawCategoryData={data.groups}
              leafRows={data.report}
              mitigate={applyChanges(setLoading, machineId, selected, setRequestState, setCheckingStatus)}
              scan={sendScanRequest(setLoading, machineId, setRequestState, setCheckingStatus)}
              setRollBackTypeValue={rollBackTypeValue => setRollBackType(rollBackTypeValue)}
              setSelected={setSelected}
              selected={selected}
              machineId={machineId}
            />
          </Paper>
        </Grid>
        <Modal
          aria-labelledby='spring-modal-title'
          aria-describedby='spring-modal-description'
          className={classes.modal}
          open={checkingStatus}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 1000 }}
        >
          <Fade in={checkingStatus}>
            <div className={classes.modalPaper}>
              <h2 id='spring-modal-title'>
                Your Request's Status
              </h2>
              <Grid container>
                <Grid item><Typography>
                  { completeReport ? <CheckCircle className={classes.reportSuccessIcon} /> : null }
                  { requestError ? <Cancel className={classes.reportRequestError} /> : null }
                  { !completeReport && !requestError ? <CircularProgress /> : null }
                   </Typography></Grid>
                <Grid item style={{alignItems: 'center', marginTop: '10px', marginLeft: '15px'}}><Typography> {requestState}</Typography></Grid>
              </Grid>
              <Grid container>
                <Grid item  style={{marginTop: '10px', marginLeft: '15px'}}>{requestError ? <Button variant="contained" color="primary" onClick={closeModalManually}>Return To Report</Button>: null}</Grid>
              </Grid>

            </div>
          </Fade>
        </Modal>
      </Grid>
    </LoadingOverlay>
  )
}

export default SecurityReport