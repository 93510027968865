import React from 'react'
import { Typography, Grid } from '@material-ui/core'

const PageTitle = ({ title }) => (
  <>
    <Grid item xs={12} md={12} lg={12}>
      <Typography align='left' variant='h5' style={{ fontWeight: '700' }}>
        {title}
      </Typography>
    </Grid>
    {/* <Grid item xs={12} md={1} lg={1}>
      <Typography align='right' variant='body2' color='textSecondary'>
        Account Type: {}
      </Typography>
    </Grid> */}
  </>
)

export default PageTitle
