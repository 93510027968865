import React from 'react'

import hofs from '../../utility/hofs'
import UsersList from './UsersList'
import UsersUnpaid from './UsersUnpaid'

const Users = () => {
  const logStatus = hofs.checkLogStatus()
  const currentRole = logStatus && hofs.getRole()
  return currentRole === 'unpaid_user' ? <UsersUnpaid /> : <UsersList />
}

export default Users
