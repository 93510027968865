import React from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { CheckCircle, Cancel } from '@material-ui/icons'

import fetchData from '../../../utility/endpointFetch'

const handleUserDisable = (setLoading, setReloadData, userId) => {
  setLoading(true)
  ;(async () => {
    const url = `/users/user/disable/${userId}`
    const response = await fetchData(url, 'Error disabling user attached to account').promise
    if (response.user_disabled) {
      // successfully disabled this user
      setReloadData({})
    }
  })()
}

const handleUserEnable = (setLoading, setReloadData, userId) => {
  setLoading(true)
  ;(async () => {
    const url = `/users/user/enable/${userId}`
    const response = await fetchData(url, 'Error enabling user attached to account').promise
    if (response.user_enabled) {
      // successfully enabled this user
      setReloadData({})
    }
  })()
}

const UserState = ({ userId, enabled, setLoading, setReloadData }) => enabled ? (
  // this is a live key
  <Tooltip title='User Active - Click to DISABLE' style={{ padding: '0' }}>
    <IconButton onClick={() => handleUserDisable(setLoading, setReloadData, userId)}>
      <CheckCircle style={{ color: '#156E01' }} />
    </IconButton>
  </Tooltip>
) : (
  <Tooltip title='User NOT Active - Click to ENABLE' style={{ padding: '0' }}>
    <IconButton onClick={() => handleUserEnable(setLoading, setReloadData, userId)}>
      <Cancel style={{ color: '#820000' }} />
    </IconButton>
  </Tooltip>
)

export default UserState
