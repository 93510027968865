import React, { useState, useRef } from 'react'
import { Button, ButtonGroup, Popper, MenuItem, MenuList, Grow, Paper } from '@material-ui/core'
import { Compare, Send, SettingsBackupRestore } from '@material-ui/icons'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import { getHierarchyStyles } from './HierarchyStyles'

const rollbackOptions = ['Rollback', 'To Pristine State', 'To Before Last Mitigation']

const MachineActions = ({ mitigate, scan, setRollBackTypeValue }) => {
  const [open, setOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const anchorRef = useRef(null)
  const classes = getHierarchyStyles()
  const handleToggle = () => setOpen(prevOpen => !prevOpen)

  const handleMenuItemClick = (e, index) => {
    setSelectedIndex(index)
    // 0: do nothing (default), 1: pristine state, 2: before last mitigation
    if ([1, 2].includes(index)) setRollBackTypeValue(index)
    setSelectedIndex(0)
    setOpen(false)
  }

  const handleClose = e => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return
    }
    setOpen(false)
  }

  return (
    <span className={classes.Hierarchy__actions}>
      <ButtonGroup
        variant='contained'
        color='primary'
        ref={anchorRef}
        aria-label='split button'
        className={classes.Hierarchy__actions_button}
      >
        <Button onClick={handleToggle} startIcon={<SettingsBackupRestore />}>
          {rollbackOptions[selectedIndex]}
        </Button>
        <Button
          color='primary'
          size='small'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='rollback request'
          aria-haspopup='menu'
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu'>
                  {rollbackOptions.map((option, index) => {
                    if (option !== 'Rollback') {
                      return (
                        <MenuItem
                          key={option}
                          selected={index === selectedIndex}
                          onClick={event => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      )
                    }
                    return null
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Button
        size='small'
        variant='contained'
        color='primary'
        className={classes.Hierarchy__actions_button}
        startIcon={<Send />}
        onClick={mitigate}
      >
        Mitigate Machine
      </Button>
      <Button
        size='small'
        variant='contained'
        color='primary'
        className={classes.Hierarchy__actions_button}
        startIcon={<Compare />}
        onClick={scan}
      >
        Scan Machine
      </Button>
    </span>
  )
}

export default MachineActions
