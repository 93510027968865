import React from 'react'
import { DoneOutline, OpenInNew } from '@material-ui/icons'
import { Typography, Button, List, ListItemIcon, ListItemText, ListItem, Container } from '@material-ui/core'

import { getAppStyles } from '../App/AppStyles'

const KeysUnpaid = () => {
  const classes = getAppStyles()
  return (
    <Container className={classes.tableContainer}>
      <Typography variant='h5' gutterBottom>
        <span>This feature is available to upgraded accounts</span>
      </Typography>
      <Typography variant='h6' gutterBottom>Benefits:</Typography>
      <List>
        <ListItem className={classes.indentList}>
          <ListItemIcon>
            <DoneOutline />
          </ListItemIcon>
          <ListItemText primary='Create as many license keys as you wish' />
        </ListItem>
        <ListItem className={classes.indentList}>
          <ListItemIcon>
            <DoneOutline />
          </ListItemIcon>
          <ListItemText primary='Use multiple license keys to group machines in your organization as you like' />
        </ListItem>
        <ListItem className={classes.indentList}>
          <ListItemIcon>
            <DoneOutline />
          </ListItemIcon>
          <ListItemText
            primary='Enable/Disable individual licenses independently and thus the machines attached to them'
          />
        </ListItem>
        <ListItem className={classes.indentList}>
          <ListItemIcon>
            <DoneOutline />
          </ListItemIcon>
          <ListItemText
            primary='Use licenses to group items by office, department and then see billing to separate by that'
          />
        </ListItem>
      </List>
      <Button
        variant='contained'
        color='primary'
        href='https://def-logix.com/security-assessment-tool'
        startIcon={<OpenInNew />}
      >
        Click to learn more...visit our site
      </Button>
    </Container>
  )
}

export default KeysUnpaid
