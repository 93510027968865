import { makeStyles } from '@material-ui/core/styles'

export const dashboardStyles = makeStyles(theme => {
  const darkMode = theme.palette.type === 'dark'
  return {
    contentTitle: {
      fontWeight: '700',
      position: 'fixed'
    },
    container: {
      padding: theme.spacing(2),
      paddingBottom: 0,
      flex: 1,
      minHeight: 0,
      display: 'flex',
      flexDirection: 'column'
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: darkMode ? theme.palette.primary.light : theme.palette.primary.lighter,
      color: theme.palette.getContrastText(darkMode ? theme.palette.primary.light : theme.palette.primary.lighter),
      flex: 1,
      minHeight: 0
    },
    grid: {
      marginTop: theme.spacing(6),
      overflow: 'auto'
    },
    dashboardSection: {
      backgroundColor: 'white',
      borderRadius: '8px',
      padding: '5px',
      color: '#495C7B',
      //height: '100%'
      minHeight: '200px',
      maxHeight: '200px'
    },
    unpaidDashboardSection: {
      backgroundColor: 'white',
      borderRadius: '8px',
      padding: '5px',
      color: '#495C7B',
      //height: '100%'
      minHeight: '75px',
      maxHeight: '200px'
    },

    containerLabel: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(0.5),
      fontWeight: 800,
      fontSize: '1.255em',
      textAlign: 'left',
      color: '#495C7B',
      alignItems: 'center',
      display: 'flex'
    },
    infoDisplay: {
      fontWeight: '700',
      textAlign: 'center'
    },
    historyContainer: {
      maxHeight: '250px',
      paddingBottom: '1rem'
    },
    iconAndSpacer: {
      fontSize: '1.2em',
      marginRight: '10px',
      marginBottom: '2px'
    },
    noBorderTable: {
      borderBottom: 'none'
    },
    boldedText: {
      fontWeight: 600
    },
    dateCell: {
      whiteSpace: 'nowrap'
    },
    riskDialText: {
      marginTop: "50px"
    }, //already in AppStyles.js but need here until central
}
})

export const depositsStyles = makeStyles({
  depositContext: {
    flex: 1
  }
})

export const ordersStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3)
  }
}))
