import { CheckCircle, Cancel, FileCopy } from '@material-ui/icons'
import { Tooltip, IconButton } from '@material-ui/core'

import KeyActions from './KeyActions'

const copyToClipboard = newLicenseKey => () => {
  navigator.clipboard.writeText(newLicenseKey)
  alert('Copied the license key to your clipboard')
}

export const keyColumns = (setReloadData, setLoading) => [
  {
    name: 'key_id',
    label: '',
    options: {
      display: false,
      filter: false,
      sort: false,
      viewColumns: false,
      searchable: false
    }
  }, {
    name: 'key_description',
    label: 'Label',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
    }
  }, {
    name: 'key',
    label: 'Key',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: value => (
        <>
          <span onClick={copyToClipboard(value)} style={{ cursor: 'pointer' }}>
            <Tooltip title='Click to copy license key'>
              <IconButton size='small' aria-label='License key copy' style={{ fontSize: '1.0rem' }}>
                {value}
                &nbsp;<FileCopy color='primary' style={{ fontSize: '.8rem' }} />
              </IconButton>
            </Tooltip>
          </span>
        </>
      )
    }
  }, {
    name: 'key_creator',
    label: 'Created By',
    options: {
      filter: false,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: value => <>{value.first_name} {value.last_name}</>,
      filterType: 'dropdown',
      customFilterListOptions: {
        render: value => value === 0 ? 'inactive' : 'active'
      },
      filterOptions: {
        renderValue: value => value === 0 ? 'inactive' : 'active'
      }
    }
  }, /*  not displaying expiration date yet {
    name: 'expiration_date',
    label: 'Expiration',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, */
  {
    name: 'machine_count',
    label: 'Machines',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, {
    name: 'disabled',
    label: 'Active?',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: value => {
        return value === 1
          // this license is active
          ? <CheckCircle style={{ color: '#038231' }} />
          // this user is inactive
          : <Cancel style={{ color: '#E81202' }} />
      },
      filterType: 'dropdown',
      customFilterListOptions: {
        render: value => value === 0 ? 'inactive' : 'active'
      },
      filterOptions: {
        renderValue: value => value === 0 ? 'inactive' : 'active'
      }
    }
  }, {
    name: '',
    label: 'Actions',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => (
        <KeyActions
          enabled={tableMeta.rowData[6] === 1}
          keyId={tableMeta.rowData[0]}
          setReloadData={setReloadData}
          setLoading={setLoading}
        />
      ),
      customHeadLabelRender: () => null
    }
  }
]

export const keysHistoryColumns = [
  {
    name: 'key_history_id',
    label: '',
    options: {
      display: false,
      filter: false,
      sort: false,
      viewColumns: false,
      searchable: false
    }
  }, {
    name: 'action',
    label: 'Action',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, {
    name: 'action_details',
    label: 'Details',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, {
    name: 'action_taken_at',
    label: 'Date',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: value => <div>{new Date(value).toLocaleDateString('en-US')}</div>
    }
  }
]
