import React from 'react'
import { Container, Paper } from '@material-ui/core'
import { useParams } from 'react-router-dom'

import { getAppStyles } from '../App/AppStyles'
import SecurityReport from '../SecurityReport/SecurityReport'

const MachineDetails = () => {
  const { machine } = useParams()
  const classes = getAppStyles()

  return (
    <Container maxWidth='xl' className={classes.container}>
      <Paper className={classes.paper}>
        <SecurityReport machineId={machine} />
      </Paper>
    </Container>
  )
}

export default MachineDetails
