import React, { useState, useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import fetchData from '../../utility/endpointFetch'
import { CircularProgress, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { getAppStyles } from '../App/AppStyles'

const AccountRiskBarStats = () => {
  const classes = getAppStyles()
  const [barChartRiskData, setBarChartRiskData] = useState([])
  const [totalMachineCount, setTotalMachineCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 5
    },
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    onClick: function(evt, element) {
      // data to use is:
      // element[0].index only if element.length greater than 0
      if (element.length) {
          history.push('/machines/'+element[0].index)
      } else {
          return null
      }
    }
  }

  const riskData = {
    labels: ['High Risk', 'Moderate Risk', 'Low Risk', 'No Risk'],
    datasets: [{
      label: 'Click to view these machines ',
      data: barChartRiskData,
      backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(255, 206, 86, 0.2)','rgba(75, 192, 192, 0.2)','rgba(75, 192, 87, 0.2)'],
      borderColor: ['rgba(255, 99, 132, 1)', 'rgba(255, 206, 86, 1)','rgba(75, 192, 192, 1)','rgba(75, 192, 87, 1)'],
      borderWidth: 1
    }]
  }

  useEffect(() => {
    const { promise, cancel } = fetchData('/machines/securityscore', 'Error retrieving machines security score data')
    ;(async () => {
      const response = await promise
      // calculate the scores per bracket
      // 0-10 = little
      // 11-50 = moderate
      // 51-75 = high
      // 76 and up = insecure
      setTotalMachineCount( response.length )
      if (response.length > 0) {
        const noRisk = response.filter( score => score === 0).length
        const lowRisk = response.filter( score => score > 0 && score <= 33 ).length
        const moderateRisk = response.filter( score => score > 33 && score <= 66).length
        const highRisk = response.filter( score => score > 66).length
        setBarChartRiskData([highRisk, moderateRisk, lowRisk, noRisk])
      }
      setIsLoading(false)
    })()
    return cancel
  }, [])

  if (isLoading) return <CircularProgress />
  if (totalMachineCount > 0)
    return <div className={classes.barChartHeight}> <Bar data={riskData} options={barOptions} /></div>
  return <Typography>There are no machines on this account</Typography>

}

export default AccountRiskBarStats
