import React, { useEffect, useState } from 'react'
import { Table, TableRow, TableCell, TextField, Grid, Paper, Button, InputAdornment,
  IconButton, Modal, Fade, Backdrop, Typography } from '@material-ui/core'
import { Edit, Visibility, VisibilityOff, CheckCircle } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'
import PasswordStrengthBar from 'react-password-strength-bar'
import TOTPSwitch from './TOTPSwitch'
import ShowTOTP from './ShowTOTP'

import fetchData from '../../utility/endpointFetch'
import { getAppStyles } from '../App/AppStyles'
import hofs from '../../utility/hofs'

const firstNameErrorMessage = 'First name is required'
const lastNameErrorMessage = 'Last name is required'
const emailErrorMessageBasic = 'Email address is required'
const emailErrorMessageDuplicate = 'That email is already within our system.  Please try another'
const passwordErrorMessage = 'Account password is required and must have a password score of at least \'good\' according to the strength meter'
const invitationCodeErrorMessageNoMatch = 'The invitation code you inserted does not match our records.  Please re-insert the invitation you have and try again.  If still no luck, please contact Sales'


const UserExpandableRowDisplay = ({ userRowDetails, reloadUserList }) => {
  //console.log("top of UserExpandableRowDisplay")
  //console.dir(userRowDetails)
  const history = useHistory()
  const classes = getAppStyles()

  const [userDetails, setUserDetails] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [firstName, setFirstName] = useState('')
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastName, setLastName] = useState('')
  const [lastNameError, setLastNameError] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [emailErrorMessage, setEmailErrorMessage] = useState(emailErrorMessageBasic)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('')
  const [passwordVerifier, setPasswordVerifier] = useState('')
  const [passwordVerifierError, setPasswordVerifierError] = useState(false)
  const [passwordVerifierErrorMessage, setPasswordVerifierErrorMessage] = useState('')
  const [passwordScore, setPasswordScore] = useState(0)
  const [modalOpen,setModalOpen] = useState(false)
  const [formError, setFormError] = useState(false)
  const [totpProvisioningURI, setTotpProvisioningURI] = useState('')
  const [loadingTOTP, setLoadingTOTP] = useState(false)
  const [totpCode, setTotpCode] = useState(false)

  const [valuesPassword, setValuesPassword] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false
  })

  const [valuesPasswordVerifier, setValuesPasswordVerifier] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPasswordVerifier: false
  })

  const handleClickShowPassword = () =>
    setValuesPassword({ ...valuesPassword, showPassword: !valuesPassword.showPassword })

  const handleClickShowPasswordVerifier = () =>
    setValuesPasswordVerifier({
      ...valuesPasswordVerifier,
      showPasswordVerifier: !valuesPasswordVerifier.showPasswordVerifier
    })

  const handleFirstNameChange = e => setFirstName(e.target.value)
  const handleLastNameChange = e => setLastName(e.target.value)
  const handleEmailChange = e => setEmail(e.target.value)
  const handlePasswordChange = e => setPassword(e.target.value)
  const handlePasswordVerifierChange = e => setPasswordVerifier(e.target.value)
  const handleMouseDownPassword = e => e.preventDefault()
  const handleMouseDownPasswordVerifier = e => e.preventDefault()

  useEffect(() => {
    const fetchURL = `/users/userdetails/${userRowDetails.user_id}`
    const { promise, cancel } = fetchData(fetchURL, 'Error retrieving details attached to user')
    ;(async () => {
      const response = await promise
      setUserDetails(response)
      setFirstName(response.first_name)
      setLastName(response.last_name)
      setEmail(response.email)
      setTotpProvisioningURI(response.totp_provisioning_uri)
      setTotpCode(response.totp_code)
      setIsLoading(false)
    })()
    return cancel
  }, [userRowDetails.user_id])

  const handleSaveSubmit = e => {
    e.preventDefault()
    let errorCount = 0

    //only need to check password if one is actually typed in
    if (password.length > 0) {
      if (passwordScore < 3) {
        //we want a stronger password than that
        errorCount++
        setPasswordError(true)
      } else {
        if (hofs.isPopulated(password)) {
          setPasswordError(false)
        } else {
          errorCount++
          setPasswordError(true)
        }
      }
      if (hofs.isPopulated(passwordVerifier)) {
        if (passwordVerifier === password) {
          setPasswordVerifierError(false)
          setPasswordVerifierErrorMessage('')
        } else {
          errorCount++
          setPasswordVerifierError(true)
          setPasswordVerifierErrorMessage(
            'Your password verifier must match the password inserted above.  Please check and make sure both match'
          )
        }
      } else {
        errorCount++
        setPasswordVerifierError(true)
        setPasswordVerifierErrorMessage(
          'You must insert a password verifier that matches your password to complete submission'
        )
      }
    }

    if (hofs.isPopulated(firstName)) {
      setFirstNameError(false)
    } else {
      errorCount++
      setFirstNameError(true)
    }

    if (hofs.isPopulated(lastName)) {
      setLastNameError(false)
    } else {
      errorCount++
      setLastNameError(true)
    }

    if (hofs.isPopulated(email)) {
      if (hofs.validateEmail(email)) {
        setEmailError(false)
        setEmailErrorMessage('')
      } else {
        setEmailError(false)
        setEmailError(true)
      }
    } else {
      errorCount++
      setEmailError(true)
    }

    // need to check if email is unique with backend
    if (errorCount === 0) {
      // there are no errors...send the data to backend to complete registration

      const fetchParams = {
        method: 'PUT',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          email,
          password,
        })
      }
      const errorMessage = 'Error saving changes to that account.  Please contact support to do so.'
      ;(async () => {
        const response = await fetchData(`/users/userdetails/${userRowDetails.user_id}`, errorMessage, fetchParams).promise
        if (response.duplicate_email) {
          setEmailError(true)
          setEmailErrorMessage(emailErrorMessageDuplicate)
        } else {
          setFormError(response.error)
          if (!response.error) {
            setModalOpen(true)
            setTimeout( () => {
              reloadUserList({})
              setModalOpen(false)
              }, 3000);

          }
        }
      })()
    }
  }

  const handleTOTPChange = () => {
    //only the admin can see this feature so need to pass the user id this will be for
    var totpChoice = false //assume off by default
    if ((totpProvisioningURI == "") || (totpProvisioningURI == undefined)) totpChoice = true //Turn totp use one for this user
    setLoadingTOTP(true)
    const fetchParams = {
      method: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        totpChoice: totpChoice,
        change_user_id: userRowDetails.user_id
      })
    }
    let totp_error_message = "Could not save time-based password configuration changes"
    ;(async () => {
      const response = await fetchData(`/login/totp_choice`, totp_error_message, fetchParams).promise
      setTotpProvisioningURI(response.totp_provisioning_uri)
      setTotpCode(response.totp_code)
      setLoadingTOTP(false)
    })()
  }

  return (
    <TableRow className={classes.tableRow}>
      <TableCell colSpan={7}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5} lg={5} lx={5} className={classes.userEditContainer}>
            <div>
              <LoadingOverlay active={isLoading} spinner text='Loading User Details...' fadeSpeed={200}>
                <div className={classes.containerLabel}>Stats</div>
                {isLoading ? (
                  <div style={{ minHeight: '100px' }} />
                ) : (
                  <div>
                    <Paper className={classes.userExpandableSection}>
                      <Table size='small'>
                        <TableRow>
                          <TableCell style={{ borderBottom: 'none' }} align='right'>
                            Added:
                          </TableCell>
                          <TableCell style={{ borderBottom: 'none' }} align='left'>
                            {userRowDetails.created_at}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ borderBottom: 'none' }} align='right'>
                            Role:
                          </TableCell>
                          <TableCell style={{ borderBottom: 'none' }} align='left'>
                            {userRowDetails.roles}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ borderBottom: 'none' }} align='right'>
                            <TOTPSwitch totpProvisioningURI={totpProvisioningURI} handleTOTPChange={handleTOTPChange} />
                          </TableCell>
                          <TableCell style={{ borderBottom: 'none' }} align='left'>
                            <LoadingOverlay active={loadingTOTP} spinner text='Saving TOTP Changes...' fadeSpeed={200}>
                              <ShowTOTP totpProvisioningURI={totpProvisioningURI} totpCode={totpCode} />
                            </LoadingOverlay>
                          </TableCell>
                        </TableRow>
                      </Table>
                    </Paper>
                  </div>
                )}
              </LoadingOverlay>
            </div>
          </Grid>

          <Grid item xs={12} md={7} lg={7} lx={7} className={classes.userEditContainer}>
            <div>
              <LoadingOverlay active={isLoading} spinner text='Loading User Details...' fadeSpeed={200}>
                <div className={classes.containerLabel}>Edit Details for {userRowDetails.first_name}</div>
                {isLoading ? (
                  <div style={{ minHeight: '100px' }} />
                ) : (
                  <div>
                    <Paper className={classes.userExpandableSection}>
                      <Table size='small'>
                        <TableRow>
                          <TableCell style={{ borderBottom: 'none' }} align='right'>
                            <TextField
                              name='first_name'
                              variant='outlined'
                              required
                              fullWidth={true}
                              value={firstName}
                              id='firstName'
                              label='First Name'
                              onChange={handleFirstNameChange}
                              helperText={firstNameError ? firstNameErrorMessage : null}
                              error={firstNameError}
                              inputProps={{ maxLength: 50 }}
                              size='small'
                            />
                          </TableCell>

                          <TableCell style={{ borderBottom: 'none' }}>
                            <TextField
                              variant='outlined'
                              required
                              fullWidth
                              value={lastName}
                              id='lastName'
                              label='Last Name'
                              name='last_name'
                              autoComplete='lname'
                              onChange={handleLastNameChange}
                              helperText={lastNameError ? lastNameErrorMessage : null}
                              error={lastNameError}
                              inputProps={{ maxLength: 50 }}
                              size='small'
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ borderBottom: 'none' }} align='right' colSpan={2}>
                            <TextField
                              variant='outlined'
                              required
                              value={email}
                              fullWidth
                              id='email'
                              label='Email Address'
                              name='email'
                              autoComplete='email'
                              onChange={handleEmailChange}
                              helperText={emailError ? emailErrorMessage : null}
                              //onBlur={checkDuplicateEmail}
                              error={emailError}
                              inputProps={{ maxLength: 255 }}
                              size='small'
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ borderBottom: 'none' }}>
                            <TextField
                              variant='outlined'
                              type={valuesPassword.showPassword ? 'text' : 'password'}
                              //required
                              size='small'
                              fullWidth
                              id='password'
                              label='Password'
                              name='password'
                              value={password}
                              autoComplete='password'
                              onChange={handlePasswordChange}
                              helperText={passwordError ? passwordErrorMessage : null}
                              error={passwordError}
                              inputProps={{ maxLength: 100 }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <IconButton
                                      aria-label='toggle password visibility'
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {valuesPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                            <PasswordStrengthBar
                              password={password}
                              onChangeScore={(score,feedback) => {
                                  setPasswordScore(score)
                                }
                              } />
                          </TableCell>
                          <TableCell style={{ verticalAlign: 'top', borderBottom: 'none' }} >
                          <TextField
                            variant='outlined'
                            type={valuesPasswordVerifier.showPasswordVerifier ? 'text' : 'password'}
                            //required
                            size='small'
                            fullWidth
                            id='password_verifier'
                            label='Password Verifier'
                            name='password_verifier'
                            autoComplete='password'
                            value={passwordVerifier}
                            onChange={handlePasswordVerifierChange}
                            helperText={passwordVerifierError ? passwordVerifierErrorMessage : null}
                            error={passwordVerifierError}
                            inputProps={{ maxLength: 100 }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <IconButton
                                    aria-label='toggle password verifier visibility'
                                    onClick={handleClickShowPasswordVerifier}
                                    onMouseDown={handleMouseDownPasswordVerifier}
                                  >
                                    {valuesPasswordVerifier.showPasswordVerifier ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                          </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={2} style={{ borderBottom: 'none' }}>
                              <Button
                                variant='contained'
                                color='primary'
                                size='small'
                                className={classes.button}
                                startIcon={<Edit />}
                                onClick={handleSaveSubmit}
                              >
                                Save User Changes to {firstName}
                              </Button>
                            </TableCell>
                          </TableRow>
                      </Table>
                    </Paper>
                  </div>
                )}
              </LoadingOverlay>
            </div>
          </Grid>
        </Grid>
        <Modal
          aria-labelledby='spring-modal-title'
          aria-describedby='spring-modal-description'
          className={classes.modal}
          open={modalOpen}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 1000 }}
        >
          <Fade in={modalOpen}>
            <div className={classes.smallModalPaper}>
              <h2 id='spring-modal-title'>
                <CheckCircle style={{ color: '#156E01' }} /> The details for {firstName} have been saved
              </h2>
            </div>
          </Fade>
        </Modal>
      </TableCell>
    </TableRow>
  )
}

export default UserExpandableRowDisplay
