import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'
import PasswordStrengthBar from 'react-password-strength-bar'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import {
  Container,
  Grid,
  Button,
  Typography,
  TextField,
  InputAdornment,
  Paper,
  Link,
  IconButton,
  Modal,
  Table,
  TableRow,
  TableCell
} from '@material-ui/core'
import ShowTOTP from './ShowTOTP'
import TOTPSwitch from './TOTPSwitch'

import fetchData from '../../utility/endpointFetch'
import Footer from '../Footer'
import hofs from '../../utility/hofs'
import { getAppStyles } from '../App/AppStyles'

const firstNameErrorMessage = 'First name is required'
const lastNameErrorMessage = 'Last name is required'
const emailErrorMessageBasic = 'Email address is required'
const passwordErrorMessage =
  'User password is required with a minimum password strength score of 3 and both password and password verifier must match.'

const UsersUnpaid = () => {
  const classes = getAppStyles()
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [accountUser, setAccountUser] = useState([])
  const [userID, setUserID] = useState('')
  const [firstName, setFirstName] = useState('')
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastName, setLastName] = useState('')
  const [lastNameError, setLastNameError] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [passwordVerifier, setPasswordVerifier] = useState('')
  const [passwordVerifierError, setPasswordVerifierError] = useState(false)
  const [passwordVerifierErrorMessage, setPasswordVerifierErrorMessage] = useState('')
  const [passwordScore, setPasswordScore] = useState(0)
  const [totpCode, setTotpCode] = useState('')
  const [totpProvisioningURI, setTotpProvisioningURI] = useState('')
  const [loadingTOTP, setLoadingTOTP] = useState(true)

  const [valuesPassword, setValuesPassword] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false
  })

  const [valuesPasswordVerifier, setValuesPasswordVerifier] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPasswordVerifier: false
  })

  useEffect(() => {
    const { promise, cancel } = fetchData('/users/user', 'Error retrieving user attached to account')
    ;(async () => {
      const response = await promise
      setAccountUser(response)
      setUserID(response.user_id)
      setEmail(response.email)
      setFirstName(response.first_name)
      setLastName(response.last_name)
      setTotpCode(response.totp_code)
      setTotpProvisioningURI(response.totp_provisioning_uri)
      setIsLoading(false)
    })()
    return cancel
  }, [])

  const handleClickShowPassword = () =>
    setValuesPassword({ ...valuesPassword, showPassword: !valuesPassword.showPassword })

  const handleClickShowPasswordVerifier = () =>
    setValuesPasswordVerifier({
      ...valuesPasswordVerifier,
      showPasswordVerifier: !valuesPasswordVerifier.showPasswordVerifier
    })

  const handleMouseDownPassword = e => e.preventDefault()
  const handleMouseDownPasswordVerifier = e => e.preventDefault()
  const handleFirstNameChange = e => setFirstName(e.target.value)
  const handleLastNameChange = e => setLastName(e.target.value)
  const handleEmailChange = e => setEmail(e.target.value)
  const handlePasswordChange = e => setPassword(e.target.value)
  const handlePasswordVerifierChange = e => setPasswordVerifier(e.target.value)
  const handleUserEditSubmit = e => {
    e.preventDefault()
    let errorCount = 0
    // first is to check if all required forms are filled
    if (hofs.isPopulated(firstName)) {
      setFirstNameError(false)
    } else {
      errorCount++
      setFirstNameError(true)
    }

    if (hofs.isPopulated(lastName)) {
      setLastNameError(false)
    } else {
      errorCount++
      setLastNameError(true)
    }

    if (hofs.isPopulated(email)) {
      setEmailError(false)
    } else {
      errorCount++
      setEmailError(true)
    }

    if (hofs.isPopulated(password) || hofs.isPopulated(passwordVerifier)) {
      // pw and/or verifier is not empty....client wishes to change
      if (hofs.isPopulated(password)) {
        setPasswordError(false)
      } else {
        errorCount++
        setPasswordError(true)
      }

      if (passwordScore < 3) {
        //we want a stronger password than that
        errorCount++
        setPasswordError(true)
      } else {
        if (hofs.isPopulated(password)) {
          setPasswordError(false)
        } else {
          errorCount++
          setPasswordError(true)
        }
      }

      if (hofs.isPopulated(passwordVerifier)) {
        if (passwordVerifier === password) {
          setPasswordVerifierError(false)
          setPasswordVerifierErrorMessage('')
        } else {
          errorCount++
          setPasswordVerifierError(true)
          setPasswordVerifierErrorMessage(
            'Your password verifier must match the password inserted above.  Please check and make sure both match'
          )
        }
      } else {
        errorCount++
        setPasswordVerifierError(true)
        setPasswordVerifierErrorMessage(
          'You must insert a password verifier that matches your password to complete submission'
        )
      }
    }

    if (errorCount === 0) {
      // there are no errors...send the data to backend to complete registration
      const fetchParams = {
        method: 'PUT',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          email,
          password
        })
      }
      const errorMessage = 'Error saving your user details.  Please contact support to do so.'
      ;(async () => {
        const response = await fetchData('/users/user', errorMessage, fetchParams).promise
        if (!response.error) {
          setIsLoading(false)
          setModalOpen(true)
        }
      })()
    }
  }

  const handleTOTPChange = () => {
    //only the admin can see this feature so need to pass the user id this will be for
    var totpChoice = false //assume off by default
    if ((totpProvisioningURI == "") || (totpProvisioningURI == undefined)) totpChoice = true //Turn totp use one for this user
    setLoadingTOTP(true)
    const fetchParams = {
      method: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        totpChoice: totpChoice,
        change_user_id: userID
      })
    }
    let totp_error_message = "Could not save time-based password configuration changes"
    ;(async () => {
      const response = await fetchData(`/login/totp_choice`, totp_error_message, fetchParams).promise
      setTotpProvisioningURI(response.totp_provisioning_uri)
      setTotpCode(response.totp_code)
      setLoadingTOTP(false)
    })()
  }

  const handleModalClose = () => {
    setModalOpen(false)
    history.push('/')
  }

  return (
    <>
      <Container maxWidth='xl' className={classes.container}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={6} lg={6}>
              <Typography align='left' variant='h5' className={classes.contentTitle}>
                Edit User Information
              </Typography>

              <LoadingOverlay active={isLoading} spinner text='Loading Account Information...' fadeSpeed={200}>
                {isLoading ? null : (
                  <form className={classes.form} noValidate onSubmit={handleUserEditSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          variant='outlined'
                          defaultValue={accountUser.first_name}
                          fullWidth
                          id='first_name'
                          label='First Name'
                          name='first_name'
                          onChange={handleFirstNameChange}
                          helperText={firstNameError ? firstNameErrorMessage : null}
                          error={firstNameError}
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant='outlined'
                          required
                          defaultValue={accountUser.last_name}
                          fullWidth
                          id='lastName'
                          label='Last Name'
                          name='last_name'
                          autoComplete='lname'
                          onChange={handleLastNameChange}
                          helperText={lastNameError ? lastNameErrorMessage : null}
                          error={lastNameError}
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name='email'
                          defaultValue={accountUser.email}
                          variant='outlined'
                          fullWidth
                          id='email'
                          label='email'
                          onChange={handleEmailChange}
                          helperText={emailError ? emailErrorMessageBasic : null}
                          error={emailError}
                          inputProps={{ maxLength: 255 }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography align='left' color='inherit'>
                          Leave password empty to leave unchanged.
                        </Typography>
                        <TextField
                          variant='outlined'
                          type={valuesPassword.showPassword ? 'text' : 'password'}
                          required
                          fullWidth
                          id='password'
                          label='Password'
                          name='password'
                          autoComplete='password'
                          onChange={handlePasswordChange}
                          helperText={passwordError ? passwordErrorMessage : null}
                          error={passwordError}
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle password visibility'
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {valuesPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                        <PasswordStrengthBar
                          password={password}
                          onChangeScore={score => setPasswordScore(score)}
                          scoreWordClassName={classes.passwordStrengthBar}
                          barColors={['#999999', '#ef4836', '#f6b44d', '#2b90ef', '#25c281']}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant='outlined'
                          type={valuesPasswordVerifier.showPasswordVerifier ? 'text' : 'password'}
                          required
                          fullWidth
                          id='password_verifier'
                          label='Password Verifier'
                          name='password_verifier'
                          autoComplete='password'
                          onChange={handlePasswordVerifierChange}
                          helperText={passwordVerifierError ? passwordVerifierErrorMessage : null}
                          error={passwordVerifierError}
                          inputProps={{ maxLength: 100 }}
                          InputProps={{ endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password verifier visibility'
                                onClick={handleClickShowPasswordVerifier}
                                onMouseDown={handleMouseDownPasswordVerifier}
                              >
                                {valuesPasswordVerifier.showPasswordVerifier ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )}}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button type='submit' fullWidth variant='contained' color='primary' className={classes.submit}>
                          Save User Changes
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </LoadingOverlay>
            </Grid>
            <Grid style={{marginTop: "50px"}} item xs={6} justify="flex-end" alignItems="flex-end" direction="row">
              <Table>
                <TableRow>
                  <TableCell width="50%" align="right">
                    <TOTPSwitch totpProvisioningURI={totpProvisioningURI} handleTOTPChange={handleTOTPChange} />
                  </TableCell>
                  <TableCell width="50%" align="center">
                    <ShowTOTP totpProvisioningURI={totpProvisioningURI} totpCode={totpCode} />
                  </TableCell>
                </TableRow>
              </Table>
            </Grid>

          </Grid>
        </Paper>
        <Modal
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby='machine-modal-title'
          aria-describedby='machine-modal-description'
        >
          <div className={classes.modalPaper}>
            <Typography align='center' variant='h4' color='inherit' gutterBottom>
              Def-Logix User Details Edit
            </Typography>
            <Typography align='center' variant='h6' color='inherit' gutterBottom>
              Your user details have been saved!
            </Typography>
            <Typography align='center'>
              <Link to='/'>
                <Button href='/' variant='contained' color='primary'>
                  Continue to Your Account Home
                </Button>
              </Link>
            </Typography>
          </div>
        </Modal>
      </Container>
      <Footer />
    </>
  )
}

export default UsersUnpaid
