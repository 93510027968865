import React, { useState, useEffect } from 'react'
import { Avatar, Typography, Container, CircularProgress } from '@material-ui/core'
import { useParams, useHistory } from 'react-router-dom'

import fetchData from '../../utility/endpointFetch'
import PasswordResetForm from './PasswordResetForm'
import { getLoginStyles } from './LoginStyles'

const PasswordResetVerify = () => {
  const { token } = useParams()
  const history = useHistory()
  //console.log(`token is: ${token}`)
  const classes = getLoginStyles()

  const [verifyingToken, setVerifyingToken] = useState(true)

  // accepts the token passed in the url
  // sends that to login/verify endpoint
  // when receive a positive displays form to reset password
  // if receive a false displays a message saying did not

  useEffect(() => {
    const fetchURL = `/login/reset/verify/${token}`
    const { promise, cancel } = fetchData(fetchURL, 'Error retrieving verification for password reset token')
    ;(async () => {
      const response = await promise
      if (response.reset_user) {
        // token verified...display password reset form below
        setVerifyingToken(false)
      } else {
        // just go somewhere else
        history.push('/login/resetverify/error')
      }
    })()
    return cancel
  }, [token, history])

  return (
    <Container component='main' maxWidth='xs'>
      <div className={classes.paper}>
        <Avatar className={classes.avatar} src='/def-logix-d-larger.png' />
        <Typography component='h1' variant='h5'>
          Password Reset
        </Typography>
        {verifyingToken ?
          <Typography>Checking submitted token....<p><CircularProgress /></p></Typography> :
          <PasswordResetForm token={token} />
        }
      </div>
    </Container>
  )
}

export default PasswordResetVerify
