import React, { useState, useEffect } from 'react'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'
import { NavLink } from 'react-router-dom'
import {
  Button, Grid, Container, Table, TableBody, TableCell, TableRow, Paper, TableContainer
} from '@material-ui/core'

import PageTitle from '../PageTitle'
import Footer from '../Footer'

import fetchData from '../../utility/endpointFetch'
import hofs from '../../utility/hofs'
import { getAppStyles } from '../App/AppStyles'

const Account = () => {
  const classes = getAppStyles()
  const [accountInfo, setAccountInfo] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const currentRole = hofs.getRole()

  useEffect(() => {
    const { promise, cancel } = fetchData('/account/', 'Error retrieving account information')
    ;(async () => {
      const response = await promise
      if (!hofs.isPopulated(response.company_name)) response.company_name = '(no company name inserted)'
      if (!hofs.isPopulated(response.address_1)) response.address_1 = '(no address inserted)'
      if (!hofs.isPopulated(response.city)) response.city = '(no city inserted)'
      if (!hofs.isPopulated(response.state)) response.state = '(no state inserted)'
      if (!hofs.isPopulated(response.postal_code)) response.postal_code = '(no post code inserted)'
      if (!hofs.isPopulated(response.industry)) response.industry = '(no industry chosen)'
      if (!hofs.isPopulated(response.phone_number)) response.phone_number = '(no phone inserted)'
      setAccountInfo(response)
      setIsLoading(false)
    })()
    return cancel
  }, [])

  return (
    <>
      <Container maxWidth='xl' className={classes.container}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <PageTitle title='Account Information' />
            <Grid item xs={12}>
              <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading Account Information...'
                fadeSpeed={200}
              >
                {isLoading ? null : (
                  <TableContainer component={Paper}>
                    <Table className={classes.tableBackground} aria-label='simple table'>
                      <TableBody>
                        <TableRow>
                          <TableCell align='right' className={classes.leftColumn}>Company Name:</TableCell>
                          <TableCell align='left'>{accountInfo.company_name}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align='right'className={classes.leftColumn}>Address:</TableCell>
                          <TableCell align='left'>{accountInfo.address_1}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align='right'className={classes.leftColumn}>Address 2:</TableCell>
                          <TableCell align='left'>{accountInfo.address_2}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align='right'className={classes.leftColumn}>City, State Postal Code:</TableCell>
                          <TableCell align='left'>
                            {accountInfo.city}, {accountInfo.state} {accountInfo.postal_code}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align='right'className={classes.leftColumn}>Industry:</TableCell>
                          <TableCell align='left'>{accountInfo.industry}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align='right'className={classes.leftColumn}>Phone Number:</TableCell>
                          <TableCell align='left'>{accountInfo.phone_number}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </LoadingOverlay>
              {currentRole !== 'client_admin' ? null : (
                <Button
                  component={NavLink}
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                  href='/account/edit'
                  to='/account/edit'
                >
                  Edit Company Info
                </Button>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Footer />
    </>
  )
}

export default Account
