import React, { useEffect, useState } from 'react'
import { Grid, TableContainer, Paper, Button, Modal, TextField } from '@material-ui/core'
import { VpnKey as VpnKeyIcon } from '@material-ui/icons'
import MUIDataTable from 'mui-datatables'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'
import hofs from '../../utility/hofs'

import fetchData from '../../utility/endpointFetch'
import { getAppStyles } from '../App/AppStyles'
import { keyColumns } from './KeysColumns'

const keyOptions = {
  filter: true,
  filterType: 'dropdown',
  responsive: 'vertical',
  selectableRows: 'none'
}

const addNewLicenseKey = (setReloadData, setLoading, setModalOpen) => e => {
  e.preventDefault()
  setModalOpen(false)
  setLoading(true)
  ;(async () => {
    const fetchParams = {
      method: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ key_description: 'temp key description' })
    }
    const errorMessage = 'Error adding a new license key attached to account'
    await fetchData('/licenses/new', errorMessage, fetchParams).promise
    setReloadData({})
  })()
}

const KeysPaid = () => {
  const classes = getAppStyles()
  const currentRole = hofs.getRole()

  const [loading, setLoading] = useState(true)
  const [accountKeys, setAccountKeys] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [newKeyLabel, setNewKeyLabel] = useState('')

  // This is simply to trigger a reload of data.
  // The value itself does not matter, only that it reruns the fetch effect when changed
  const [reloadData, setReloadData] = useState({})

  useEffect(() => {
    setLoading(true)
    const { promise, cancel } = fetchData('/licenses/', 'Error retrieving keys attached to account')
    ;(async () => {
      const response = await promise
      setAccountKeys(response)
      // setAccountKeysDisabled(response[1])
      setLoading(false)
    })()
    return cancel
  }, [reloadData])

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <LoadingOverlay
          active={loading}
          spinner
          text='Loading License Keys...'
          fadeSpeed={200}
        >
          <MUIDataTable
            title=''
            data={accountKeys}
            columns={keyColumns(setReloadData, setLoading)}
            options={keyOptions}
          />
        </LoadingOverlay>
      </TableContainer>
      { currentRole === "client_admin" ?
        <Button
          variant='contained'
          style={{ backgroundColor: '#128C01', color: '#FFFFFF', maxWidth: '300px', marginTop: '10px' }}
          size='small'
          className={classes.button}
          onClick={() => setModalOpen(true)}
          startIcon={<VpnKeyIcon />}
        >
          add new license key
        </Button> : null }
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby='machine-modal-title'
        aria-describedby='machine-modal-description'
      >
        <div className={classes.modalPaper}>
          <h2 id='simple-modal-title'>
            Adding A New License Key To Your Account
          </h2>
          <p id='simple-modal-description'>
            Please give your new license key a label to make identifying a specific key easy for you
          </p>
          <form
            className={classes.form}
            noValidate
            onSubmit={addNewLicenseKey(setReloadData, setLoading, setModalOpen)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  fullWidth
                  id='key_label'
                  label='Key Label'
                  name='key_label'
                  value={newKeyLabel}
                  onChange={e => setNewKeyLabel(e.target.value)}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                >
                  Add New License Key
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Modal>
    </>
  )
}

export default KeysPaid
