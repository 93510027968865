import React from 'react'
import { Container, Paper, Typography, Grid, Button } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import hofs from '../../utility/hofs'
import { getAppStyles } from '../App/AppStyles'

const DisplaySubscriptionDetails = ({ subscription_details }) => {
  const classes = getAppStyles()
  const currentRole = hofs.getRole()
  //if (subscription_details) {
  return (
    <>
      <Container maxWidth='xl' className={classes.container}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant='h5' gutterBottom>
              Details of your subscription
            </Typography>
            <Typography>
              Current Subscription Period Start Date: {subscription_details.period_start_time_display}
            </Typography>
            <Typography>
              Current Subscription Period End Date: {subscription_details.period_end_time_display}
            </Typography>
          </Paper>
        </Grid>
      </Container>
    </>
  )
  //} else {
    ///*
    //if (currentRole === 'unpaid_user') {
    //  return (
    //    <Button
    //      variant="contained"
    //      color='primary'
    //      component={NavLink}
    //      to='/payments/start'
    //      href='/payments/start'
    //   >
    //      I'm ready....Let's start my subscription
    //    </Button>
    //  )
    //} else {
    //  return null
    //}*/}
  //}
}

export default DisplaySubscriptionDetails
