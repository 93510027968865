import { makeStyles } from '@material-ui/core/styles'

export const userIconDisplayStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1)
  },
  popover: {
    pointerEvents: 'none'
  }
}))
