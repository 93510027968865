import { Typography, TableContainer, Table, TableRow, TableCell, TableBody, Paper } from '@material-ui/core'
import { Report as ReportIcon, Warning as WarningIcon } from '@material-ui/icons'

import { loginErrorMessageDisplayStyles } from './LoginStyles'

const LoginErrorMessageDisplay = ({ errorMessage, secondaryErrorMessage = false }) => {
  const classes = loginErrorMessageDisplayStyles()
  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} padding='normal'>
          <TableBody>
            <TableRow key='some_key'>
              <TableCell>
                <ReportIcon fontSize='large' className={classes.reporticon} />
              </TableCell>
              <TableCell>
                <Typography variant='button' color='error'>{errorMessage}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {!secondaryErrorMessage ? null :
        <TableContainer component={Paper}>
          <Table className={classes.table} padding='normal'>
            <TableBody>
              <TableRow key='some_key2'>
                <TableCell>
                  <WarningIcon fontSize='large' className={classes.warningicon} />
                </TableCell>
                <TableCell>
                  <Typography variant='button' gutterBottom color='error'>{secondaryErrorMessage}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      }
    </div>
  )
}

export default LoginErrorMessageDisplay
