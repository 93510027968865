import React, { useState, Fragment } from 'react'

import { Container } from '@material-ui/core'

import { getRegisterStyles } from './RegisterStyles'
import Footer from '../Footer'

import RegisterOneUser from './RegisterOneUser'
import RegisterTwoVerification from './RegisterTwoVerification'
import RegisterStepComplete from './RegisterStepComplete'

const Register = () => {
  const classes = getRegisterStyles()

  const [ registrationStep, setRegistrationStep ] = useState(0)

  switch ( registrationStep ) {

    case 0:
      return (
        <Fragment>
          <Container >
            <RegisterOneUser
              setRegistrationStep={setRegistrationStep}
            />
          </Container>
          <Footer />
        </Fragment>
      )
    case 1:
      return (
        <Fragment>
          <Container >
            <RegisterTwoVerification
              setRegistrationStep={setRegistrationStep}
            />
          </Container>
          <Footer />
        </Fragment>
      )
    case 2:
      return (
        <Fragment>
          <Container >
            <RegisterStepComplete />
          </Container>
          <Footer />
        </Fragment>
      )
    default:

        //something is jacked....do nothing
        return null

  }
}

export default Register
