import React, { useEffect, useState } from 'react'
import { Table, TableRow, TableCell, CircularProgress } from '@material-ui/core'
import clsx from 'clsx'

import fetchData from '../../utility/endpointFetch'
import { dashboardStyles } from './DashboardStyles'

const AccountStats = () => {
  const classes = dashboardStyles()

  const [accountStats, setAccountStats] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const { promise, cancel } = fetchData('/account/stats', 'Error retrieving account wide stats')
    ;(async () => {
      const response = await promise
      setAccountStats(response.account_stats)
      setIsLoading(false)
    })()
    return cancel
  }, [])

  if (isLoading) return <CircularProgress />
  return (
    <div>
      <Table size='small'>
        <tbody>
          <TableRow>
            <TableCell align='right' className={classes.noBorderTable}>
              Account Created:
            </TableCell>
            <TableCell align='left' className={clsx(classes.noBorderTable, classes.boldedText)}>
              {accountStats.account_created}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='right' className={classes.noBorderTable}>
              Users Active/Inactive:
            </TableCell>
            <TableCell align='left' className={clsx(classes.noBorderTable, classes.boldedText)}>
              {accountStats.active_users} / {accountStats.inactive_users}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='right' className={classes.noBorderTable}>
              Licenses Active/Inactive:
            </TableCell>
            <TableCell align='left' className={clsx(classes.noBorderTable, classes.boldedText)}>
              {accountStats.active_licenses} / {accountStats.inactive_licenses}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='right' className={classes.noBorderTable}>
              Machines Active/Inactive:
            </TableCell>
            <TableCell align='left' className={clsx(classes.noBorderTable, classes.boldedText)}>
              {accountStats.active_machines} / {accountStats.inactive_machines}
            </TableCell>
          </TableRow>
        </tbody>
      </Table>
    </div>
  )
}

export default AccountStats
