import React from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { Container, Grid, Paper } from '@material-ui/core'

import hofs from '../../../utility/hofs'
import { adminDashboardStyles } from './AdminDashboardStyles'

const AdminDashboard = () => {
  const classes = adminDashboardStyles()
  const history = useHistory()

  if (!hofs.checkLogStatus() || hofs.getRole() !== 'admin') {
    // either no token or expired token...login
    // or this user is not an admin....direct to /login
    history.push('/login')
  } else {
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)

    return (
      <Container maxWidth='xl' className={classes.container}>
        <Grid container spacing={3}>
          {/* Chart */}
          <Grid item xs={12} md={8} lg={9}>
            <Paper className={fixedHeightPaper}>something cool here</Paper>
          </Grid>
          {/* Recent Deposits */}
          <Grid item xs={12} md={4} lg={3}>
            <Paper className={fixedHeightPaper}>and here</Paper>
          </Grid>
          {/* Recent Orders */}
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              {/* <Orders /> */}
              Latest actions by clients?
            </Paper>
          </Grid>
        </Grid>
      </Container>
    )
  }
}

export default AdminDashboard
