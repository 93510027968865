import { Tooltip, IconButton, Link } from '@material-ui/core'
import { Edit, MonetizationOn } from '@material-ui/icons'

import hofs from '../../../utility/hofs'

const columns = [
  {
    name: 'account_id',
    label: '',
    options: {
      display: false,
      filter: false,
      sort: false,
      viewColumns: false,
      searchable: false
    }
  }, {
    name: 'user_details',
    label: '',
    options: {
      display: false,
      filter: false,
      sort: false,
      viewColumns: false,
      searchable: false
    }
  }, {
    name: 'company_name',
    label: 'Company ',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: (value, tableMeta) => {
        if ((value === null) || (!hofs.isPopulated(value))) {
          return (
            <div style={{ textTransform: 'capitalize' }}>
              {`( ${tableMeta.rowData[1].first_name} ${tableMeta.rowData[1].last_name} )`}
            </div>
          )
        } else {
          return <div>{value}</div>
        }
      }
    }
  }, {
    name: 'city',
    label: 'City',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: (value, tableMeta) => {
        if (value === null) {
          return <div> - </div>
        } else {
          return <div>{value}</div>
        }
      }
    }
  }, {
    name: 'state',
    label: 'State ',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: (value, tableMeta) => {
        if (value === null) {
          return <div> - </div>
        } else {
          return <div>{value}</div>
        }
      }
    }
  }, {
    name: 'industry',
    label: 'Industry',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: (value, tableMeta) => {
        if (value === null) {
          return <div> - </div>
        } else {
          return <div>{value}</div>
        }
      }
    }
  }, {
    name: 'created_at',
    label: 'Opened',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: value => <div>{new Date(value).toLocaleDateString('en-US')}</div>
    }
  }, {
    name: 'account_status',
    label: 'Paid',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: value => {
        if (value === 'paid') {
          return (
            <Tooltip title='Paid Account'>
              <IconButton size='small' aria-label='Paid Account'>
                <MonetizationOn style={{ color: '#009105' }} />
              </IconButton>
            </Tooltip>
          )
        } else {
          return (
            <Tooltip title='Unpaid Account'>
              <IconButton size='small' aria-label='Unpaid Account'>
                <MonetizationOn style={{ color: '#C9C9C9' }} />
              </IconButton>
            </Tooltip>
          )
        }
      }
    }
  }, {
    name: '',
    label: 'Edit',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <div>
            <Tooltip title='Edit Account Details'>
              <IconButton
                size='small'
                aria-label='Edit Account Details'
                component={Link}
                to={`/admin/account/edit/${tableMeta.rowData[0]}`}
              >
                <Edit style={{ color: '#0275E8' }} />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    }
  }
]

export default columns
