import React, { useState } from 'react'
import { useParams } from 'react-router'
import { ArrowRight, Cancel } from '@material-ui/icons'
import {
  ListItemIcon,
  Container,
  Modal,
  List,
  ListItem,
  Paper,
  Button,
  ListItemText,
  Typography
} from '@material-ui/core'

import hofs from '../../utility/hofs'
import Footer from '../Footer'
import MachinesUnpaid from './MachinesUnpaid'
import MachinesPaid from './MachinesPaid'
import { getAppStyles } from '../App/AppStyles'

const Machines = () => {
  const classes = getAppStyles()
  const currentRole = hofs.getRole()
  const { filter } = useParams()
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <Container maxWidth='xl' className={classes.container}>
        <Paper className={classes.paper}>
          <Typography align='left' variant='h5' className={classes.contentTitle}>
            Account Machines
          </Typography>
          {currentRole === 'unpaid_user'
            ? <MachinesUnpaid />
            : <MachinesPaid filter={filter} handleModalOpen={() => setModalOpen(true)} />
          }
          <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            aria-labelledby='machine-modal-title'
            aria-describedby='machine-modal-description'
          >
            <div className={classes.modalPaper}>
              <h2 id='simple-modal-title'>Adding A Machine To Your Account</h2>
              <p id='simple-modal-description'>
                You can not add machines directly within this tool. To add machines to your account you need:
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowRight />
                    </ListItemIcon>
                    <ListItemText>
                      <strong>A valid license key</strong> - you can get one from this user portal tool in the
                      'License Keys' section.
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowRight />
                    </ListItemIcon>
                    <ListItemText>
                      <strong>Downloaded mitigation tool to the machine you wish to add</strong>. You can find this in
                      the 'Mitigation Tool' link within the left side of this user portal tool.
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowRight />
                    </ListItemIcon>
                    <ListItemText>
                      <strong>Install the mitigation tool</strong> to the machine you wish to add.
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowRight />
                    </ListItemIcon>
                    <ListItemText>
                      <strong>Insert the license key</strong> described above into the mitigation tool installation
                      process when asked.
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ArrowRight />
                    </ListItemIcon>
                    <ListItemText>
                      <strong>That machine will automatically be added to your account</strong>.
                    </ListItemText>
                  </ListItem>
                </List>
              </p>
              <p>
                The mitigation tool provides all the necessary information to the user portal for that machine to be
                added to your account. There is no need for extra steps. We will add more features in the future to
                save more time managing security configurations across your enterprise.
              </p>
              <Button
                variant='contained'
                style={{ backgroundColor: '#7A0A0A', color: '#FFFFFF', maxWidth: '150px', marginTop: '10px' }}
                size='small'
                className={classes.button}
                onClick={() => setModalOpen(false)}
                startIcon={<Cancel />}
              >
                close
              </Button>
            </div>
          </Modal>
        </Paper>
      </Container>
      <Footer />
    </>
  )
}

export default Machines
