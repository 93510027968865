import React from 'react'
import { Container, Paper, Typography, Grid } from '@material-ui/core'
import PageTitle from '../PageTitle'
import Footer from '../Footer'
import { getAppStyles } from '../App/AppStyles'

const PaymentsComplete = () => {
  const classes = getAppStyles()
  return (
    <>
      <Container maxWidth='xl' className={classes.container}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <PageTitle title='Payment Details' />
            <Typography>
              Your subscription has been setup and is active from this time.
              <p>
                Your subscription has been established.  We will poll the number of machines you have on your account
                several times before your next subscription payment is due.  The amount you will be billed is based on
                the highest number of machines attached to your account when those poles were taken.  You will then be
                automatically billed the amount based on that high machine count.  Let us know if you have questions
                regarding your account or how it's billed.
              </p>
            </Typography>
          </Grid>
        </Paper>
      </Container>
      <Footer />
    </>
  )
}

export default PaymentsComplete
