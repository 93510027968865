import React, { useEffect, useState } from 'react'
import { Table, TableRow, TableCell, CircularProgress } from '@material-ui/core'
import clsx from 'clsx'

import fetchData from '../../utility/endpointFetch'
import { getAppStyles } from '../App/AppStyles'

const MachineStats = () => {
  const classes = getAppStyles()

  const [machineStats, setMachineStats] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const { promise, cancel } = fetchData('/machines/stats', 'Error retrieving account wide machine stats')
    ;(async () => {
      const response = await promise
      // console.log('machine stats response is:')
      // console.dir(response)
      setMachineStats(response.machine_stats)
      setIsLoading(false)
    })()
    return cancel
  }, [])

  if (isLoading) return <CircularProgress />
  return (
    <div>
      <Table size='small'>
        <tbody>
          <TableRow>
            <TableCell align='right' className={classes.noBorderTable}>
              Machines Active/Inactive:
            </TableCell>
            <TableCell align='left' className={clsx(classes.noBorderTable, classes.boldedText)}>
              {machineStats[0].length} / {machineStats[1].length}
            </TableCell>
          </TableRow>
        </tbody>
      </Table>
    </div>
  )
}

export default MachineStats
