import { makeStyles } from '@material-ui/core/styles'

export const getHierarchyStyles = makeStyles(theme => ({
  Hierarchy__base: {
    marginTop: '1rem'
  },
  Hierarchy__actionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    marginBottom: '1rem',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      flexDirection: 'column'
    }
  },
  Hierarchy__actions: {
    flex: 1,
    display: 'inline-flex',
    flexDirection: 'row-reverse',
    flexWrap: 'wrap',
    [theme.breakpoints.only('md')]: {
      marginTop: '1rem'
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      width: '100%'
    }
  },
  Hierarchy__actions_button: {
    marginLeft: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem'
    }
  },
  HierarchyRow__base: {
    textAlign: 'left',
    color: 'black',
    marginLeft: '1rem'
  },
  HierarchyRow__infoLink: {
    fontSize: '1rem',
    marginBottom: '-.20rem',
    marginLeft: theme.spacing(.5),
    marginRight: theme.spacing(.5),
    cursor: 'pointer'
  },
  LeafRow__base: {
    color: 'black',
    marginLeft: '1rem',
    display: 'flex',
    alignItems: 'center'
  },
  LeafRow__classificationIconWrapper: {
    color: '#3f51b5',
    width: '1em',
    cursor: 'default',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(2)
  },
  LeafRow__faIcon: {
    fontSize: '1rem'
  },
  LeafRow__rowItem: {
    marginRight: theme.spacing(.5),
    display: 'inline-flex',
    alignItems: 'center'
  },
  LeafRow__consequencesWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  LeafRow__infoLink: {
    fontSize: '1rem',
    marginBottom: '-.22rem',
    cursor: 'pointer'
  },
  LeafRow__severityIconWrapper: {
    marginLeft: theme.spacing(1.5),
    width: '1em',
    display: 'flex'
  },
  LeafRow__severityIcon: {
    fontSize: '1rem',
    marginTop: '2px'
  },
  InfoModal__infoLinks: {
    display: 'inline-block',
    marginRight: '1rem',
    marginTop: '1rem'
  }
}))
