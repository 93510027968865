import React, { useEffect, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'

import { getAppStyles } from '../App/AppStyles'

// import { mockReportStats } from '../../StaticData/mockSecurityReportData'

const doughnutOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'left'
    }
  }
}

const MachineClassificationChart = ({ data }) => {
  const classes = getAppStyles()
  const [classificationData, setClassificationData] = useState()
  useEffect(() => {
    if (data && data.last_report_date) {
      setClassificationData({
        labels: data.classification_labels,
        datasets: [{
          label: 'Classification',
          data: Object.values(data.classification_counts),
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }
      ]})
    }
  }, [data])

  return (
    <div className={classes.doughnutContainer}>
      {classificationData ? (
        <Doughnut data={classificationData} options={doughnutOptions} />
      ) : (
        <div style={{ marginTop: '1rem' }}>Run an analysis on your machine to get a report!</div>
      )}
    </div>
  )
}

export default MachineClassificationChart
