import { History } from '@material-ui/icons'
import { Tooltip, IconButton } from '@material-ui/core'
import { Link } from 'react-router-dom'

import MachineState from './MachineState'

const columns = (setLoading, setReloadData) => [
  {
    name: 'machine_id',
    label: '',
    options: {
      display: false,
      filter: false,
      sort: false,
      viewColumns: false,
      searchable: false
    }
  }, {
    name: 'client_machine_id',
    label: 'Client Machine ID',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, {
    name: 'machine_name',
    label: 'Machine Name',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, {
    name: 'company_name',
    label: 'Company ',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, {
    name: 'key',
    label: 'Key Covering',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, {
    name: 'first_covered',
    label: 'Date Added',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: value => <div>{new Date(value).toLocaleDateString('en-US')}</div>
    }
  }, {
    name: 'active',
    label: 'Active ',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: (value, tableMeta) => (
        <MachineState
          enabled={value === 1}
          machineId={tableMeta.rowData[0]}
          setLoading={setLoading}
          setReloadData={setReloadData}
        />
      )
    }
  }, {
    name: '',
    label: 'History',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => (
        <div>
          <Tooltip title='Machine History'>
            <IconButton
              size='small'
              aria-label='Machine History'
              component={Link}
              to={`/admin/machines/history/${tableMeta.rowData[0]}`}
            >
              <History style={{ color: '#0275E8' }} />
            </IconButton>
          </Tooltip>
        </div>
      )
    }
  }
]

export default columns
