import React from 'react'
import { Link } from 'react-router-dom'
import { Tooltip, IconButton } from '@material-ui/core'
import { History, Delete, AddCircle } from '@material-ui/icons'

import hofs from '../../utility/hofs'
import fetchData from '../../utility/endpointFetch'

const disableKey = (keyId, setReloadData, setLoading) => () => {
  setLoading(true)
  // disable the key passed then reload data
  ;(async () => {
    await fetchData(`/licenses/disable/${keyId}`, 'Error disabling a key attached to account').promise
    setReloadData({})
  })()
}

const enableKey = (keyId, setReloadData, setLoading) => () => {
  setLoading(true)
  // enable the key passed then reload data
  ;(async () => {
    await fetchData(`/licenses/enable/${keyId}`, 'Error enabling a key attached to account').promise
    setReloadData({})
  })()
}

const KeyActions = ({ enabled, keyId, setReloadData, setLoading }) => {
  const currentRole = hofs.getRole()
  return (
    <>
      <Tooltip title='Key History'>
        <IconButton size='small' aria-label='History' component={Link} to={`/licenses/history/${keyId}`}>
          <History style={{ color: '#0275E8' }} />
        </IconButton>
      </Tooltip>
      { currentRole === "client_admin" ?
        enabled ? (
          <Tooltip title='Suspend Key'>
            <IconButton
              size='small'
              onClick={disableKey(keyId, setReloadData, setLoading)}
              aria-label='Suspend Key'
            >
              <Delete style={{ color: '#E81202' }} />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title='Enable Key'>
            <IconButton
              size='small'
              onClick={enableKey(keyId, setReloadData, setLoading)}
              aria-label='Enable Key'
            >
              <AddCircle style={{ color: '#038231' }} />
            </IconButton>
          </Tooltip>
        ) : null }
    </>
  )
}

export default KeyActions
