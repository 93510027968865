import React, { useState } from 'react'
import { Avatar, Button, Typography, TextField, Link, Grid, Container } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'

import hofs from '../../utility/hofs'
import { login } from '../Auth/auth'
import fetchData from '../../utility/endpointFetch'
import LoginErrorMessageDisplay from './LoginErrorMessageDisplay'
import { getLoginStyles } from './LoginStyles'
import DisplayCaptcha from './DisplayCaptcha'
import DisplayOTPassword from './DisplayOTPassword'
import LoginTOTP from './LoginTOTP'

const Login = () => {
  const classes = getLoginStyles()
  const history = useHistory()

  const [loginError, setLoginError] = useState(false)
  const [loginErrorMessage, setLoginErrorMessage] = useState('')
  const [secondaryErrorMessage, setSecondaryErrorMessage] = useState('')
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [checkingLogin,setCheckingLogin] = useState(false)
  const [forceCaptcha, setForceCaptcha] = useState(0)
  const [captchaError, setCaptchaError] = useState(false)
  const [captchaValue, setCaptchaValue] = useState('')
  const [forceOTPUse, setForceOTPUse] = useState(false)
  const [OTPassword, setOTPassword] = useState('')
  const [OTPError, setOTPError] = useState(false)
  const [forceTOTPUse, setForceTOTPUse] = useState(false)
  const [TOTPassword, setTOTPassword] = useState('')
  const [TOTPError, setTOTPError] = useState(false)


  const handleEmailChange = e => {
    setLoginError(false)
    setSecondaryErrorMessage('')
    setEmailError(false)
    setEmail(e.target.value)
  }

  const handlePasswordChange = e => {
    setLoginError(false)
    setSecondaryErrorMessage('')
    setPasswordError(false)
    setPassword(e.target.value)
  }

  const handleCaptchaChange = e => {
    setCaptchaError(false)
    setCaptchaValue(e.target.value)
  }

  const handleOTPasswordChange = e => {
    setOTPError(false)
    setOTPassword(e.target.value)
  }

  const handleTOTPChange = e => {
    setTOTPError(false)
    setTOTPassword(e.target.value)
  }

  const refreshCaptcha = e => {
    setCheckingLogin(true)
    ;(async () => {
      const response = await fetchData('/login/captcha/refresh', "could not refresh captcha image").promise
      if (response.new_captcha_key) {
        // a new captcha image was created
        setForceCaptcha(response.new_captcha_key)
        setCaptchaValue('')
      } else {
        // hmmm....nothing...as no new captcha could be created...use the current one
      }
      setCheckingLogin(false)
    })()
  }

  const handleLoginSubmit = e => {

    e.preventDefault()

    // first is to check if email and password are filled in
    const isEmailPopulated = hofs.isPopulated(email)
    setEmailError(!isEmailPopulated)

    const isPasswordPopulated = hofs.isPopulated(password)
    setPasswordError(!isPasswordPopulated)

    var isCaptchaPopulated = true
    if (forceCaptcha != 0) {
      //captcha is required...collect and send
      //console.log("forceCaptcha in check is: " + forceCaptcha)
      isCaptchaPopulated = hofs.isPopulated(captchaValue)
      setCaptchaError(isCaptchaPopulated)
    } else {
      //captcha is not required...so leave is populated to true
    }

    var isOTPasswordPopulated = true
    if (forceOTPUse) {
      isOTPasswordPopulated = hofs.isPopulated(OTPassword)
      setOTPError(isCaptchaPopulated)
    } else {
      //ot pw is NOT in use...so let is pass into post
    }

    var isTOTPasswordPopulated = true
    if (forceTOTPUse) {
      isTOTPasswordPopulated = hofs.isPopulated(TOTPassword)
      setTOTPError(isCaptchaPopulated)
    } else {
      //totp pw is NOT in use...so let is pass into post
    }

    if ([isEmailPopulated, isPasswordPopulated, isCaptchaPopulated, isOTPasswordPopulated, isTOTPasswordPopulated].every(b => b)) {
      // there are no errors...send the data to backend to complete registration
      //log("about to send to /login endpoint")
      //console.log("TOTPassword is: " + TOTPassword)
      setCheckingLogin(true)
      const fetchParams = {
        method: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'email':email,
          'password': password,
          'captchaValue':captchaValue,
          'forceCaptcha': forceCaptcha,
          'otpPW': OTPassword,
          'totpPW': TOTPassword
        })
      }
      //console.log("body to send to /login/")
      //console.dir(fetchParams)
      const errorMessage = 'Error creating your account.  Please contact support to do so.'
      ;(async () => {
        const response = await fetchData('/login/', errorMessage, fetchParams).promise
        if (response.logged_in === false) {
          // not a valid email/password
          //console.log("response from server is unauthorized")
          //console.dir(response)
          setLoginError(true)
          setEmailError(true)
          setPasswordError(true)
          setForceCaptcha(response.captcha_key)
          setForceOTPUse(response.force_otp_use)
          setForceTOTPUse(response.force_totp_use)
          setCaptchaValue('')
          setOTPassword('')
          setTOTPassword('')
          if (response.force_totp_use)
            setLoginErrorMessage('There is an issue with your login credentials.  A time-based password is required.  Please insert the password from the authentication app you configured when setting up time-based passwords.')
          else if (response.captcha_key != 0)
            setLoginErrorMessage('There is an issue with your login credentials.  There is now a captcha image within the form.  Please insert the letters you see in in the captcha image to the captcha field as well as your login credentials to successfully login.')
          else if (response.force_otp_use == true)
            setLoginErrorMessage('There is an issue with your login credentials.  An email has been sent to your email address that includes the time-limited value you should insert into the One-time password field along with your login credentials to successfully login.')
          else
            setLoginErrorMessage('There is an issue with your login credentials.  Please check your login credentials and resubmit.')
        } else {
          if (response.logged_in) {
            // this user can be logged in...check if active
            if (response.active) {
              // successfully logged in
              setLoginError(false)
              // need to save the jwt that came back
              if (response.access_token) {
                login(response.access_token)
                history.push(hofs.getRole() === 'admin' ? '/admin/' : '/')
              } else {
                setLoginError(true)
                setLoginErrorMessage('We could not establish a session on your browser.  Please try logging in again.')
              }
            } else {
              // user has account but not active
              setLoginError(true)
              setCaptchaValue('')
              setOTPassword('')
              setTOTPassword('')
              setLoginErrorMessage('We could not log you in.')
              setSecondaryErrorMessage('Is this your first time to login?  If so, have you verified your account after registration?  After completing registration you were sent an email to verify the email address of your account.  Make sure you followed the steps within that email to verify your account and then log into your account.')
            }
          } else {
            setLoginError(true)
            setForceCaptcha(response.force_captcha)
            setForceOTPUse(response.force_otp_use)
            setForceTOTPUse(response.force_totp_use)
            setCaptchaValue('')
            setOTPassword('')
            setTOTPassword('')
            setLoginErrorMessage('We could not log you in.  Please check your email/password and try again.')
            if (response.force_totp_use)
              setLoginErrorMessage('There is an issue with your login credentials.  A time-based password is required.  Please insert the password from the authentication app you configured when setting up time-based passwords.')
            else if (response.captcha_key != 0)
              setLoginErrorMessage('There is an issue with your login credentials.  There is now a captcha image within the form.  Please insert the letters you see in in the captcha image to the captcha field as well as your login credentials to successfully login.')
            else if (response.force_otp_use == true)
              setLoginErrorMessage('There is an issue with your login credentials.  An email has been sent to your email address that includes the time-limited value you should insert into the One-time password field along with your login credentials to successfully login.')
            else
              setLoginErrorMessage('There is an issue with your login credentials.  Please check your login credentials and resubmit.')
          }
        }
        setCheckingLogin(false)
      })()
    } else {
      //console.log('there were errors in the login submission')
    }
  }

  if (hofs.checkLogStatus()) return <Redirect to={hofs.getRole() === 'admin' ? '/admin/' : '/'} />
  return (
    <Container component='main' maxWidth='xs'>
      <div className={classes.paper}>
        <Avatar className={classes.avatar} src='/def-logix-d-larger.png' />
        <Typography component='h1' variant='h5'>
          Sign in
        </Typography>
        {loginError &&
          <LoginErrorMessageDisplay errorMessage={loginErrorMessage} secondaryErrorMessage={secondaryErrorMessage} />
        }
        <LoadingOverlay active={checkingLogin} spinner text='Checking Login Details...' fadeSpeed={200}>
        <form className={classes.form} noValidate onSubmit={handleLoginSubmit}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Address'
            name='email'
            autoComplete='email'
            autoFocus
            error={emailError}
            onChange={handleEmailChange}
            inputProps={{ maxLength: 255 }}
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
            error={passwordError}
            onChange={handlePasswordChange}
            inputProps={{ maxLength: 100 }}
          />
          {/* figure out other things before implementing this feature
          <FormControlLabel
            control={<Checkbox value='remember' color='primary' />}
            label='Remember me'
          />
          */}
          <DisplayCaptcha
            captcha_key={forceCaptcha}
            handleCaptchaChange={handleCaptchaChange}
            captchaError={captchaError}
            captchaValue={captchaValue}
            refreshCaptcha={refreshCaptcha} />
          <DisplayOTPassword
            handleOTPasswordChange={handleOTPasswordChange}
            OTPassword={OTPassword}
            OTPError={OTPError}
            forceOTPUse={forceOTPUse}
          />
          <LoginTOTP
            handleTOTPChange={handleTOTPChange}
            TOTPError={TOTPError}
            forceTOTPUse={forceTOTPUse}
            TOTPassword={TOTPassword}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href='/login/reset' variant='body2'>
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href='/register' variant='body2'>
                Don't have an account? Sign Up
              </Link>
            </Grid>
          </Grid>
        </form>
        </LoadingOverlay>
      </div>
    </Container>
  )
}

export default Login
