import React, { useEffect, useState } from 'react'
import { TableContainer, Table, CircularProgress, Typography } from '@material-ui/core'

import fetchData from '../../utility/endpointFetch'
import AccountLastHistoryRow from './AccountLastHistoryRow'

const displayResults = (accountHistory, historyType) => {
  if (accountHistory.length > 0)
    return (
      <Table size='small'>
        <tbody>
          {accountHistory.map((row, i) => <AccountLastHistoryRow key={JSON.stringify(row) + i} currentRow={row} />)}
        </tbody>
      </Table>
    )
  else {
    return (
      <Table size='small'>
        <Typography>There are no {historyType} actions in history</Typography>
      </Table>
    )
  }
}

const AccountLastHistory = ({ historyType }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [accountHistory, setAccountHistory] = useState([])

  useEffect(() => {
    const fetchURL = `/account/history/${historyType}/5`
    const { promise, cancel } = fetchData(fetchURL, 'Error retrieving account history attached to account')
    ;(async () => {
      const response = await promise
      setAccountHistory(response.account_history)
      setIsLoading(false)
    })()
    return cancel
  }, [historyType])

  return (
    <TableContainer>
      {isLoading ? <CircularProgress /> : displayResults(accountHistory, historyType)}
    </TableContainer>
  )
}

export default AccountLastHistory
