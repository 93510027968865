import { makeStyles, unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles'

export const commonStyles = theme => {
  const darkMode = theme.palette.type === 'dark'
  return {
    actionBox: {
      textAlign: 'center',
    },
    barChartHeight: {
      height: '160px'
    },
    booleanFormControl: {
      display: 'flex',
      alignItems: 'center'
    },
    booleanFormControl__background: {
      border: '1px solid #CCCCCC',
      width: '50px',
      height: '26px',
      borderRadius: '13px',
      cursor: 'pointer',
      display: 'inline-block',
      verticalAlign: 'sub',
    },
    booleanFormControl__switch: {
      border: '1px solid #999999',
      width: '25px',
      height: '24px',
      borderRadius: '12px',
      background: 'white',
      position: 'relative',
      transition: theme.transitions.create('left', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
      }),
      boxShadow: theme.palette.type === 'dark' ? 'none' : '1px 1px 1px #CCCCCC'
    },
    cardErrorMessages: {
      fontWeight: '600',
      color: 'red'
    },
    chartContainer: {
      backgroundColor: 'white',
      borderRadius: '8px',
      padding: theme.spacing(1),
      color: theme.palette.primary.main,
      height: '100%'
    },
    chipLeft: {
      textAlign: 'left'
    },
    container: {
      padding: theme.spacing(2),
      paddingBottom: 0,
      flex: 1,
      minHeight: 0,
      display: 'flex',
      flexDirection: 'column'
    },
    containerLabel: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(0.5),
      fontWeight: 800,
      fontSize: '1.255em',
      textAlign: 'left',
      color: theme.palette.primary.main,
      alignItems: 'center',
      display: 'flex'
    },
    contentTitle: {
      fontWeight: '700',
      position: 'sticky'
    },
    dashboardSection: {
      backgroundColor: 'white',
      borderRadius: '8px',
      padding: '5px',
      color: theme.palette.primary.main,
      height: '100%'
    },
    datatableCellAlignRight: {
      textAlign: 'right'
    },
    defLogixLogo: {
      border: '.5px solid black',
      marginRight: '15px'
    },
    doughnutContainer: {
      width: '100%',
      maxHeight: '150px',
      height: '100%'
    },
    drawerPaper: {
      height: '100vh',
      position: 'relative',
      whiteSpace: 'nowrap',
      width: 240,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9)
      }
    },
    expandableRiskDial: {
      marginTop: '1.5rem',
      width: '100%'
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      padding: theme.spacing(2),
      borderRadius: '4px'
    },
    grid: {
      marginTop: theme.spacing(2),
      overflow: 'auto',
      minWidth: '0'
    },
    historyLink: {
      fontSize: '1.25em'
    },
    iconAndSpacer: {
      fontSize: '1.2em',
      marginRight: '10px',
      marginBottom: '2px'
    },
    indentList: {
      marginLeft: '20px'
    },
    leftColumn: {
      width: '50%'
    },
    machineExpandableSection: {
      backgroundColor: 'white',
      borderRadius: '8px',
      padding: '5px',
      color: '#495C7B',
      height: '150px'
    },
    userExpandableSection: {
      backgroundColor: 'white',
      borderRadius: '8px',
      padding: '5px',
      color: '#495C7B',
      height: '250px'
    },
    materialIconAlign: {
      display: 'inline-flex',
      verticalAlign: 'top'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    modalPaper: {
      position: 'absolute',
      width: '75%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid black',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      alignItems: 'center',
      top: '15%',
      left: '12.5vw',
      minHeight: "200px"
    },
    smallModalPaper: {
      position: 'absolute',
      width: '40%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid black',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: '15%',
      left: '20vw',
      minHeight: "75px"
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: darkMode ? theme.palette.primary.light : theme.palette.primary.lighter,
      color: theme.palette.getContrastText(darkMode ? theme.palette.primary.light : theme.palette.primary.lighter),
      minHeight: 0,
      overflow: 'auto'
    },
    paperBoxes: {
      padding: theme.spacing(2),
      backgroundColor: darkMode ? theme.palette.primary.light : 'white',
    },
    passwordStrengthBar: {
      color: theme.palette.getContrastText(
        darkMode ? theme.palette.primary.light : theme.palette.primary.lighter
      ) + ' !important'
    },
    paymentform: {
      fontSize: '1.5rem',
      marginTop: '20px',
      marginBottom: '20px'
    },
    reportExpandedContainer: {
      maxWidth: '100%',
      backgroundColor: 'white',
      borderRadius: '8px'
    },
    riskDialContainer: {
      width: '70%',
      float: 'right'
    },
    riskDialGauge: {
      minHeight: '100px'
    },
    statsContainer: {
      minHeight: '150px'
    },
    userEditContainer: {
      minHeight: '250px',
      backgroundColor: '#efefef'
    },
    submit: {
      margin: theme.spacing(3, 0, 2)
    },
    tableBackground: {
      backgroundColor: '#F5F8FF'
    },
    tableContainer: {
      marginTop: theme.spacing(6)
    },
    tableRoot: {
      borderBottom: 'none'
    },
    title: {
      flexGrow: 1,
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...theme.mixins.toolbar
    },
    toolbarIconOpen: {
      justifyContent: 'flex-end',
      marginRight: theme.spacing(2)
    },
    reportSuccessIcon: {
      fontSize: '3rem',
      color: '#069926',
    },
    reportRequestError: {
      fontSize: '3rem',
      color: '#b5090f',
    }
  }
}

export const getAppStyles = makeStyles(commonStyles)

const commonTheme = darkMode => console.log(`Dark Mode: ${darkMode}`) || ({
  palette: {
    type: darkMode ? 'dark' : 'light',
    primary: {
      main: '#495C7B',
      light: '#6D7C95',
      lighter: '#DAE2F5'
    },
    background: {
      paper: darkMode ? '#6D7C95' : '#DAE2F5'
    }
  },
  overrides: {
    // Material-UI component overrides
    MuiAppBar: {
      colorPrimary: {
        color: 'white'
      }
    },
    MuiDrawer: {
      paper: {
        background: darkMode ? '#424242' : 'white'
      }
    },
    MuiTableCell: {
      head: {
        padding: '2px',
        paddingLeft: '10px'
      },
      body: {
        color: darkMode ? 'black' : '#495C7B'
      }
    },
    MuiTableSortLabel: {
      icon: {
        color: 'white !important'
      }
    },
    MuiChip: {
      deleteIcon: {
        color: '#EEEEEE'
      }
    },
    MuiListItem: {
      root: {
        paddingLeft: '24px !important'
      }
    },
    MuiTablePagination: {
      selectIcon: {
        color: 'black !important'
      },
      toolbar: {
        color: 'black'
      },
      actions: {
        color: 'black'
      }
    },
    // DataTable overrides
    MUIDataTable: {
      paper: {
        boxShadow: 'none',
        color: darkMode ? 'black' : '#495C7B',
        backgroundColor: darkMode ? '#DAE2F5' : 'white'
      }
    },
    MUIDataTableSelectCell: {
      headerCell: {
        backgroundColor: 'black !important',
        color: 'white'
      }
    },
    MUIDataTableBodyCell: {
      root: {
        lineHeight: '1',
        paddingTop: '2px',
        paddingBottom: '2px',
        paddingLeft: '5px',
        whiteSpace: 'nowrap',
        color: 'black'
      }
    },
    MUIDataTableHeadCell: {
      data: {
        fontWeight: '800',
        color: 'white !important'
      },
      root: {
        fontWeight: '800',
        color: 'white !important'
      },
      fixedHeader: {
        backgroundColor: 'black'
      }
    },
    MUIDataTableFilterList: {
      chip: {
        backgroundColor: '#5C70A1',
        color: '#EEEEEE',
        marginBottom: '10px'
      }
    },
    MUIDataTableToolbar: {
      root: {
        minHeight: '50px'
      },
      titleText: {
        textAlign: 'left',
        fontSize: '1.6rem',
        fontWeight: '600'
      },
      icon: {
        color: darkMode ? '#5F8DD7' : '#2E5CA6'
      }
    },
    // There seems to be a bug with the selectToolbarPlacement: 'none' option
    // that causes it to mess up the select all checkbox.
    // Workaround is to just use selectToolbarPlacement: 'above' and hide it.
    MUIDataTableToolbarSelect: {
      root: {
        display: 'none'
      }
    }
  }
})

export const getCommonTheme = darkMode => createMuiTheme(commonTheme(darkMode))
