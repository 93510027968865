import React, { useState } from 'react'
import { Button, Typography, Grid, Container, TextField } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import fetchData from '../../utility/endpointFetch'
import hofs from '../../utility/hofs'
import { getAppStyles } from '../App/AppStyles'

const firstNameErrorMessage = 'First name is required'
const lastNameErrorMessage = 'Last name is required'
const emailErrorMessage = 'Email address is required'
const passwordErrorMessage = 'Account password is required'

const KeysDisabled = () => {
  // TODO: Fix this hardcoded account ID
  const accountID = 1

  const classes = getAppStyles()
  const history = useHistory()

  const [firstName, setFirstName] = useState()
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastName, setLastName] = useState()
  const [lastNameError, setLastNameError] = useState(false)
  const [email, setEmail] = useState()
  const [emailError, setEmailError] = useState(false)
  const [password, setPassword] = useState()
  const [passwordError, setPasswordError] = useState(false)

  const handleNewUserSubmit = e => {
    e.preventDefault()

    const firstNamePopulated = hofs.isPopulated(firstName)
    setFirstNameError(!firstNamePopulated)

    const lastNamePopulated = hofs.isPopulated(lastName)
    setLastNameError(!lastNamePopulated)

    const emailPopulated = hofs.isPopulated(email)
    setEmailError(!emailPopulated)

    const passwordPopulated = hofs.isPopulated(password)
    setPasswordError(!passwordPopulated)

    if ([firstNamePopulated, lastNamePopulated, emailPopulated, passwordPopulated].every(b => b)) {
      // there are no errors...send the data to backend to complete registration
      const fetchParams = {
        method: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          email,
          password
        })
      }

      ;(async () => {
        const errorMessage = 'Error creating your user.  Please contact support to do so.'
        await fetchData(`/licenses/${accountID}`, errorMessage, fetchParams).promise
      })()
      history.push('/users')
    }
  }

  return (
    <Container component='main' maxWidth='sm'>
      <div className={classes.paper}>
        <Typography component='h1' variant='h5'>
          Add New User To Your Account
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleNewUserSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name='first_name'
                variant='outlined'
                required
                fullWidth={true}
                value={firstName}
                id='firstName'
                label='First Name'
                onChange={e => setFirstName(e.target.value)}
                helperText={firstNameError ? firstNameErrorMessage : null}
                error={firstNameError}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant='outlined'
                required
                fullWidth
                id='lastName'
                label='Last Name'
                name='last_name'
                autoComplete='lname'
                onChange={e => setLastName(e.target.value)}
                helperText={lastNameError ? lastNameErrorMessage : null}
                error={lastNameError}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                onChange={e => setEmail(e.target.value)}
                helperText={emailError ? emailErrorMessage : null}
                error={emailError}
                inputProps={{ maxLength: 255 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                type='password'
                required
                fullWidth
                id='password'
                label='Password'
                name='password'
                autoComplete='password'
                onChange={e => setPassword(e.target.value)}
                helperText={passwordError ? passwordErrorMessage : null}
                error={passwordError}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            className={classes.submit}
          >
            Add User
          </Button>
        </form>
      </div>
    </Container>
  )
}

export default KeysDisabled
