import React, { useEffect, useState } from 'react'
import { CircularProgress, Typography } from '@material-ui/core'
import { Bar } from 'react-chartjs-2'

import fetchData from '../../utility/endpointFetch'

const classificationData = (labels, data) => ({
  labels,
  datasets: [{
    label: 'Machines using this License',
    data,
    backgroundColor: ['rgba(255, 99, 132, 0.2)'],
    borderColor: ['rgba(255, 99, 132, 1)'],
    borderWidth: 1
  }]
})

const barOptions = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: 10
  },
  plugins: {
    legend: {
      display: false
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      callBack: value => value % 0 ? value : null
    }
  }
}

const AccountMachinePerLicenseBar = () => {
  const [labelsData, setLabelsData] = useState([])
  const [barData, setBarData] = useState([])
  const [totalMachineCount, setTotalMachineCount] = useState(0)
  const [isLoading, setIsLoading] = useState([])

  useEffect(() => {
    const { promise, cancel } = fetchData('/machines/perlicense', 'Error retrieving machines per license data')
    ;(async () => {
      const response = await promise
      const licenseLabelData = response.map(license => license.key_description)
      setLabelsData(licenseLabelData)
      const machinePerLicenseData = response.map(license => license.machines_using_this_key.length)
      setBarData(machinePerLicenseData)
      setTotalMachineCount(machinePerLicenseData.reduce((a, b) => a + b, 0))
      setIsLoading(false)
    })()
    return cancel
  }, [])

  if (isLoading) return <CircularProgress />
  if (totalMachineCount > 0) return (
    <div>
      <Bar data={classificationData(labelsData, barData)} options={barOptions} />
    </div>
  )
  return <Typography>There are no machines on this account</Typography>
}

export default AccountMachinePerLicenseBar
