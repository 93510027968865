import React from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import CancelIcon from '@material-ui/icons/Cancel'

import fetchData from '../../../utility/endpointFetch'

const handleMachineDisable = (setLoading, setReloadData, machineId) => () => {
  setLoading(true)
  ;(async () => {
    const url = `/machines/machine/disable/${machineId}`
    const response = await fetchData(url, 'Error disabling machine by admin').promise
    if (response.machine_disabled) {
      // successfully disabled this machine
      setReloadData({})
    }
  })()
}

const handleMachineEnable = (setLoading, setReloadData, machineId) => () => {
  setLoading(true)
  ;(async () => {
    const url = `/machines/machine/enable/${machineId}`
    const response = await fetchData(url, 'Error enabling machine by admin').promise
    if (response.machine_enabled) {
      // successfully enabled this machine
      setReloadData({})
    }
  })()
}

const MachineState = ({ enabled, machineId, setLoading, setReloadData }) => enabled ? (
  // this is a live key
  <Tooltip title='Machine Active - Click to disable' style={{ padding: '0' }}>
    <IconButton onClick={handleMachineDisable(setLoading, setReloadData, machineId)}>
      <CheckCircle style={{ color: '#156E01' }} />
    </IconButton>
  </Tooltip>
) : (
  <Tooltip title='Machine NOT Active - Click to enable' style={{ padding: '0' }}>
    <IconButton onClick={handleMachineEnable(setLoading, setReloadData, machineId)}>
      <CancelIcon style={{ color: '#820000' }} />
    </IconButton>
  </Tooltip>
)

export default MachineState
