import React, { useState, useEffect } from 'react'
import { ListAlt, Computer, History, Warning, Equalizer } from '@material-ui/icons'
import { Container, Grid, Paper, Typography } from '@material-ui/core'
import clsx from 'clsx'
import fetchData from '../../utility/endpointFetch'

import AccountStats from './AccountStats'
import AccountLastHistory from './AccountLastHistory'
import AccountMachinePerLicenseBar from './AccountMachinePerLicenseBar'
import MachineRiskDial from '../Machines/MachineRiskDial'
import AccountRiskBarStats from '../Machines/AccountRiskBarStats'

import Footer from '../Footer'
import { dashboardStyles } from './DashboardStyles'

const DashboardPaid = ({ pageTitle }) => {
  const classes = dashboardStyles()

  const [averageSecurityScore, setAverageSecurityScore] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const { promise, cancel } = fetchData('/machines/securityscore', 'Error retrieving machines security score data')
    ;(async () => {
      const response = await promise
      if (response.length > 0) {
        setAverageSecurityScore(parseInt(response.reduce((a, b) => a + b, 0) / response.length).toFixed(2))
      } else {
        setAverageSecurityScore(0)
      }
      setIsLoading(false)
    })()
    return cancel
  }, [])

  return (
    <>
      <Container maxWidth='xl' className={classes.container}>
        <Paper className={classes.paper}>
          <Typography align='left' variant='h5' className={classes.contentTitle}>
            {pageTitle}
          </Typography>
          <Grid container spacing={2} className={classes.grid}>
            <Grid item xs={12} md={4}>
              <Paper className={classes.dashboardSection}>
                <div className={classes.containerLabel}>
                  <Warning className={classes.iconAndSpacer} />
                  Account Wide Security Score
                </div>
                <MachineRiskDial isLoading={isLoading} securityRiskScore={averageSecurityScore / 100} />
                <div className={classes.riskDialText}>% of Insecurities per Def-Logix Analysis of Best-Practices</div>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper className={classes.dashboardSection}>
                <div className={classes.containerLabel}>
                  <Equalizer className={classes.iconAndSpacer} />
                  Account Wide Machines By Security Score
                </div>
                <AccountRiskBarStats />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper className={classes.dashboardSection}>
                <div className={classes.containerLabel}>
                  <ListAlt className={classes.iconAndSpacer} />
                  Account Stats
                </div>
                <AccountStats />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.dashboardSection}>
                <div className={classes.containerLabel}>
                  <Computer className={classes.iconAndSpacer} />
                  Machines Per License Key
                </div>
                <AccountMachinePerLicenseBar />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={clsx(classes.historyContainer, classes.dashboardSection)}>
                <div className={classes.containerLabel}>
                  <History className={classes.iconAndSpacer} />
                  Last User Actions...
                </div>
                <AccountLastHistory historyType='user' />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={clsx(classes.historyContainer, classes.dashboardSection)}>
                <div className={classes.containerLabel}>
                  <History className={classes.iconAndSpacer} />
                  Last Machine Actions...
                </div>
                <AccountLastHistory historyType='machine' />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={clsx(classes.historyContainer, classes.dashboardSection)}>
                <div className={classes.containerLabel}>
                  <History className={classes.iconAndSpacer} />
                  Last License Actions...
                </div>
                <AccountLastHistory historyType='license' />
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Footer />
    </>
  )
}

export default DashboardPaid
