import React from 'react'
import { Tooltip, IconButton } from '@material-ui/core'
import { History, AddCircle, Delete } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import hofs from '../../utility/hofs'

import fetchData from '../../utility/endpointFetch'

const handleUserDisable = (setLoading, setReloadData, userId) => () => {
  setLoading(true)
  ;(async () => {
    const url = `/users/user/disable/${userId}`
    const response = await fetchData(url, 'Error disabling user attached to account').promise
    if (response.user_disabled) {
      // successfully disabled this user
      setReloadData({})
    }
  })()
}

const handleUserEnable = (setLoading, setReloadData, userId) => () => {
  setLoading(true)
  ;(async () => {
    const url = `/users/user/enable/${userId}`
    const response = await fetchData(url, 'Error disabling user attached to account').promise
    if (response.user_enabled) {
      // successfully disabled this user
      setReloadData({})
    }
  })()
}

const UserActions = ({ enabled, userId, setLoading, setReloadData }) => {
  const currentRole = hofs.getRole()
  return (
    <>
      <Tooltip title='User History'>
        <IconButton size='small' aria-label='User History' component={Link} to={`/users/history/${userId}`}>
          <History style={{ color: '#0275E8' }} />
        </IconButton>
      </Tooltip>
      { currentRole === 'client_admin' ?
        enabled ? (
          <Tooltip title='Suspend User'>
            <IconButton
              onClick={handleUserDisable(setLoading, setReloadData, userId)}
              size='small'
              aria-label='Suspend User'
            >
              <Delete style={{ color: '#E81202' }} />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title='Enable User'>
            <IconButton
              onClick={handleUserEnable(setLoading, setReloadData, userId)}
              size='small'
              aria-label='Enable User'
            >
              <AddCircle style={{ color: '#038231' }} />
            </IconButton>
          </Tooltip>
        ) : null }
    </>
  )
}

export default UserActions
