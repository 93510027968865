import { complement, isEmpty } from 'ramda'
import jwt_decode from 'jwt-decode'

import { logout } from '../Components/Auth/auth'

const isNotEmpty = complement(isEmpty)
const isPopulated = function (val) {
  // use default javascript check first.
  // this handles null, undefined, 0, "", NaN
  if (typeof val === 'undefined' || !val) return false
  // [], {} are considered empty, return that as falsey
  // a string with only whitespace is also falsey
  if (isNotEmpty(val)) {
    if (val.trim) return val.trim().length > 0
    // not a string and isPopulated checks that, so all good
    return true
  }
  return false
}

const validateEmail = input => {
  // eslint-disable-next-line no-useless-escape
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return input.match(emailRegex) ? true : false
}

const getToken = () => localStorage.getItem('REACT_TOKEN_AUTH_KEY')
  && localStorage.getItem('REACT_TOKEN_AUTH_KEY').replace(/['"]+/g, '')

const checkLogStatus = () => {
  // check if token has expired...just because there is a token saved doesn't mean it hasn't expired
  const token = getToken()
  if (!token) return false
  const decodedToken = jwt_decode(token)
  if (Date.now() >= decodedToken.exp * 1000) {
    // this token is expired...lets remove token to save confusion
    logout()
    return false
  } else {
    return true
  }
}

const getUserIdFromToken = () => {
  const token = getToken()
  return token && jwt_decode(token).id
}

const getRole = () => {
  const token = getToken()
  return token && jwt_decode(token).rls
}

const exportedObjects = {
  isPopulated,
  validateEmail,
  getToken,
  checkLogStatus,
  getRole,
  getUserIdFromToken
}

export default exportedObjects
