import React from 'react'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import {
  Dashboard as DashboardIcon,
  People as PeopleIcon,
  VpnKey as VpnKeyIcon,
  AttachMoney as AttachMoneyIcon,
  Computer as ComputerIcon,
  ExitToApp as ExitToAppIcon,
  AccountBalance as AccountBalanceIcon,
  GetApp as GetAppIcon,
  OpenInNew as OpenInNewIcon
} from '@material-ui/icons'

import { logout } from '../Auth/auth'
import hofs from '../../utility/hofs'

const ListItems = () => {
  const currentRole = hofs.getRole()
  if (currentRole === 'client_user' || currentRole === 'client_admin' || currentRole === 'unpaid_user') {
    return (
      <div>
        <ListItem
          button
          component={NavLink}
          href='/'
          to='/'
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary='Dashboard' />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          href='/account'
          to='/account'
        >
          <ListItemIcon>
            <AccountBalanceIcon />
          </ListItemIcon>
          <ListItemText primary='Account' />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          href='/users'
          to='/users'
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary='Users' />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          href='/licenses'
          to='/licenses'
        >
          <ListItemIcon>
            <VpnKeyIcon />
          </ListItemIcon>
          <ListItemText primary='License Keys' />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          href='/machines/nofilter'
          to='/machines/nofilter'
        >
          <ListItemIcon>
            <ComputerIcon />
          </ListItemIcon>
          <ListItemText primary='Machines' />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          href='/payments'
          to='/payments'
        >
          <ListItemIcon>
            <AttachMoneyIcon />
          </ListItemIcon>
          <ListItemText primary='Subscription' />
        </ListItem>
        <ListItem
          button
          component='a'
          target="_blank"
          href='https://forms.office.com/Pages/ResponsePage.aspx?id=Rrf__LpkdUCAINDGb9p52vRU9cwCx8NHsLQ7sfYArklUMzlVWVJPM1pPUzkzUExIRUJDTlFERklFVi4u'
          to='https://forms.office.com/Pages/ResponsePage.aspx?id=Rrf__LpkdUCAINDGb9p52vRU9cwCx8NHsLQ7sfYArklUMzlVWVJPM1pPUzkzUExIRUJDTlFERklFVi4u'
        >
          <ListItemIcon>
            <OpenInNewIcon />
          </ListItemIcon>
          <ListItemText primary='Leave Feedback' />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          href='/download'
          to='/download'
        >
          <ListItemIcon>
            <GetAppIcon />
          </ListItemIcon>
          <ListItemText primary='Download Tool' />
        </ListItem>
        <ListItem
          button
          onClick={() => logout()}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary='Logout' />
        </ListItem>
      </div>
    )
  }
  return (
    // this is the admin role
    <div>
      <ListItem
        button
        component={NavLink}
        href='/admin/accounts'
        to='/admin/accounts'
      >
        <ListItemIcon>
          <AccountBalanceIcon />
        </ListItemIcon>
        <ListItemText primary='Accounts' />
      </ListItem>
      <ListItem
        button
        component={NavLink}
        href='/admin/users'
        to='/admin/users'
      >
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary='Users' />
      </ListItem>
      <ListItem
        button
        component={NavLink}
        href='/admin/licenses'
        to='/admin/licenses'
      >
        <ListItemIcon>
          <VpnKeyIcon />
        </ListItemIcon>
        <ListItemText primary='License Keys' />
      </ListItem>
      <ListItem
        button
        component={NavLink}
        href='/admin/machines'
        to='/admin/machines'
      >
        <ListItemIcon>
          <ComputerIcon />
        </ListItemIcon>
        <ListItemText primary='Machines' />
      </ListItem>
      <ListItem
        button
        component={NavLink}
        href='/admin/invitationcodes'
        to='/admin/invitationcodes'
      >
        <ListItemIcon>
          <ComputerIcon />
        </ListItemIcon>
        <ListItemText primary='Invitation Codes' />
      </ListItem>
      <ListItem
        button
        component={NavLink}
        href='/admin/configurations'
        to='/admin/configurations'
      >
        <ListItemIcon>
          <ComputerIcon />
        </ListItemIcon>
        <ListItemText primary='Configuration' />
      </ListItem>
      <ListItem
        button
        onClick={() => logout()}
      >
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary='Logout' />
      </ListItem>
    </div>
  )
}

export default ListItems
