import { TableCell, TableRow } from '@material-ui/core'

import { dashboardStyles } from './DashboardStyles'

const AccountLastHistoryRow = ({ currentRow }) => {
  const classes = dashboardStyles()
  return (
    <TableRow>
      <TableCell className={classes.dateCell}>{currentRow.action_taken_at}</TableCell>
      <TableCell>{currentRow.action}</TableCell>
      <TableCell>{currentRow.action_details}</TableCell>
    </TableRow>
  )
}

export default AccountLastHistoryRow
