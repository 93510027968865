import { useEffect, useState } from 'react'
import { Container, Typography, Avatar, CircularProgress } from '@material-ui/core'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'
import { Redirect } from 'react-router-dom'

import fetchData from '../../utility/endpointFetch'
import { getRegisterStyles } from './RegisterStyles'
import LoginCollectCCInfo from '../Login/LoginCollectCCInfo'

import { login } from '../Auth/auth'

const RegistrationStepComplete = () => {
  const classes = getRegisterStyles()

  const [isLoading, setIsLoading] = useState(true)
  const [firstName, setFirstName] = useState('Test')
  const [alreadyRegistered, setAlreadyRegistered] = useState(false)
  const [registerVerificationError, setRegisterVerificationError] = useState(false)
  //only one error message atm
  const [registerVerificationErrorMessage, setRegisterVerificationMessage] = useState('We were not able to verify your email.  Please retry your email verification link.  If the issue persists please contact support.')

  const search = window.location.search
  const params = new URLSearchParams(search)
  const parameterToken = params.get('token')

  useEffect(() => {
    const fetchURL = `/registration/${parameterToken}`
    const { promise, cancel } = fetchData(fetchURL, 'Error retrieving active machines attached to account')
    ;(async () => {
      const response = await promise
      console.log("response is: ")
      console.dir(response)
      if (response.access_token) {
        login(response.access_token)
      } else {
        setRegisterVerificationError(true)
        //setRegisterVerificationErrorMessage('Somethine')
      }
      setIsLoading(false)
    })()
    return cancel
  }, [])

  // make sure alreadyRegistered is changed before
  if (isLoading) {
    return (
      <Container component='main' maxWidth='xs'>
        <div className={classes.paper}>
          <Avatar className={classes.avatar} src='/def-logix-d-larger.png' />
          <Typography component='h1' variant='h5'>
            Registration Verification
          </Typography>
          <CircularProgress />
          <Typography>
            We're checking your registration verification details
          </Typography>
        </div>
      </Container>
    )
  } else if (registerVerificationError) {
    console.log("registerVerificationError is true")
    return (
      <Container component='main' maxWidth='xs'>
      <div className={classes.paper}>
        <Avatar className={classes.avatar} src='/def-logix-d-larger.png' />
        <Typography component='h1' variant='h5'>
          Registration Email Verification (Failed!)
        </Typography>
        <Typography>
          {registerVerificationErrorMessage}
        </Typography>
      </div>
    </Container>
    )
  } else {
    console.log("registerVerificationError is " + registerVerificationError)
    return (
       <Redirect to='/' />
    )
  }
}

export default RegistrationStepComplete
