import React from 'react'
import GaugeChart from 'react-gauge-chart'
import { CircularProgress } from '@material-ui/core'

import { getAppStyles } from '../App/AppStyles'

const MachineRiskDial = ({ isLoading, securityRiskScore=0, last_report_date }) => {
  const classes = getAppStyles()

  if (isLoading) return <CircularProgress />

  return (
    <div className={classes.riskDialContainer}>
      {last_report_date !== null && securityRiskScore !== undefined ? (
        <GaugeChart
          id='gauge-chart4'
          className={classes.riskDialGauge}
          nrOfLevels={10}
          arcPadding={0.1}
          cornerRadius={3}
          percent={securityRiskScore}
          textColor='black'
        />
      ) : (
        <div style={{ marginTop: '1rem' }}>Run an analysis on your machine to get a report!</div>
      )}
    </div>
  )
}

export default MachineRiskDial
