import React, { useState, useEffect } from 'react'
import { Grid, TextField, CircularProgress, IconButton } from '@material-ui/core'
import fetchData from '../../utility/endpointFetch'
import { Cached } from '@material-ui/icons'
import { getLoginStyles } from './LoginStyles'
import { PureComponent } from 'react'

const DisplayCaptcha = ({ captcha_key, handleCaptchaChange, captchaError, captchaValue, refreshCaptcha }) => {
  //console.log("top of DisplayCaptcha and captcha_key is: " + captcha_key)
  const classes = getLoginStyles()

  const [ loading, setLoading ] = useState(true)
  const [ captchaImage, setCaptchaImage] = useState()

  useEffect(() => {
    setLoading(true)
    /* let defaultOptions = {
      method: 'GET',
      headers: {
        Accept: 'image/png',
      }
    }*/
    if (captcha_key != 0) {
      const defaultOptions = {
        method: 'GET',
        headers: {
          Accept: 'image/png',
        }
      }
      async function fetchCaptchaImage (captcha_key, defaultOptions) {
        let response
        try {
         response = await fetch(`/api/login/captcha/${captcha_key}`, defaultOptions)
            .then(response => response.blob())
            .then( blob => {
              setCaptchaImage( URL.createObjectURL(blob) )
              return blob
            })
            .catch( err => console.error(err))
          //let image = await response.body
          //console.log("response from /login/captcha is: ")
          //console.dir(response)
          //await new Promise(r => setTimeout(r, 2000));
          return
          //console.dir(image)
        } catch {
          //console.log('Unhandled Exception')
          return Promise.reject('Something bad happened on the server.  You didn\'t get an HTTP response.')
        }
      }
      fetchCaptchaImage(captcha_key, defaultOptions)
      setLoading(false)

    } else {
      //do nothing as this will not display
    }
  }, [captcha_key])

  if (captcha_key == 0) {
    return null
  } else {
    if (loading) {
      return <CircularProgress />
    } else {
      return (
        <Grid container >
          <Grid item xs xs='8' className={classes.captchaimage}>
            <img src={captchaImage} />
            <IconButton className={classes.captchaRefresh} size="medium" aria-label="refresh captcha" color="primary" onClick={refreshCaptcha}>
              <Cached fontSize="large"/>
            </IconButton>
          </Grid>
          <Grid item xs='4'>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='captcha_value'
              label='Captcha Value'
              type='text'
              id='captcha_value'
              value={captchaValue}
              autoComplete='insert captcha value'
              error={captchaError}
              onChange={handleCaptchaChange}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
        </Grid>
      )
    }
  }
}

export default DisplayCaptcha
