import { CheckCircle, Cancel, Delete } from '@material-ui/icons'
import { Tooltip, Button, IconButton } from '@material-ui/core'
import fetchData from '../../../utility/endpointFetch'

const sendInvitationEmail = (invitation_code_id, setModalOpen, setModalMessage) => {
  const errorMessage = 'Error sending your invitation email.'
  const fetchParams = {
    method: 'POST',
    dataType: 'json',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ invitation_code_id })
  }
  ;(async () => {
    const response = await fetchData('/registration/admin/sendinvitationcode', errorMessage, fetchParams).promise
    if (response.invitation_sent) {
      // no errors....invitation send successfully
      setModalMessage('Invitation email successfully sent')
      setModalOpen(true)

    } else {
      // insertion of new invitation code successful
      setModalMessage('Invitation email could not be sent')
      setModalOpen(true)
    }
  })()
}

const handleInvitationCodeDelete = (invitation_code_id, setModalOpen, setReloadData, setModalMessage) => {
  const errorMessage = 'Error deleting invitation code.'
  const fetchParams = {
    method: 'POST',
    dataType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'invitation_code_id': invitation_code_id,
    })
  }
  ;(async () => {
    const response = await fetchData('/registration/admin/sendinvitationcode/delete', errorMessage, fetchParams).promise
    if (response.invitation_removed) {
      // no errors....invitation was removed
      setModalOpen(true)
      setReloadData({})
      setModalMessage('Invitation successfully removed')
    } else {
      // error removing invitation
      setModalOpen(true)
      setModalMessage('Invitation could not be removed due to error')
    }
  })()
}

const columns = (setModalOpen, setReloadData, setModalMessage) => [
  {
    name: 'registration_code_id',
    label: '',
    options: {
      display: false,
      filter: false,
      sort: false,
      viewColumns: false,
      searchable: false
    }
  }, {
    name: 'invitation_code',
    label: 'Invitation Code',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, {
    name: 'email',
    label: 'Email',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, {
    name: 'created',
    label: 'Created',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, {
    name: 'code_used',
    label: 'Registered',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: value => {
        if (value) {
          return (
            <Tooltip title='Invitation code has been used to register account to this email' style={{ padding: 0 }}>
              <CheckCircle style={{ color: '#156E01' }} />
            </Tooltip>
          )
        } else {
          return (
            <Tooltip title='Invitation code not used yet' style={{ padding: 0 }}>
              <Cancel style={{ color: '#820000' }} />
            </Tooltip>
          )
        }
      }
    }
  }, {
    name: '',
    label: 'Send Invite',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => sendInvitationEmail(tableMeta.rowData[0], setModalOpen, setModalMessage)}>Send</Button>
      )
    }
  },{
    name: '',
    label: 'remove',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => (
        <Tooltip title='Delete Invitation Code'>
        <IconButton
          onClick={() => handleInvitationCodeDelete(tableMeta.rowData[0], setModalOpen, setReloadData, setModalMessage)}
          size='small'
          aria-label='Delete Invitation Code'
        >
          <Delete style={{ fontSize: '1.2rem', color: '#E81202' }} />
        </IconButton>
      </Tooltip>
      )
    }
  }
]

export default columns
