import React, { useEffect, useState } from 'react'
import { Container, Grid, TableContainer, Paper, Typography, Modal, Fade, Backdrop, Button, CircularProgress } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'

import fetchData from '../../../utility/endpointFetch'
import { getAppStyles } from '../../App/AppStyles'
import columns from './AdminUsersColumns'

const options = {
  filter: true,
  filterType: 'dropdown',
  responsive: 'vertical',
  selectableRows: 'none'
}

const AdminUsers = () => {
  const classes = getAppStyles()

  const [loading, setLoading] = useState(true)
  const [modalLoading, setModalLoading ] = useState(false)
  const [allUsers, setAllUsers] = useState([])
  const [modalOpen,setModalOpen] = useState(false)
  const [userIDToDelete,setUserIDToDelete] = useState(0)
  const [numberUsersToAccount, setNumberUsersToAccount] = useState(0)

  const [firstName,setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email,setEmail] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [postalCode,setPostalCode] = useState('')

  // This is simply to trigger a reload of data.
  // The value itself does not matter, only that it reruns the fetch effect when changed
  const [reloadData, setReloadData] = useState({})

  useEffect(() => {
    setLoading(true)
    const { promise, cancel } = fetchData('/users/admin', 'Error retrieving users for admin users')
    ;(async () => {
      const response = await promise
      setAllUsers(response.all_users)
      setLoading(false)
    })()
    return cancel
  }, [reloadData])

  const handleUserDelete = (userToDelete) => {

    setLoading(true)
    setModalOpen(false)
    ;(async () => {
      const response = await fetchData(`/users/user/delete/${userToDelete}`, 'Error deleting user').promise
      if (response.user_delete) {
        // successfully disabled this user
        setReloadData({})
      } else {
        // error deleting this user...hmmm
      }
    })()
  }

  const loadUserToDelete = (userToDelete) => {
    setUserIDToDelete(userToDelete)
    setModalLoading(true)
    const { promise, cancel } = fetchData(`/users/admin/user/${userToDelete}`, 'Error retrieving users for admin users')
    ;(async () => {
      const response = await promise

      setFirstName(response.user_details.first_name)
      setLastName(response.user_details.last_name)
      setEmail(response.user_details.email)

      setCompanyName( response.account_details.company_name ? response.account_details.company_name : '')
      setAddress1(response.account_details.address_1 ? response.account_details.address_1 : '' )
      setAddress2(response.account_details.address_2 ? '' : response.account_details.address_2)
      setCity(response.account_details.city ? response.account_details.address_2 : '')
      setState(response.account_details.state ? response.account_details.state : '')
      setPostalCode(response.account_details.postal_code ? response.account_details.postal_code : '')
      setNumberUsersToAccount(response.total_number_of_users_in_account)
      setModalLoading(false)
    })()
    return cancel
  }

  return (
    <Container maxWidth='xl' className={classes.container}>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography align='left' variant='h5' style={{ fontWeight: '700' }}>Full User List</Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <LoadingOverlay
                active={loading}
                spinner
                text='Loading Users...'
                fadeSpeed={200}
              >
                <MUIDataTable
                  title=''
                  data={allUsers}
                  columns={columns(setLoading, setModalOpen, setReloadData, loadUserToDelete)}
                  options={options}
                />
              </LoadingOverlay>
            </TableContainer>
          </Grid>
          <Modal
            aria-labelledby='spring-modal-title'
            aria-describedby='spring-modal-description'
            className={classes.modal}
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
          >
            <Fade in={modalOpen}>
              <div className={classes.modalPaper}>
                <h2 id='spring-modal-title'>
                  Are You Sure You Want To Delete This User?
                </h2>
                {modalLoading ? <CircularProgress /> :
                  <>
                    <p id='spring-modal-description' style={{ fontSize: '1rem' }}>
                      User Details that will be deleted:
                      <br /><br />
                      {firstName} {lastName} <br />
                      {email}<br /><br />
                      Attached to the following account:<br /><br />
                      {companyName}<br />
                      {address1}<br />
                      {address2}<br />
                      {city}, {state} {postalCode}
                      <br /><br />
                      { numberUsersToAccount === 1 ? <span style={{color: '#E81202', fontSize: '2rem'}}>This is the last user on this account and so will delete the whole account</span> : null}
                    </p>
                    <Button
                      variant='contained'
                      style={{ backgroundColor: '#128C01', color: '#FFFFFF', maxWidth: '300px', marginTop: '10px' }}
                      size='small'
                      onClick={() => {
                        setModalOpen(false)
                      }}
                    >
                      No, do not delete
                    </Button>
                    <Button
                      variant='contained'
                      style={{ backgroundColor: '#E81202', color: '#FFFFFF', maxWidth: '300px', marginTop: '10px', marginLeft: '20px' }}
                      size='small'
                      onClick={() => handleUserDelete(userIDToDelete)}
                    >
                      Yes, delete this user { numberUsersToAccount === 1 ? " and account" : null }
                    </Button>
                  </>
                }
              </div>
            </Fade>
          </Modal>
        </Grid>
      </Paper>
    </Container>
  )
}

export default AdminUsers
