import { Tooltip, IconButton } from '@material-ui/core'
import { History, Delete } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUserShield, faUserTie,  faUserLock  } from '@fortawesome/free-solid-svg-icons'

import UserState from './UserState'
import hofs from '../../../utility/hofs'

const userDeleteModal = (user_id, setModalOpen, loadUserToDelete) => {
  loadUserToDelete(user_id)
  setModalOpen(true)
}

const columns = (setLoading, setModalOpen, setReloadData, loadUserToDelete) => [
  {
    name: 'user_id',
    label: '',
    options: {
      display: false,
      filter: false,
      sort: false,
      viewColumns: false,
      searchable: false
    }
  }, {
    name: 'first_name',
    label: 'First',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, {
    name: 'last_name',
    label: 'Last',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, {
    name: 'company_name',
    label: 'Company ',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: value => hofs.isPopulated(value) ? value : '(no company name)'
    }
  }, {
    name: 'email',
    label: 'Email ',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, {
    name: 'created_at',
    label: 'Created',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: value => <div>{new Date(value).toLocaleDateString('en-US')}</div>
    }
  }, {
    name: 'is_active',
    label: 'Active',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: (value, tableMeta) => {
        return (
          <UserState
            userId={tableMeta.rowData[0]}
            enabled={value === 1}
            setLoading={setLoading}
            setReloadData={setReloadData}
          />
        )
      }
    },
  }, {
    name: 'roles',
    label: 'Roles',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: value => {
        if (value.indexOf(',') !== -1) {
          return null
        } else {
          switch (value) {
            case 'client_user':
              return (
                <Tooltip title='Client User'>
                  <IconButton size='small' aria-label='Client User'>
                    <FontAwesomeIcon icon={faUser} style={{ color: '#010C80' }} />
                  </IconButton>
                </Tooltip>
              )
            case 'client_admin':
              return (
                <Tooltip title='Client Admin'>
                  <IconButton size='small' aria-label='Client Admin'>
                    <FontAwesomeIcon icon={faUserShield} style={{ color: '#058001' }} />
                  </IconButton>
                </Tooltip>
              )
            case 'admin':
              return (
                <Tooltip title='Def-Logix Admin'>
                  <IconButton size='small' aria-label='Def-Logix Admin'>
                    <FontAwesomeIcon icon={faUserTie} style={{ color: '#010C80' }} />
                  </IconButton>
                </Tooltip>
              )
            case 'unpaid_user':
              return (
                <Tooltip title='Unpaid User'>
                  <IconButton size='small' aria-label='Unpaid User'>
                    <FontAwesomeIcon icon={faUserLock} style={{ color: '#800167' }} />
                  </IconButton>
                </Tooltip>
              )
            default:
              return null
          }
        }
      }
    }
  }, {
    name: '',
    label: 'Actions',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        if ((tableMeta.rowData[0] === 1) || (tableMeta.rowData[7] === 'admin'))
          //site admin...do not remove
          return null
        else
          return (
            <div>
              <Tooltip title='User History'>
                <IconButton
                  size='small'
                  aria-label='User History'
                  component={Link}
                  to={`/admin/users/history/${tableMeta.rowData[0]}`}
                >
                  <History style={{ color: '#0275E8' }} />
                </IconButton>
              </Tooltip>
              <Tooltip
                title='User Delete - Also removes account, keys and machines if only user on account'
                style={{ padding: '0' }}
              >
                <IconButton onClick={() => userDeleteModal(tableMeta.rowData[0], setModalOpen, loadUserToDelete)} >
                  <Delete style={{ color: '#E81202' }} />
                </IconButton>
              </Tooltip>
            </div>
          )
      }
    }
  }
]

export default columns
