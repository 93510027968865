import { Paper, Button, Grid } from '@material-ui/core'
import { getAppStyles } from '../App/AppStyles'

const ReportLastDateDisplay = ({ reportLastDate, lastReportType, refreshPageData }) => {
  const classes = getAppStyles()
  const reportTypes = ['SCAN', 'MITIGATE', 'ROLLBACK']
  let reportType = ''
  // using as some in the past were actual values like above instead of numbers
  if (lastReportType === parseInt(lastReportType, 10)) {
    // passed as a number use it directly
    reportType = reportTypes[lastReportType]
  } else if (reportTypes.includes(lastReportType)) {
    // old way passed....use it directly
    reportType = lastReportType
  } else {
    reportType = reportTypes[parseInt(lastReportType)]
  }

  var offset = new Date().getTimezoneOffset();
  //console.log("time offset: " + offset)

  var server_time = new Date(Date.parse(reportLastDate))
  server_time.setHours(server_time.getHours()-(offset/60))
  return (
    <>
      <Paper className={classes.chartContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={8} xl={8}>
            <div className={classes.containerLabel}>
              Based on Report Received: {reportLastDate !== 0 ? `${server_time.toLocaleString()} (type: ${reportType})` : 'no report received yet' }
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={4} xl={4} style={{ justifyContent: 'flex-end' }}>
            <div>
              <Button size='small' variant='contained' onClick={refreshPageData} color='primary'>Refresh Report</Button>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default ReportLastDateDisplay
