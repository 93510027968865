import { createAuthProvider } from 'react-token-auth'

import fetchData from '../../utility/endpointFetch'

export const [useAuth, authFetch, login, logout] = createAuthProvider({
  accessTokenKey: 'access_token',
  onUpdateToken: async token => {
    // TODO: does this actually work?
    //console.log('inside onUpdateToken')
    const url = '/login/refresh'
    const fetchParams = {
      method: 'POST',
      dataType: 'json',
      headers: { 'Content-Type': 'application/json' },
      // token seems to be the string stored in localStorage from the token provider code in react-token-auth,
      // so token.access_token wouldn't be a thing.
      body: token.access_token
    }
    const response = await fetchData(url, 'Error refreshing token.  Please login again to reset.', fetchParams).promise
    return response.json()
  }
})
