import React from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import CancelIcon from '@material-ui/icons/Cancel'

import fetchData from '../../../utility/endpointFetch'

const enableKey = (keyId, setLoading, setReloadData) => {
  setLoading(true)
  // enable the key passed then refresh page
  ;(async () => {
    await fetchData(`/licenses/enable/${keyId}`, 'Error enabling a key attached to account').promise
    setReloadData({})
  })()
}

const disableKey = (keyId, setLoading, setReloadData) => {
  setLoading(true)
  // disable the key passed then refresh page
  ;(async () => {
    await fetchData(`/licenses/disable/${keyId}`, 'Error disabling a key attached to account').promise
    setReloadData({})
  })()
}

const KeyState = ({ value, localTableMeta, setLoading, setReloadData }) => {
  if (value === 1) {
    // this is a live key
    return (
      <Tooltip title='Key Active - Click to disable' style={{ padding: '0' }}>
        <IconButton onClick={() => disableKey(localTableMeta.rowData[0], setLoading, setReloadData)}>
          <CheckCircle style={{ color: '#156E01' }} />
        </IconButton>
      </Tooltip>
    )
  }
  return (
    <Tooltip title='Key NOT Active - Click to enable' style={{ padding: '0' }}>
      <IconButton onClick={() => enableKey(localTableMeta.rowData[0], setLoading, setReloadData)}>
        <CancelIcon style={{ color: '#820000' }} />
      </IconButton>
    </Tooltip>
  )
}

export default KeyState
