import React, { useEffect, useState } from 'react'
import { Grid, TableContainer, Paper, Button, Chip, Tooltip} from '@material-ui/core'
import { Computer, Warning, Equalizer, ListAlt, Cancel } from '@material-ui/icons'
import MUIDataTable from 'mui-datatables'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'
import MachineRiskDial from './MachineRiskDial'
import AccountRiskBarStats from './AccountRiskBarStats'
import MachineStats from './MachineStats'

import fetchData from '../../utility/endpointFetch'
import { paidOptions, paidColumns } from './MachinesColumns'
import { getAppStyles } from '../App/AppStyles'

// import { mockMachines } from '../../StaticData/mockMachines'

const MachinesPaid = ({ filter, handleModalOpen }) => {
  const classes = getAppStyles()
  const filterValues = [0, 1, 2, 3]

  const [accountMachines, setAccountMachines] = useState([])
  const [allAccountMachines, setAllAccountMachines] = useState([])
  const [averageSecurityScore,setAverageSecurityScore] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [tableTitle,setTableTitle] = useState('')

  // This is simply to trigger a reload of data.
  // The value itself does not matter, only that it reruns the fetch effect when changed
  const [reloadData, setReloadData] = useState({})

  const filterMachines = machine => {
    if (filter) {
      if (machine.active === 1) {
        if (machine.last_report_date != null) {
          switch (parseInt(filter)) {
            case 0: //only high risk returned
              return machine.security_score > 66 ? true : false
            case 1: //only moderate risk returned
              return (machine.security_score > 33 && machine.security_score <= 66) ? true : false
            case 2: //only high risk returned
              return (machine.security_score > 0 && machine.security_score <= 33) ? true : false
            case 3: //only low risk returned
              return machine.security_score === 0 ? true : false
            default:
              return true //there is no
          }
        } else return false
      } else return false
    } else return true //filter removed...show all
  }

  const handleFilterDelete = () => {
    setAccountMachines(allAccountMachines)
    setTableTitle('')
  }


  const getTitle = filter => {
    switch (parseInt(filter, 10)) {
      case 0: //only high risk returned
        return (
          <div className={classes.chipLeft}>
            <Tooltip
              title='Click X to remove filter'
              aria-label='Filtered by Moderate Risk'
              placement='top'
            >
              <Chip
                label='High Risk Only'
                onDelete={handleFilterDelete}
                deleteIcon={<Cancel />}
                color='primary'
              />
            </Tooltip>
          </div>
          )
      case 1: //only moderate risk returned
        return (
          <div className={classes.chipLeft}>
            <Tooltip
              title='Click X to remove filter'
              aria-label='Filtered by Moderate Risk'
              placement='top'
            >
              <Chip
                label='Moderate Risk Only'
                onDelete={handleFilterDelete}
                deleteIcon={<Cancel />}
                color='primary'
              />
            </Tooltip>
          </div>
          )
      case 2: //only low risk returned
        return (
          <div className={classes.chipLeft}>
            <Tooltip
              title='Click X to remove filter'
              aria-label='Filtered by Low Risk'
              placement='top'
            >
              <Chip
                label='Low Risk Only'
                onDelete={handleFilterDelete}
                deleteIcon={<Cancel />}
                color='primary'
              />
            </Tooltip>
          </div>
          )
      case 3: //only no risk returned
        return (
          <div className={classes.chipLeft}>
            <Tooltip
              title='Click X to remove filter'
              aria-label='Filtered by No Risk'
              placement='top'
            >
              <Chip
                label='No Risk Only'
                onDelete={handleFilterDelete}
                deleteIcon={<Cancel />}
                color='primary'
              />
            </Tooltip>
          </div>
          )
      default:
        //do nothing
        return null
    }
  }

  useEffect(() => {
    setIsLoading(true)
    const { promise, cancel } = fetchData('/machines', 'Error retrieving active machines attached to account')
    ;(async () => {
      const response = await promise
      setAllAccountMachines(response.account_machines)
      if (filterValues.includes(parseInt(filter))) {
        const filteredMachines = response.account_machines.filter( filterMachines )
        setAccountMachines(filteredMachines)
      } else {
        //if no filters passed in....just use the full set
        setAccountMachines(response.account_machines)
      }
      setAverageSecurityScore(response.average_security_score.toFixed(0) / 100)
      setIsLoading(false)
    })()
    return cancel
    // const response = mockMachines
    // setAllAccountMachines(response.account_machines)
    // if (filterValues.includes(parseInt(filter))) {
    //   const filteredMachines = response.account_machines.filter( filterMachines )
    //   setAccountMachines(filteredMachines)
    // } else {
    //   //if no filters passed in....just use the full set
    //   setAccountMachines(response.account_machines)
    // }
    // setAverageSecurityScore(response.average_security_score.toFixed(0) / 100)
    // setIsLoading(false)
  }, [reloadData])

  useEffect(() => {
    setTableTitle(getTitle(filter))
    const newFilteredMachines = allAccountMachines.filter(filterMachines, filter)
    setAccountMachines(newFilteredMachines)
  }, [filter])

  return (
    <Grid container spacing={3} className={classes.grid}>
      <Grid item xs={12} md={4} lg={4} xl={4}>
        <Paper className={classes.dashboardSection}>
          <div className={classes.containerLabel} >
            <Warning className={classes.iconAndSpacer} />
            Account Wide Avg Risk Factor
          </div>
          <MachineRiskDial isLoading={isLoading} securityRiskScore={averageSecurityScore} />
          <div className={classes.riskDialText}>% of Insecurities per Def-Logix Analysis of Best-Practices</div>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4} lg={4} xl={4}>
        <Paper className={classes.dashboardSection}>
          <div className={classes.containerLabel}>
            <Equalizer className={classes.iconAndSpacer} />
            Machines By Security Score
          </div>
          <AccountRiskBarStats />
        </Paper>
      </Grid>
      <Grid item xs={12} md={4} lg={4} xl={4}>
        <Paper className={classes.dashboardSection} >
          <div className={classes.containerLabel}>
            <ListAlt className={classes.iconAndSpacer} />
            Account Machine Stats
          </div>
          <MachineStats />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <LoadingOverlay
            active={isLoading}
            spinner
            text='Loading Machines...'
            fadeSpeed={200}
          >
            <MUIDataTable
              title={tableTitle}
              data={accountMachines}
              columns={paidColumns(setIsLoading, setReloadData)}
              options={paidOptions(accountMachines, filter)}
            />
          </LoadingOverlay>
        </TableContainer>
        <Button
          variant='contained'
          style={{ backgroundColor: '#128C01', color: '#FFFFFF', maxWidth: '300px', marginTop: '10px' }}
          size='small'
          className={classes.button}
          onClick={handleModalOpen}
          startIcon={<Computer />}
        >
          add new machine
        </Button>
      </Grid>
    </Grid>
  )
}

export default MachinesPaid
