import React, { useEffect, useState } from 'react'
import { Container, Grid, TableContainer, Paper, Typography, Button, Modal, Backdrop, Fade } from '@material-ui/core'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'
import MUIDataTable from 'mui-datatables'
import { NavLink } from 'react-router-dom'

import fetchData from '../../../utility/endpointFetch'
import columns from './AdminInvitationCodesColumns'
import { getAppStyles } from '../../App/AppStyles'

const options = {
  filter: true,
  filterType: 'dropdown',
  responsive: 'vertical',
  selectableRows: 'none'
}

const AdminInvitationCodes = () => {
  const classes = getAppStyles()
  const [isLoading, setIsLoading] = useState(true)
  const [allInvitationCodes, setAllInvitationCodes] = useState([])
  const [modalOpen,setModalOpen] = useState(false)
  const [reloadData, setReloadData] = useState({})
  const [modalMessage,setModalMessage] = useState('')

  useEffect(() => {
    const errorMessage = 'Error retrieving invitation codes for admin users'
    const { promise, cancel } = fetchData('/registration/admin/invitationcodes', errorMessage)
    ;(async () => {
      const response = await promise
      setAllInvitationCodes(response.invitation_codes_list || [])
      setIsLoading(false)
    })()
    return cancel
  }, [reloadData])

  const handleModalClose = () => {
    setModalOpen(false)
  }

  return (
    <Container maxWidth='xl' className={classes.container}>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography align='left' variant='h5' style={{ fontWeight: '700' }}>
              All Current Invitation Codes
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <LoadingOverlay active={isLoading} spinner text='Loading Invitation Codes..' fadeSpeed={200}>
                <MUIDataTable title='' data={allInvitationCodes} columns={columns(setModalOpen, setReloadData, setModalMessage)} options={options} />
              </LoadingOverlay>
            </TableContainer>
            <Button
              variant='contained'
              style={{ backgroundColor: '#128C01', color: '#FFFFFF', maxWidth: '300px', marginTop: '10px' }}
              size='small'
              component={NavLink}
              className={classes.button}
              href='/admin/invitationcodes/new'
              to='/admin/invitationcodes/new'
            >
              add new invitation code
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Modal
        aria-labelledby='spring-modal-title'
        aria-describedby='spring-modal-description'
        className={classes.modal}
        open={modalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={modalOpen}>
          <div className={classes.modalPaper}>
            <h2 id='spring-modal-title'>Invitation Code  Response</h2>
            <p id='spring-modal-description' style={{ fontSize: '1.2rem' }}>
              {modalMessage}<br/><br/>
            </p>
            <br /><br />
            <Button
              variant='contained'
              style={{ backgroundColor: '#128C01', color: '#FFFFFF', maxWidth: '300px', marginTop: '10px' }}
              size='small'
              onClick={() => handleModalClose()}
            >
              close
            </Button>
          </div>
        </Fade>
      </Modal>
    </Container>
  )
}

export default AdminInvitationCodes
