import React, { useState } from 'react'
import { Drawer, List, Divider, IconButton } from '@material-ui/core'
import { Menu as MenuIcon, ChevronLeft as ChevronLeftIcon } from '@material-ui/icons'
import clsx from 'clsx'

import ListItems from './ListItems'
import { getAppStyles } from '../App/AppStyles'

const Sidebar = () => {
  const classes = getAppStyles()
  const [open, setOpen] = useState(true)

  return (
    <Drawer
      variant='permanent'
      classes={{ paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose) }}
      open={open}
    >
      <Divider />
      <div className={clsx(classes.toolbarIcon, open && classes.toolbarIconOpen)}>
        {open ? (
          <IconButton aria-label='close drawer' onClick={() => setOpen(false)}>
            <ChevronLeftIcon />
          </IconButton>
        ) : (
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={() => setOpen(true)}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            size='small'
          >
            <MenuIcon />
          </IconButton>
        )}
      </div>
      <Divider />
      <List>
        <ListItems />
      </List>
    </Drawer>
  )
}

Sidebar.propTypes = {}

export default Sidebar
