import React from 'react'
import { Button, Typography, Grid, Container, Avatar } from '@material-ui/core'
import { ThumbUpAltSharp } from '@material-ui/icons'

import { getRegisterStyles } from './RegisterStyles'

const RegisterTwoVerification = ( { setRegistrationStep } ) => {
  const classes = getRegisterStyles()

  const verifyClick = e => {
    setRegistrationStep(2)
  }

  return (
    <Container component='main' maxWidth='sm'>
      <div className={classes.paper}>
        <Avatar className={classes.avatar} src='/def-logix-d-larger.png' />
        <Typography component='h1' variant='h5'>
          Registration Verification
        </Typography>
        <h2 id='spring-modal-title'>Def-Logix Security Manager Tool Registration </h2>
        <p id='spring-modal-description' style={{ fontSize: '1rem' }}>
          Please verify your email address to move to complete registration
          <br /><br />
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: '25px'}}>
              <Typography variant="subtitle2">You've been sent an email to the email address included within your registration details.  Open that email and click the link within it.  After verification
              you will be redirected here to complete your registration</Typography>
            </Grid>
          </Grid>
        </p>
      </div>
    </Container>
  )
}

export default RegisterTwoVerification
