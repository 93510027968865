import React, { useState } from 'react'
import { AccountCircle } from '@material-ui/icons'
import { Badge, Popover, Typography, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'

import { userIconDisplayStyles } from './UserIconDisplayStyles'

const UserIconDisplay = ({ userInfo: { first_name, last_name, role } }) => {
  const classes = userIconDisplayStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  return (
    <div>
      <Typography
        aria-owns={!!anchorEl ? 'mouse-over-popover' : undefined}
        aria-haspopup='true'
        onMouseEnter={e => setAnchorEl(e.currentTarget)}
        onMouseLeave={() => setAnchorEl(null)}
      >
        <Badge color='secondary'><AccountCircle /></Badge>
      </Typography>
      <Popover
        id='mouse-over-popover'
        className={classes.popover}
        classes={{ paper: classes.paper }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
      >
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}><strong>User Details</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align='right'>User:</TableCell>
              <TableCell>{first_name} {last_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='right'>Role:</TableCell>
              <TableCell>{role}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Popover>
    </div>
  )
}

export default UserIconDisplay
