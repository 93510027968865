import React, { useState, useEffect } from 'react'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'
import { Button, Grid, Container, TextField, Modal, Paper, MenuItem, Backdrop, Fade, Link } from '@material-ui/core'

import PageTitle from '../PageTitle'
import fetchData from '../../utility/endpointFetch'
import { stateNameList } from '../../StaticData/stateNameList'
import { stateAbbreviationList } from '../../StaticData/stateAbbreviationList'
import { industryList } from '../../StaticData/industryList'
import { getAppStyles } from '../App/AppStyles'

// const address1ErrorMessage = 'Business address is required'
// const cityErrorMessage = 'City is required'
// const stateErrorMessage = 'State is required'
// const postalCodeErrorMessage = 'Postal code is required'
// const industryErrorMessage = 'Industry choice is required'
// const companyNameErrorMessage = 'Company name is required'

const AccountEdit = () => {
  const classes = getAppStyles()

  const [isLoading, setIsLoading] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)

  const [address1, setAddress1] = useState()
  const [address2, setAddress2] = useState()
  const [city, setCity] = useState()
  const [state, setState] = useState()
  const [postalCode, setPostalCode] = useState()
  const [phoneNumber, setPhoneNumber] = useState()
  const [industry, setIndustry] = useState()
  const [companyName, setCompanyName] = useState()

  // const [address1Error, setAddress1Error] = useState(false)
  // const [cityError, setCityError] = useState(false)
  // const [stateError, setStateError] = useState(false)
  // const [postalCodeError, setPostalCodeError] = useState(false)
  // const [industryError, setIndustryError] = useState(false)
  // const [companyNameError, setCompanyNameError] = useState(false)
  // const [accountEditError, setAccountEditError] = useState(false)

  useEffect(() => {
    const { promise, cancel } = fetchData('/account/', 'Error retrieving users attached to account')
    ;(async () => {
      const response = await promise
      setAddress1(response.address_1)
      setAddress2(response.address_2)
      setCity(response.city)
      setState(response.state)
      setPostalCode(response.postal_code)
      setPhoneNumber(response.phone_number)
      setIndustry(response.industry)
      setCompanyName(response.company_name)
      setPostalCode(response.postal_code)
      setIsLoading(false)
    })()
    return cancel
  }, [])

  const handleAddress1Change = e => setAddress1(e.target.value)
  const handleAddress2Change = e => setAddress2(e.target.value)
  const handlePhoneNumberChange = e => setPhoneNumber(e.target.value)
  const handleCityChange = e => setCity(e.target.value)
  const handleStateChange = e => setState(e.target.value)
  const handlePostalCodeChange = e => setPostalCode(e.target.value)
  const handleIndustryChange = e => setIndustry(e.target.value)
  const handleCompanyChange = e => setCompanyName(e.target.value)

  const handleAccountEditSubmit = e => {
    e.preventDefault()
    let errorCount = 0
    //first is to check if all required forms are filled

    //if (hofs.isPopulated(address1)) {
    //    setAddress1Error(false)
    //} else {
    //    errorCount++
    //    setAddress1Error(true)
    //}
    //if (hofs.isPopulated(city)) {
    //    setCityError(false)
    //} else {
    //    errorCount++
    //    setCityError(true)
    //}
    //if (hofs.isPopulated(state) && state != undefined) {
    //    setStateError(false)
    //} else {
    //    errorCount++
    //    setStateError(true)
    //}
    //if (hofs.isPopulated(postalCode)) {
    //    setPostalCodeError(false)
    //} else {
    //   errorCount++
    //    setPostalCodeError(true)
    //}
    //if (hofs.isPopulated(industry)) {
    //    setIndustryError(false)
    //} else {
    //    errorCount++
    //    setIndustryError(true)
    //}
    //if (hofs.isPopulated(companyName)) {
    //    setCompanyNameError(false)
    //} else {
    //    errorCount++
    //    setCompanyNameError(true)
    //}

    if (errorCount === 0) {
      //there are no errors...send the data to backend to complete registration

      //having issues...will re-add later
      //setModalOpen(true)

      const fetchParams = {
        method: 'PUT',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'address_1': address1,
          'address_2': address2,
          'city': city,
          'state': state,
          'postal_code': postalCode,
          'phone_number': phoneNumber,
          'industry': industry,
          'company_name': companyName
        })
      }
      const errorMessage = 'Error creating your account.  Please contact support to do so.'
      ;(async () => {
        await fetchData('/account/', errorMessage, fetchParams).promise
        // setAccountEditError(response.error)
        setIsLoading(false)
      })()
    }
  }

  return (
    <Container maxWidth='xl' className={classes.container}>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <PageTitle title='Edit Account Information' />
          <Grid item xs={12}>
            <LoadingOverlay
              active={isLoading}
              spinner
              text='Loading Account Information...'
              fadeSpeed={200}
            >
              {isLoading ? null : (
                <form className={classes.form} noValidate onSubmit={handleAccountEditSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        variant='outlined'
                        defaultValue={address1}
                        fullWidth
                        id='address_1'
                        label='Address Line 1'
                        name='address_1'
                        onChange={handleAddress1Change}
                        // helperText={address1Error ? address1ErrorMessage : null}
                        // error={address1Error}
                        inputProps={{ maxLength: 100 }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant='outlined'
                        defaultValue={address2}
                        fullWidth
                        id='address_2'
                        label='Address Line 2'
                        name='address_2'
                        autoComplete='address_2 name'
                        onChange={handleAddress2Change}
                        inputProps={{ maxLength: 100 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name='city'
                        defaultValue={city}
                        variant='outlined'
                        fullWidth
                        id='city'
                        label='City'
                        onChange={handleCityChange}
                        // helperText={cityError ? cityErrorMessage : null}
                        // error={cityError}
                        inputProps={{ maxLength: 50 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id='state'
                        name='state'
                        select
                        label='State'
                        value={state}
                        onChange={handleStateChange}
                        variant='outlined'
                        fullWidth
                        // helperText={stateError ? stateErrorMessage : null}
                        // error={stateError}
                        inputProps={{ maxLength: 25 }}
                      >
                        {stateNameList.map((option,key) => (
                          <MenuItem key={key} value={stateAbbreviationList[key]} >
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        defaultValue={postalCode}
                        fullWidth
                        id='postal_code'
                        label='Postal Code '
                        name='postal_code'
                        onChange={handlePostalCodeChange}
                        // helperText={postalCodeError ? postalCodeErrorMessage : null}
                        // error={postalCodeError}
                        inputProps={{ maxLength: 25 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        defaultValue={phoneNumber}
                        fullWidth
                        id='phone_number'
                        label='Phone Number'
                        name='phone_number'
                        onChange={handlePhoneNumberChange}
                        inputProps={{ maxLength: 25 }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id='industry'
                        defaultValue={industry}
                        name='industry'
                        select
                        label='Industry'
                        value={industry}
                        onChange={handleIndustryChange}
                        variant='outlined'
                        fullWidth
                        // helperText={industryError ? industryErrorMessage : null}
                        // error={industryError}
                        inputProps={{ maxLength: 50 }}
                      >
                        {industryList.map((option,key) => (
                          <MenuItem key={key} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant='outlined'
                        defaultValue={companyName}
                        fullWidth
                        id='company_name'
                        label='Company Name'
                        name='company_name'
                        autoComplete='company name'
                        onChange={handleCompanyChange}
                        // helperText={companyNameError ? companyNameErrorMessage : null}
                        // error={companyNameError}
                        inputProps={{ maxLength: 100 }}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    className={classes.submit}
                  >
                    Save Account Changes
                  </Button>
                </form>
              )}
            </LoadingOverlay>
          </Grid>
        </Grid>
      </Paper>
      <Modal
        aria-labelledby='spring-modal-title'
        aria-describedby='spring-modal-description'
        className={classes.modal}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={modalOpen}>
          <div className={classes.modalPaper}>
            <h2 id='spring-modal-title'>Def-Logix Account Details Edit </h2>
            <p id='spring-modal-description' style={{ fontSize: '.7rem' }}>
              Your new details will be saved!<br/><br/>
            </p>
            <p>
              <Link to='/dashboard'>
                <Button href='/dashboard' variant='contained' color='primary'>
                  {' Continue to Your Account Home '}
                </Button>
              </Link>
            </p>
          </div>
        </Fade>
      </Modal>
    </Container>
  )
}

export default AccountEdit
