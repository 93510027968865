import { CheckCircle, Cancel } from '@material-ui/icons'
import { Chip } from '@material-ui/core'

import MachineActions from './MachineActions'
import MachineExpandableRowDisplay from './MachineExpandableRowDisplay'

export const paidColumns = (setLoading, setReloadData) => [
  {
    name: 'machine_id',
    label: '',
    options: {
      display: false,
      filter: false,
      sort: false,
      viewColumns: false,
      searchable: false
    }
  }, /*{
    name: 'client_machine_id',
    label: 'Client Machine ID',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  },*/ {
    name: 'machine_name',
    label: 'Machine Name',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, /*{
    name: 'first_covered',
    label: 'Added',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: value => <div>{new Date(value).toLocaleDateString('en-US')}</div>
    }
  },*/ {
    name: 'last_report_date',
    label: 'Last Report',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: value => {
        if ((value === 0) || (value===null)) return <div>none yet</div>
        else return <div>{new Date(value).toLocaleDateString('en-US')}</div>
      }
    }
  },/* {
    name: 'percentage_secure',
    label: '% Secure',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: value => {
        // this will be a switch to display the score in a dot with color
        if (value === 0) return 'none'
        else return <div>{Math.round(value * 100)}%</div>
      }
    }
  },*/ {
    name: 'security_score',
    label: 'Score',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: (value, rowData) => {
        //console.log("custombodyrender security score value is: " + value)
        let percentage = Math.round(value) + '%'
        let chip_color = "#eeeeee"
        // this will be a switch to display the score in a dot with color
        if ((value === 0) && (rowData.rowData[2] === null )) return 'after first report'
        else {
          if (parseInt(value) === 0) {
            //rgba(75, 192, 87, 1) #3cab46
            return <Chip size="small" label={percentage} style={{backgroundColor: '#a4eba9', fontSize: '.8em' }}/>
          } else if (parseInt(value) > 0 && parseInt(value) <= 33) {
            //rgba(75, 192, 192, 1) #368787
            return <Chip size="small"  label={percentage} style={{backgroundColor: '#99dede', fontSize: '.8em' }}/>
          } else if (parseInt(value) > 33 && parseInt(value) <= 66) {
            //rgba(255, 206, 86, 1) #f08d30
            return <Chip size="small"  label={percentage} style={{backgroundColor: '#fad087', fontSize: '.8em' }}/>
          } else if (parseInt(value) > 66) {
            //rgba(255, 99, 132, 1) #f70034
            return <Chip size="small"  label={percentage} style={{backgroundColor: '#f7b0b0', fontSize: '.8em' }}/>
          } else {
            //what is it if not one of the above
            return <Chip size="small"  label={percentage} style={{backgroundColor: '#eeeeee', fontSize: '.8em' }}/>
          }
        }
      }
    }
  }, {
    name: '',
    label: '',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: (value, tableMeta) => {
        //const random = Math.floor(Math.random() * risk_colors.length);
        //let tooltip_title = "Unknown Risk";
        //if (random === 1) {
        //    tooltip_title = "Some Risk";
        //} else if (random === 2){
        //     tooltip_title = "Intermediate Risk";
        //} else if (random === 3 ) {
        //    tooltip_title = "High Risk";
        //} else {
        //    tooltip_title = "No Risk"
        //}
        //return (
        //    <Tooltip title={tooltip_title} aria-label="Risk Level" >
        //        <FiberManualRecord  style={{color: risk_colors[random] }} />
        //    </Tooltip>
        //);
        return null
      }
    }
  }, {
    name: 'active',
    label: 'Active',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: (value, tableMeta) => value === 1
        // this user is active
        ? <CheckCircle style={{ fontSize: '1.2rem', color: '#038231' }} />
        // this user is inactive
        : <Cancel style={{ fontSize: '1.2rem', color: '#E81202' }} />,
      filterType: 'dropdown',
      customFilterListOptions: {
        render: v => v === 1 ? 'active' : 'inactive'
      },
      filterOptions: {
        renderValue: value => value === 0 ? 'inactive' : 'active'
      }
    }
  }, {
    name: '',
    label: 'Actions',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => (
        <MachineActions
          enabled={tableMeta.rowData[5] === 1}
          machineId={tableMeta.rowData[0]}
          setLoading={setLoading}
          setReloadData={setReloadData}
        />
      ),
      customHeadLabelRender: () => null
    }
  }
]

export const historyColumns = [
  {
    name: 'machine_history_id',
    label: '',
    options: {
      display: false,
      filter: false,
      sort: false,
      viewColumns: false,
      searchable: false
    }
  }, {
    name: 'action',
    label: 'Action',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, {
    name: 'action_details',
    label: 'Details',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, {
    name: 'action_taken_at',
    label: 'Date',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: value => <div>{new Date(value).toLocaleDateString('en-US')}</div>
    }
  }
]

// TODO: will need to be dealt with
export const paidOptions = (accountMachines, filter) => ({
  filter: true,
  filterType: 'dropdown',
  responsive: 'vertical',
  selectableRows: 'none',
  expandableRows: true,
  expandableRowsHeader: false,
  expandableRowsOnClick: true,
  renderExpandableRow: (rowData, rowMeta) => {
    return <MachineExpandableRowDisplay machineRowDetails={accountMachines[rowMeta.dataIndex]} />
  },
  onCellClick: (cellData, cellMeta) => {
    // only want to link to machine details if these columns are clicked
    if (cellMeta.colIndex === 1 || cellMeta.colIndex === 2 || cellMeta.colIndex === 3 ) {
      // link to machine details is now within the expandable row
      // history.push(`/machines/details/${accountMachines[cellMeta.rowIndex].machine_id}`)
      return null // for the moment until the machine details page is finished
    } else {
      return null
    }
  }
})
