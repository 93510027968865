import React, { useState } from 'react'

import DashboardUnpaid from '../DashboardUnpaid'
import DashboardPaid from './DashboardPaid'

const Dashboard = ({ userInfo: { role, first_name, last_name, company_name } }) => {
  const [companyName] = useState(company_name ? `( ${company_name} )` : '')
  const [dashboardName] = useState(`${first_name} ${last_name}${companyName}`)
  if (role === 'unpaid_user') return <DashboardUnpaid pageTitle={dashboardName} />
  return <DashboardPaid pageTitle={dashboardName} />
}
export default Dashboard
