import React, { useState } from 'react'

import hofs from '../../utility/hofs'
import { logout } from '../Auth/auth'
import { getLoginStyles } from './LoginStyles'
import Login from './Login'

const Logout = () => {

  logout()

  return Login
}
export default Logout