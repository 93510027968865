import React, { useEffect, useState } from 'react'
import { Container, Grid, TableContainer, Paper, Typography, Button } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'

import fetchData from '../../utility/endpointFetch'
import PageTitle from '../PageTitle'
import { getAppStyles } from '../App/AppStyles'
import { historyColumns } from './MachinesColumns'

const historyOptions = {
  filter: true,
  filterType: 'dropdown',
  responsive: 'vertical',
  selectableRows: 'none'
}

const tableTitle = (pathname, history) => (
  <Typography align='left'>
    <Button
      onClick={() => history.push(pathname.includes('admin') ? '/admin/machines' : '/machines')}
      startIcon={<FontAwesomeIcon icon={faChevronCircleLeft} style={{ color: '#010C80' }} />}
    >
      Return to Machines List
    </Button>
  </Typography>
)

const MachineHistory = () => {
  const classes = getAppStyles()
  const history = useHistory()
  const { pathname } = useLocation()
  const { machine } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [machineHistory, setMachineHistory] = useState([])

  useEffect(() => {
    const url = `/machines/history/${machine}`
    const { promise, cancel } = fetchData(url, 'Error retrieving history attached to machine')
    ;(async () => {
      const response = await promise
      setMachineHistory(response.machine_history)
      setIsLoading(false)
    })()
    return cancel
  }, [machine])

  return (
    <Container maxWidth='xl' className={classes.container}>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <PageTitle title='Machine History' />
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading Machines...'
                fadeSpeed={200}
              >
                <MUIDataTable
                  title={tableTitle(pathname, history)}
                  data={machineHistory}
                  columns={historyColumns}
                  options={historyOptions}
                />
              </LoadingOverlay>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}

export default MachineHistory
