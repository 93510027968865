import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNetworkWired, faHdd, faCode, faUser, faCircle } from '@fortawesome/free-solid-svg-icons'
import { faWindows } from '@fortawesome/free-brands-svg-icons'
import { Checkbox, Tooltip, IconButton, Paper, Button } from '@material-ui/core'
import { ArrowDropDown, ArrowRight, Security, CheckCircle, Cancel, OpenInNew } from '@material-ui/icons'

import { getHierarchyStyles } from './HierarchyStyles'

const classifications = ['', 'OS - Operating System', 'User', 'Network', 'Application', 'Storage', 'Other']
const severities = [
  { name: 'Low', color: '#FCDB35' },
  { name: 'Medium', color: '#F08A26' },
  { name: 'High', color: '#E81202' },
  { name: 'None', color: '#35FC60' }
]

const handleCheckboxChange = (selected, setSelected, id) => e =>
  setSelected(
    e.target.checked
      ? [ ...selected, id ]
      : [ ...selected.filter(s => s !== id) ]
  )

const mapLinksToButtons = classes => link => (
  <span key={link.label} className={classes.InfoModal__infoLinks}>
    <Button
      size='small'
      variant='contained'
      color='primary'
      href={link.link}
      startIcon={<OpenInNew />}
      target='_blank'>
      {link.label}
    </Button>
  </span>
)

// This is used to fix a console warning about tooltips and components without refs.
// The tooltip that surrounds it needs the icon to have a ref.
const FaIconWithRef = React.forwardRef((props, ref) => <FontAwesomeIcon {...props} ref={ref} />)
const getCategoryIcon = (category, classes) => {
  const icons = [faCircle, faWindows, faUser, faNetworkWired, faCode, faHdd]
  return <FaIconWithRef icon={icons[category || 0]} className={classes.LeafRow__faIcon} />
}

const LeafRow = ({ row, selected, setSelected, show }) => {
  const [showMore, setShowMore] = useState(false)
  const hierarchyClasses = getHierarchyStyles()

  return show && (
    <>
      <div key={row.secid} className={hierarchyClasses.LeafRow__base}>
        <span className={hierarchyClasses.LeafRow__rowItem}>
          {row.secstate ? (
            <Tooltip title='Secure'>
              <CheckCircle style={{ color: '#038231', fontSize: '1.1rem' }} aria-label='Secure' />
            </Tooltip>
          ) : (
            <Tooltip title='Insecure'>
              <Cancel style={{ color: '#E81202', fontSize: '1.1rem' }} aria-label='Insecure' />
            </Tooltip>
          )}
        </span>
        <Tooltip title='Enforce This Setting'>
          <Checkbox
            onChange={handleCheckboxChange(selected, setSelected, row.secid)}
            checked={selected.includes(row.secid)}
            color='primary'
          />
        </Tooltip>
        <span className={hierarchyClasses.LeafRow__rowItem}>
          {row.security_element}
          <span
            style={{ color: severities[row.severity].color }}
            className={hierarchyClasses.LeafRow__severityIconWrapper}
          >
            <Tooltip title={`Severity - ${(severities[row.severity] || severities[3]).name}`}>
              <Security
                style={{ color: (severities[row.severity] || severities[3]).color }}
                className={hierarchyClasses.LeafRow__severityIcon}
                aria-label={`Severity ${(severities[row.severity] || severities[3]).name}`}
              />
            </Tooltip>
          </span>
          <Tooltip title={classifications[row.classification]}>
            <IconButton
              size='small'
              aria-label={classifications[row.classification]}
              className={hierarchyClasses.LeafRow__classificationIconWrapper}
            >
              {getCategoryIcon(row.classification, hierarchyClasses)}
            </IconButton>
          </Tooltip>
          <Tooltip title={showMore ? 'Less' : 'More Info'}>
            <IconButton
              size='small'
              aria-label={`Collapse ${row.security_element}`}
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? <ArrowDropDown /> : <ArrowRight />}
            </IconButton>
          </Tooltip>
        </span>
      </div>
      <Paper style={{ padding: '1rem', display: showMore ? 'block' : 'none' }}>
        <div className={hierarchyClasses.LeafRow__consequencesWrapper}>{row.consequences}</div>
        <br />
        <div>{row.link_info}</div>
        <div>{row.additional_links.map(mapLinksToButtons(hierarchyClasses))}</div>
      </Paper>
    </>
  )
}

export default LeafRow
