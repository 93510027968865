import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'
import {
  Button,
  Typography,
  Grid,
  Container,
  TextField,
  Paper,
  MenuItem,
  FormControl,
  FormLabel,
  Table,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core'

import fetchData from '../../../utility/endpointFetch'
import { stateNameList } from '../../../StaticData/stateNameList'
import { stateAbbreviationList } from '../../../StaticData/stateAbbreviationList'
import { industryList } from '../../../StaticData/industryList'
import hofs from '../../../utility/hofs'
import { getAppStyles } from '../../App/AppStyles'

// const address1ErrorMessage = 'Business address is required'
// const cityErrorMessage = 'City is required'
// const stateErrorMessage = 'State is required'
// const postalCodeErrorMessage = 'Postal code is required'
// const industryErrorMessage = 'Industry choice is required'
// const companyNameErrorMessage = 'Company name is required'

const AdminAccountEdit = () => {
  const { accountId } = useParams()
  const classes = getAppStyles()
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(true)

  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [industry, setIndustry] = useState('')
  const [companyName, setCompanyName] = useState('')

  // const [address1Error, setAddress1Error] = useState(false)
  // const [cityError, setCityError] = useState(false)
  // const [stateError, setStateError] = useState(false)
  // const [postalCodeError, setPostalCodeError] = useState(false)
  // const [industryError, setIndustryError] = useState(false)
  // const [companyNameError, setCompanyNameError] = useState(false)
  // const [accountEditError, setAccountEditError] = useState(false)

  const [accountStatus, setAccountStatus] = useState('')
  // const [subscriptionExpiration, setSubscriptionExpiration] = useState()
  // const [stripeAccountDetails, setStripeAccountDetails] = useState()
  const [stripeCustomerId, setStripeCustomerId] = useState('')
  const [stripeSubscriptionId, setStripeSubscriptionId] = useState('')
  const [stripeSubscriptionItemId, setStripeSubscriptionItemId] = useState('')
  const [stripePaymentMethodId, setStripePaymentMethodId] = useState('')
  const [stripeLastUsageSent, setStripeLastUsageSent] = useState('')
  const [howDidYouHearAboutUs, setHowDidYouHearAboutUs] = useState('')

  useEffect(() => {
    const fetchURL = `/account/admin/edit/${accountId}`
    const { promise, cancel } = fetchData(fetchURL, 'Error retrieving users attached to account')
    ;(async () => {
      const response = await promise
      //setAccountInfo(response)
      //console.log("response from /account/admin/edit/")
      //console.dir(response)
      setAddress1(response.account_to_edit.address_1)
      setAddress2(response.account_to_edit.address_2)
      setCity(response.account_to_edit.city)
      if (hofs.isPopulated(response.account_to_edit.state)) setState(response.account_to_edit.state)
      else setState('')
      setPostalCode(response.account_to_edit.postal_code)
      setPhoneNumber(response.account_to_edit.phone_number)
      if (hofs.isPopulated(response.account_to_edit.industry)) setIndustry(response.account_to_edit.industry)
      else setIndustry('')
      setCompanyName(response.account_to_edit.company_name)
      setAccountStatus(response.account_to_edit.account_status)
      // if (hofs.isPopulated(response.account_to_edit.subscription_expiration))
      //   setSubscriptionExpiration(response.account_to_edit.subscription_expiration)
      // else setSubscriptionExpiration('')
      // setStripeAccountDetails(response.account_to_edit.stripe_account_details)
      setStripeCustomerId(response.account_to_edit.stripe_customer_id)
      setStripeSubscriptionId(response.account_to_edit.stripe_subscription_id)
      setStripeSubscriptionItemId(response.account_to_edit.stripe_subscription_item_id)
      setStripePaymentMethodId(response.account_to_edit.stripe_payment_method_id)
      setStripeLastUsageSent(response.account_to_edit.stripe_last_usage_sent)
      setHowDidYouHearAboutUs(response.account_to_edit.how_did_you_hear_about_us)
      setIsLoading(false)
    })()
    return cancel
  }, [accountId])

  const handleAddress1Change = e => setAddress1(e.target.value)
  const handleAddress2Change = e => setAddress2(e.target.value)
  const handlePhoneNumberChange = e => setPhoneNumber(e.target.value)
  const handleCityChange = e => setCity(e.target.value)
  const handleStateChange = e => setState(e.target.value)
  const handlePostalCodeChange = e => setPostalCode(e.target.value)
  const handleIndustryChange = e => setIndustry(e.target.value)
  const handleCompanyChange = e => setCompanyName(e.target.value)

  const handleAccountEditSubmit = e => {
    e.preventDefault()
    let errorCount = 0
    //first is to check if all required forms are filled

    //if (hofs.isPopulated(address1)) {
    //    setAddress1Error(false)
    //} else {
    //    errorCount++
    //    setAddress1Error(true)
    //}
    //if (hofs.isPopulated(city)) {
    //    setCityError(false)
    //} else {
    //    errorCount++
    //    setCityError(true)
    //}
    //if (hofs.isPopulated(state) && state != undefined) {
    //    setStateError(false)
    //} else {
    //    errorCount++
    //    setStateError(true)
    //}
    //if (hofs.isPopulated(postalCode)) {
    //    setPostalCodeError(false)
    //} else {
    //   errorCount++
    //    setPostalCodeError(true)
    //}
    //if (hofs.isPopulated(industry)) {
    //    setIndustryError(false)
    //} else {
    //    errorCount++
    //    setIndustryError(true)
    //}
    //if (hofs.isPopulated(companyName)) {
    //    setCompanyNameError(false)
    //} else {
    //    errorCount++
    //    setCompanyNameError(true)
    //}

    if (errorCount === 0) {
      //there are no errors...send the data to backend to complete registration

      const url = `/account/admin/edit/${accountId}`
      const errorMessage = 'Error saving edits to an account.  Please contact support to do so.'
      const fetchParams = {
        method: 'PUT',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          address_1: address1,
          address_2: address2,
          city: city,
          state: state,
          postal_code: postalCode,
          phone_number: phoneNumber,
          industry: industry,
          company_name: companyName,
          account_status: accountStatus
        })
      }
      ;(async () => {
        const response = await fetchData(url, errorMessage, fetchParams).promise
        // setAccountEditError(response.error)
        if (!response.error) {
          setIsLoading(false)
          history.push('/admin/accounts')
        }
      })()
    }
  }

  return (
    <Container maxWidth='xl' className={classes.container}>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography align='left' variant='h5'>
              Edit Account Information
            </Typography>
          </Grid>
          <LoadingOverlay active={isLoading} spinner text='Loading Account Information...' fadeSpeed={200}>
            <Grid item xs={12} md={12} lg={12}>
              <form className={classes.form} noValidate onSubmit={handleAccountEditSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      variant='outlined'
                      value={address1}
                      fullWidth
                      id='address_1'
                      label='Address Line 1'
                      name='address_1'
                      onChange={handleAddress1Change}
                      // helperText={address1Error ? address1ErrorMessage : null}
                      // error={address1Error}
                      inputProps={{ maxLength: 100 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant='outlined'
                      value={address2}
                      fullWidth
                      id='address_2'
                      label='Address Line 2'
                      name='address_2'
                      autoComplete='address_2 name'
                      onChange={handleAddress2Change}
                      inputProps={{ maxLength: 100 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name='city'
                      value={city}
                      variant='outlined'
                      fullWidth
                      id='city'
                      label='City'
                      onChange={handleCityChange}
                      // helperText={cityError ? cityErrorMessage : null}
                      // error={cityError}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id='state'
                      name='state'
                      select
                      label='State'
                      value={state}
                      onChange={handleStateChange}
                      variant='outlined'
                      fullWidth
                      // helperText={stateError ? stateErrorMessage : null}
                      // error={stateError}
                      inputProps={{ maxLength: 25 }}
                    >
                      {stateNameList.map((option, key) => (
                        <MenuItem key={key} value={stateAbbreviationList[key]}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant='outlined'
                      value={postalCode}
                      fullWidth
                      id='postal_code'
                      label='Postal Code '
                      name='postal_code'
                      onChange={handlePostalCodeChange}
                      // helperText={postalCodeError ? postalCodeErrorMessage : null}
                      // error={postalCodeError}
                      inputProps={{ maxLength: 25 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant='outlined'
                      value={phoneNumber || ''}
                      fullWidth
                      id='phone_number'
                      label='Phone Number'
                      name='phone_number'
                      onChange={handlePhoneNumberChange}
                      inputProps={{ maxLength: 25 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id='industry'
                      value={industry || ''}
                      name='industry'
                      select
                      label='Industry'
                      onChange={handleIndustryChange}
                      variant='outlined'
                      fullWidth
                      // helperText={industryError ? industryErrorMessage : null}
                      // error={industryError}
                      inputProps={{ maxLength: 50 }}
                    >
                      {industryList.map((option, key) => (
                        <MenuItem key={key} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant='outlined'
                      value={companyName || ''}
                      fullWidth
                      id='company_name'
                      label='Company Name'
                      name='company_name'
                      autoComplete='company name'
                      onChange={handleCompanyChange}
                      // helperText={companyNameError ? companyNameErrorMessage : null}
                      // error={companyNameError}
                      inputProps={{ maxLength: 100 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant='outlined'
                      value={howDidYouHearAboutUs}
                      fullWidth
                      id='how_did_you_hear_about_us'
                      label='How Did You Hear About Us'
                      name='how_did_you_hear_about_us'
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl component='fieldset'>
                      <FormLabel component='legend'>Subscription Details</FormLabel>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>stripe client id</TableCell>
                            <TableCell>{stripeCustomerId}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>stripe subscription id</TableCell>
                            <TableCell>{stripeSubscriptionId}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>stripe subscription itme id</TableCell>
                            <TableCell>{stripeSubscriptionItemId}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>stripe payment method id</TableCell>
                            <TableCell>{stripePaymentMethodId}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>last usage sent to stripe (sent once a day) </TableCell>
                            <TableCell>{stripeLastUsageSent}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </FormControl>
                  </Grid>
                </Grid>
                <Button type='submit' fullWidth variant='contained' color='primary' className={classes.submit}>
                  Save Account Changes
                </Button>
              </form>
            </Grid>
          </LoadingOverlay>
        </Grid>
      </Paper>
    </Container>
  )
}

export default AdminAccountEdit
