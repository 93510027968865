import { makeStyles } from '@material-ui/core/styles'
import { Container, IconButton, Grid, Link } from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import InstagramIcon from '@material-ui/icons/Instagram'
import Copyright from './Copyright'

const footerStyles = makeStyles(() => ({
  footerLinks: {
    marginLeft: '5px',
    marginRight: '5px'
  }
}))

const Footer = () => {
  const classes = footerStyles()
  return (
    <Container>
      <Grid item xs={12}>
        <IconButton onClick={() => window.open('https://www.facebook.com/Def-Logix-Inc-154509951272705/', '_blank')}>
          <FacebookIcon style={{ color: '#495C7B' }} />
        </IconButton>
        <IconButton onClick={() => window.open('https://twitter.com/DefLogix', '_blank')}>
          <TwitterIcon style={{ color: '#495C7B' }} />
        </IconButton>
        <IconButton onClick={() => window.open('https://www.instagram.com/deflogixinc/', '_blank')}>
          <InstagramIcon style={{ color: '#495C7B' }} />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Link className={classes.footerLinks} color='inherit' href='https://def-logix.com/legal-2/'>
          User Agreement - Privacy Policy
        </Link>
      </Grid>
      <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex', flexWrap: 'wrap' }}>
        <Link className={classes.footerLinks} color='inherit' href='http://j.brt.mv/ATS/ax.do?portalGK=22836'>
          Careers
        </Link>|
        <Link
          className={classes.footerLinks}
          color='inherit'
          href='https://def-logix.com/commercial-about/'
        >
          Commercial
        </Link>|
        <Link
          className={classes.footerLinks}
          color='inherit'
          href='https://def-logix.com/government-about/'
        >
          Government
        </Link>|
        <Link className={classes.footerLinks} color='inherit' href='https://def-logix.com/newsletter/'>
          Newsletter
        </Link>|
        <Link className={classes.footerLinks} color='inherit' href='https://def-logix.com/media/'>Media</Link>|
        <Link className={classes.footerLinks} color='inherit' href='https://def-logix.com/contact-us/'>
          Contact Us
        </Link>|
        <Link className={classes.footerLinks} color='inherit' href='https://def-logix.com/security-assessment-tool'>
          Def-Logix™ Security Manager
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Copyright />
      </Grid>
    </Container>
  )
}

export default Footer
