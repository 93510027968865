import React, { useEffect, useState } from 'react'
import { Container, Grid, TableContainer, Paper, Typography, Button } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'

import fetchData from '../../utility/endpointFetch'
import PageTitle from '../PageTitle'
import { getAppStyles } from '../App/AppStyles'
import { keysHistoryColumns } from './KeysColumns'

const historyOptions = {
  filter: true,
  filterType: 'dropdown',
  responsive: 'vertical',
  selectableRows: 'none'
}

const tableTitle = (pathname, history) => (
  <Typography align='left'>
    <Button
      onClick={() => history.push(pathname.includes('admin') ? '/admin/licenses' : '/licenses')}
      startIcon={<FontAwesomeIcon icon={faChevronCircleLeft} style={{ color: '#010C80' }} />}
    >
      Return to Account Licenses
    </Button>
  </Typography>
)

const KeyHistory = () => {
  const classes = getAppStyles()
  const { key } = useParams()
  const history = useHistory()
  const { pathname } = useLocation()
  const [isLoading, setIsLoading] = useState(true)
  const [keyHistory, setKeyHistory] = useState([])

  useEffect(() => {
    const { promise, cancel } = fetchData(`/licenses/history/${key}`, 'Error retrieving history attached to key')
    ;(async () => {
      const response = await promise
      setKeyHistory(response.key_history)
      // setKeyDetails(response.key_details)
      setIsLoading(false)
    })()
    return cancel
  }, [key])

  return (
    <Container maxWidth='xl' className={classes.container}>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <PageTitle title='License Key History' />
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading Users...'
                fadeSpeed={200}
              >
                <MUIDataTable
                  title={tableTitle(pathname, history)}
                  data={keyHistory}
                  columns={keysHistoryColumns}
                  options={historyOptions}
                />
              </LoadingOverlay>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}

export default KeyHistory
