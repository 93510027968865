import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Paper, Button, Typography, Grid, Container, TextField } from '@material-ui/core'

import fetchData from '../../utility/endpointFetch'
import hofs from '../../utility/hofs'
import { getAppStyles } from '../App/AppStyles'

const firstNameErrorMessage = 'First name is required'
const lastNameErrorMessage = 'Last name is required'
const emailErrorMessageBasic = 'A valid Email address is required'
const emailErrorMessageDuplicate = 'That email already exists within our system.  Please try another'
const passwordErrorMessage = 'password is required must be a minimum of 8 and match password verifier'

const UsersNew = () => {
  const classes = getAppStyles()
  const history = useHistory()

  const [firstName, setFirstName] = useState()
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastName, setLastName] = useState()
  const [lastNameError, setLastNameError] = useState(false)
  const [email, setEmail] = useState()
  const [emailError, setEmailError] = useState(false)
  const [emailErrorMessage, setEmailErrorMessage] = useState(emailErrorMessageBasic)
  const [password, setPassword] = useState()
  const [passwordVerifier, setPasswordVerifier] = useState()
  const [passwordError, setPasswordError] = useState(false)

  const handleFirstNameChange = e => setFirstName(e.target.value)
  const handleLastNameChange = e => setLastName(e.target.value)
  const handleEmailChange = e => setEmail(e.target.value)
  const handlePasswordChange = e => setPassword(e.target.value)
  const handlePasswordVerifierChange = e => setPasswordVerifier(e.target.value)

  const handleNewUserSubmit = e => {
    e.preventDefault()
    let errorCount = 0
    setEmailErrorMessage('')

    if (hofs.isPopulated(firstName)) {
      setFirstNameError(false)
    } else {
      errorCount++
      setFirstNameError(true)
    }

    if (hofs.isPopulated(lastName)) {
      setLastNameError(false)
    } else {
      errorCount++
      setLastNameError(true)
    }

    if (hofs.isPopulated(email)) {
      if (hofs.validateEmail(email)) {
        setEmailError(false)
      } else {
        errorCount++
        setEmailErrorMessage(emailErrorMessageBasic)
        setEmailError(true)
      }
    } else {
      errorCount++
      setEmailError(true)
    }

    if (hofs.isPopulated(password)) {
      if (password.length < 8) {
        setPasswordVerifier('')
        setPasswordError(true)
        errorCount++
      } else {
        if (password !== passwordVerifier) {
          setPasswordError(true)
          errorCount++
          setPasswordVerifier('')
        } else {
          setPasswordError(false)
        }
      }
    } else {
      errorCount++
      setPasswordError(true)
    }

    if (errorCount === 0) {
      // there are no errors...send the data to backend to complete registration
      const errorMessage = 'Error creating your user.  Please contact support to do so.'
      const fetchParams = {
        method: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          email,
          password
        })
      }
      ;(async () => {
        const response = await fetchData('/users/', errorMessage, fetchParams).promise
        if (response.duplicate_email) {
          // this email already exists in our system
          setEmailError(true)
          setEmailErrorMessage(emailErrorMessageDuplicate)
        } else {
          // insertion of new user successful
          history.push('/users/')
        }
      })()
    }
  }

  return (
    <Container maxWidth='xl' className={classes.container}>
      <Paper className={classes.paper}>
        <Typography component='h1' variant='h5'>
          Add New User To Your Account
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleNewUserSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name='first_name'
                variant='outlined'
                required
                fullWidth={true}
                value={firstName}
                id='firstName'
                label='First Name'
                onChange={handleFirstNameChange}
                helperText={firstNameError ? firstNameErrorMessage : null}
                error={firstNameError}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant='outlined'
                required
                fullWidth
                value={lastName}
                id='lastName'
                label='Last Name'
                name='last_name'
                autoComplete='lname'
                onChange={handleLastNameChange}
                helperText={lastNameError ? lastNameErrorMessage : null}
                error={lastNameError}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                value={email}
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                onChange={handleEmailChange}
                helperText={emailError ? emailErrorMessage : null}
                error={emailError}
                inputProps={{ maxLength: 255 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                type='password'
                value={password}
                required
                fullWidth
                id='password'
                label='Password'
                name='password'
                autoComplete='password'
                onChange={handlePasswordChange}
                helperText={passwordError ? passwordErrorMessage : null}
                error={passwordError}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                type='password'
                value={passwordVerifier}
                required
                fullWidth
                id='password_verifier'
                label='Password Verifier'
                name='password_verifier'
                autoComplete='password'
                onChange={handlePasswordVerifierChange}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>
          <Button type='submit' variant='contained' color='primary' className={classes.submit}>
            Add User
          </Button>
        </form>
      </Paper>
    </Container>
  )
}

export default UsersNew
