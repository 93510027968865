import { Typography, TableContainer, Table, TableRow, TableCell, TableBody, Paper, Button } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { useHistory } from 'react-router-dom'

import { getLoginStyles } from './LoginStyles'

const ResetMessage = () => {
  const history = useHistory()
  const classes = getLoginStyles()
  return (
    <TableContainer component={Paper} style={{ marginTop: '20px' }}>
      <Table className={classes.table} aria-label='simple table'>
        <TableBody>
          <TableRow key='some_key'>
            <TableCell>
              <CheckCircleIcon fontSize='large' className={classes.checkicon} />
            </TableCell>
            <TableCell>
              <Typography>
                An email has been sent that includes instructions on reseting your password.
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} align="center">
              <Button
                variant='contained'
                style={{ backgroundColor: '#128C01', color: '#FFFFFF', maxWidth: '300px', marginTop: '10px' }}
                size='small'
                onClick={() => {
                  history.push('/login')
                }}
              >
                Return To Login
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

    </TableContainer>
  )
}

export default ResetMessage
