import React, { useState } from 'react'
import { Tooltip, IconButton } from '@material-ui/core'
import { ArrowDropDown, ArrowRight, Info } from '@material-ui/icons'

import { getHierarchyStyles } from './HierarchyStyles'
import LeafRow from './LeafRow'

const handleCategoryInfoClick = (category, setCategoryInfoOpen, setCategoryRow) => () => {
  setCategoryRow(category)
  setCategoryInfoOpen(true)
}

const HierarchyRow = props => {
  const {
    selected,
    setSelected,
    leafRows,
    level,
    hiddenFromAbove,
    category,
    setCategoryRow,
    setCategoryInfoOpen
  } = props
  const [show, setShow] = useState(!hiddenFromAbove)
  const classes = getHierarchyStyles()
  return (
    <div className={classes.HierarchyRow__base} key={category.id}>
      <Tooltip title={show ? 'Collapse' : 'Expand'}>
        <IconButton size='small' aria-label={`Collapse ${category.name}`} onClick={() => setShow(!show)}>
          {show ? <ArrowDropDown /> : <ArrowRight />}
        </IconButton>
      </Tooltip>
      <strong>
        {category.name}
        <Info
          onClick={handleCategoryInfoClick(category, setCategoryInfoOpen, setCategoryRow)}
          className={classes.HierarchyRow__infoLink}
        />
      </strong>
      <span>{category.notes && ` - ${category.notes}`}</span>
      <div style={{ display: show ? 'inherit' : 'none' }}>
        {category.subCategories.length
          ? category.subCategories
              .map(s => (
                <HierarchyRow
                  key={s.id}
                  {...{ selected, setSelected, leafRows, setCategoryRow, setCategoryInfoOpen }}
                  category={s}
                  level={level + 1}
                  hiddenFromAbove={!show}
                />
              ))
          : leafRows
              .filter(r => r.group_id === category.id)
              .map(row => <LeafRow key={row.secid} {...{row, selected, setSelected, show}} />)
        }
      </div>
    </div>
  )
}

export default HierarchyRow
