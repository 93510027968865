import { Tooltip, IconButton } from '@material-ui/core'
import { History } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import hofs from '../../../utility/hofs'

import KeyState from './KeyState'

const columns = (setLoading, setReloadData) => [
  {
    name: 'key_id',
    label: '',
    options: {
      display: false,
      filter: false,
      sort: false,
      viewColumns: false,
      searchable: false
    }
  }, {
    name: 'key',
    label: 'Key',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, {
    name: 'key_description',
    label: 'Key Label',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true
    }
  }, {
    name: 'company_name',
    label: 'Company ',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: value => hofs.isPopulated(value) ? value : <div>nothing</div>
    }
  }, {
    name: 'created_at',
    label: 'Created',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: value => <div>{new Date(value).toLocaleDateString('en-US')}</div>
    }
  }, /* {
    name: 'expiration_date',
    label: 'Expires',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: value => <div>{new Date(value).toLocaleDateString('en-US')}</div>
    }
  },*/
  {
    name: 'disabled',
    label: 'Active',
    options: {
      filter: true,
      sort: true,
      sortThirdClickReset: true,
      customBodyRender: (value, tableMeta) => (
        <KeyState
          value={value}
          localTableMeta={tableMeta}
          setLoading={setLoading}
          setReloadData={setReloadData}
        />
      )
    }
  }, {
    name: '',
    label: 'History',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <div>
            <Tooltip title='License History'>
              <IconButton
                size='small'
                aria-label='License History'
                component={Link}
                to={`/admin/licenses/history/${tableMeta.rowData[0]}`}
              >
                <History style={{ color: '#0275E8' }} />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    }
  }
]

export default columns
