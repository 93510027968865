import React from "react";
import { Button, Typography } from '@material-ui/core'

const ShowTOTP = ({ totpProvisioningURI, handleTOTPChange }) => {

  var totpButtonText = "Turn Off TOTP Use"
  var instruction_text = "Scan the QR code into your TOTP authentication app like Google Authenticator or Authy.  You will find the time-based password in that for use on your next login."
  if ((totpProvisioningURI == "") || (totpProvisioningURI == undefined)) {
    //this feature is off...show text to turn it back on
    var totpButtonText = "Turn ON TOTP Use"
    instruction_text = ''
  }

  return (
    <>
    <Button size="small" style={{ whiteSpace: 'nowrap' }}variant="contained" color="primary" onClick={ () => handleTOTPChange()}>{totpButtonText}</Button><br />
    <Typography variant="caption">
      {instruction_text}
    </Typography>
    </>
  )
}

export default ShowTOTP