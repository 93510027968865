import React from 'react'
import PropTypes from 'prop-types'

import { getAppStyles } from '../App/AppStyles'

const BooleanFormControl = ({ formValuesKey, title, onChange, value }) => {
  const classes = getAppStyles()
  return (
    <div className={classes.booleanFormControl}>
      <label style={{ verticalAlign: 'top', display: 'inline-block' }}>{title}:</label>
      <div
        className={classes.booleanFormControl__background}
        style={{ background: value && 'green' }}
        onClick={onChange}
      >
        <div
          className={classes.booleanFormControl__switch}
          style={{ left: value ? 23 : 0 }}
        />
        <input type='checkbox' hidden readOnly name={formValuesKey} value={value || false} />
      </div>
    </div>
  )
}

BooleanFormControl.propTypes = {
  formValuesKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired
}

export default BooleanFormControl
