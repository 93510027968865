import React, { useEffect, useState } from 'react'
import { Typography, Container, Grid, CircularProgress, Paper, Tooltip, IconButton } from '@material-ui/core'
import { Computer, VpnKey, FileCopy } from '@material-ui/icons'

import fetchData from '../../utility/endpointFetch'
import hofs from '../../utility/hofs'
import { dashboardStyles } from '../Dashboard/DashboardStyles'
import SecurityReport from '../SecurityReport/SecurityReport'
import Footer from '../Footer'

const copyToClipboard = newLicenseKey => () => {
  navigator.clipboard.writeText(newLicenseKey)
  alert('Copied the license key to your clipboard')
}

const DashboardUnpaid = ({ pageTitle }) => {
  const classes = dashboardStyles()

  const [loading, setLoading] = useState(true)
  const [accountLicense, setAccountLicense] = useState('')
  const [accountMachine, setAccountMachine] = useState('')
  const [machineID, setMachineID] = useState(0)

  useEffect(() => {
    setLoading(true)
    const { promise, cancel } = fetchData('/account/unpaid', 'Error retrieving information for unpaid account')
    ;(async () => {
      const response = await promise
      setAccountMachine(response.machine ? response.machine : 'none inserted yet')
      setAccountLicense(response.license ? response.license : 'none found')
      setMachineID(hofs.isPopulated(response.machine) ? response.machine_id : 0)
      setLoading(false)
    })()
    return cancel
  }, [])

  return (
    <>
      <Container maxWidth='xl' className={classes.container}>
        <Paper className={classes.paper}>
          <Typography align='left' variant='h5' className={classes.contentTitle}>
            {pageTitle}
          </Typography>
          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={12} md={6}>
              <Paper className={classes.unpaidDashboardSection}>
                <div className={classes.containerLabel}>
                  <VpnKey className={classes.iconAndSpacer} />
                  License Key
                </div>
                <Typography align='right' variant='h6' className={classes.infoDisplay}>
                  {loading
                    ? <CircularProgress size={20} />
                    : (
                      <span onClick={copyToClipboard(accountLicense)} style={{ cursor: 'pointer' }} >
                        <Tooltip title='Click to copy license key'>
                          <IconButton size='small' aria-label='License key copy' style={{ fontSize: '1.5rem', fontWeight: "600" }}>
                            {accountLicense}
                            <FileCopy color='primary' style={{ fontSize: '1.2rem', marginLeft: '4px' }} />
                          </IconButton>
                        </Tooltip>
                      </span>
                    )
                  }
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper className={classes.unpaidDashboardSection}>
                <div className={classes.containerLabel}>
                  <Computer className={classes.iconAndSpacer} />
                  Machine
                </div>
                <Typography align='right' variant='h6' className={classes.infoDisplay}>
                  {loading ? <CircularProgress size={20} /> : accountMachine}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} style={{ minHeight: 300 }}>
              {machineID
                ? <SecurityReport machineId={machineID} />
                : <Paper className={classes.unpaidDashboardSection}>
                    <Typography align='right' variant='h6' className={classes.infoDisplay}>
                      {loading
                        ? <CircularProgress size={20} />
                        : 'There is no machine to attached to your account to generate report.  Add a machine to your account to view the Security Report based on it'
                      }
                    </Typography>
                  </Paper>
              }
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Footer />
    </>
  )
}

export default DashboardUnpaid
