import React, { useState } from 'react'
import { Container, Grid, TextField, Paper, Typography, Button, Modal, Backdrop, InputAdornment, Fade, IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import PasswordStrengthBar from 'react-password-strength-bar'

import fetchData from '../../../utility/endpointFetch'
import { getAppStyles } from '../../App/AppStyles'
import hofs from '../../../utility/hofs'

const passwordErrorMessage = 'To change the admin password both password and password verifier fields must be completed with a minimum password strength score of 3.'

const AdminConfigurations = () => {
  const classes = getAppStyles()
  const [modalOpen,setModalOpen] = useState(false)
  const [password,setPassword] = useState('')
  const [passwordError,setPasswordError] = useState('')
  const [passwordVerifier,setPasswordVerifier] = useState('')
  const [passwordVerifierError, setPasswordVerifierError] = useState(false)
  const [passwordVerifierErrorMessage, setPasswordVerifierErrorMessage] = useState('')
  const [passwordScore, setPasswordScore] = useState(0);
  const [modalMessage,setModalMessage] = useState('') //the only functionality on this page so far...change when add more

  const handleMouseDownPassword = e => e.preventDefault()
  const handleMouseDownPasswordVerifier = e => e.preventDefault()
  const handlePasswordChange = e => setPassword(e.target.value)
  const handlePasswordVerifierChange = e => setPasswordVerifier(e.target.value)

  const submitAdminPassword = (e) => {

    e.preventDefault()
    let errorCount = 0

    if ((hofs.isPopulated(password)) || (hofs.isPopulated(passwordVerifier))) {
      // pw and/or verifier is not empty....client wishes to change
      if (hofs.isPopulated(password)) {
        setPasswordError(false)
      } else {
        errorCount++
        setPasswordError(true)
      }

      if (passwordScore < 3) {
        //we want a stronger password than that
        errorCount++
        setPasswordError(true)
      } else {
        if (hofs.isPopulated(password)) {
          setPasswordError(false)
        } else {
          errorCount++
          setPasswordError(true)
        }
      }

      if (hofs.isPopulated(passwordVerifier)) {
        if (passwordVerifier === password) {
          setPasswordVerifierError(false)
          setPasswordVerifierErrorMessage('')
        } else {
          errorCount++
          setPasswordVerifierError(true)
          setPasswordVerifierErrorMessage('Your password verifier must match the password inserted above.  Please check and make sure both match')
        }
      } else {
        errorCount++
        setPasswordVerifierError(true)
        setPasswordVerifierErrorMessage('You must insert a password verifier that matches your password to complete submission')
      }
    }
    if (errorCount === 0) {

      const errorMessage = 'Error retrieving invitation codes for admin users'
      const fetchParams = {
        method: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          admin_password: password,
        })
      }
      ;(async () => {
        const response = await fetchData('/users/adminpassword', errorMessage, fetchParams).promise
        if (response.admin_email_updated) {
          // admin password updated
          setModalMessage('Admin password has been reset')
          setModalOpen(true)

        } else {
          setModalMessage('There was an error saving the admin password...please check and try again')
          setModalOpen(true)

        }
      })()
    }
  }

  const handleModalClose = () => {
    setModalMessage('')
    setModalOpen(false)
  }

  const [valuesPassword, setValuesPassword] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false
  })

  const [valuesPasswordVerifier, setValuesPasswordVerifier] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPasswordVerifier: false
  })

  const handleClickShowPassword = () =>
  setValuesPassword({ ...valuesPassword, showPassword: !valuesPassword.showPassword })

  const handleClickShowPasswordVerifier = () =>
    setValuesPasswordVerifier({
      ...valuesPasswordVerifier,
      showPasswordVerifier: !valuesPasswordVerifier.showPasswordVerifier
    })

  return (
    <Container maxWidth='xl' className={classes.container}>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography align='left' variant='h5' style={{ fontWeight: '700' }}>
              Configurations
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper variant="outlined" elevation={3} className={classes.paperBoxes}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <Typography align='left' color='inherit'>
                      Admin Password Change
                    </Typography>
                    <TextField
                      variant='outlined'
                      type={valuesPassword.showPassword ? 'text' : 'password'}
                      required
                      fullWidth
                      id='password'
                      label='Password'
                      name='password'
                      autoComplete='password'
                      onChange={handlePasswordChange}
                      helperText={passwordError ? passwordErrorMessage : null}
                      error={passwordError}
                      inputProps={{ maxLength: 100 }}
                      InputProps={{
                        endAdornment:
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {valuesPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                      }}
                    />
                    <PasswordStrengthBar
                      password={password}
                      onChangeScore={(score,feedback) => {
                          setPasswordScore(score)
                        }
                      }
                    />

                    <TextField
                      variant='outlined'
                      type={valuesPasswordVerifier.showPasswordVerifier ? 'text' : 'password'}
                      required
                      fullWidth
                      id='password_verifier'
                      label='Password Verifier'
                      name='password_verifier'
                      autoComplete='password'
                      onChange={handlePasswordVerifierChange}
                      helperText={passwordVerifierError ? passwordVerifierErrorMessage : null}
                      error={passwordVerifierError}
                      inputProps={{ maxLength: 100 }}
                      InputProps={{
                        endAdornment:
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password verifier visibility'
                              onClick={handleClickShowPasswordVerifier}
                              onMouseDown={handleMouseDownPasswordVerifier}
                            >
                              {valuesPasswordVerifier.showPasswordVerifier ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                      }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    className={classes.submit}
                    onClick={submitAdminPassword}
                  >
                    Save Admin Password Changes
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
      <Modal
        aria-labelledby='spring-modal-title'
        aria-describedby='spring-modal-description'
        className={classes.modal}
        open={modalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={modalOpen}>
          <div className={classes.modalPaper}>
            <h2 id='spring-modal-title'>Configurations</h2>
            <p id='spring-modal-description' style={{ fontSize: '1.2rem' }}>
              {modalMessage}<br/><br/>
            </p>
            <Button
                variant='contained'
                style={{ backgroundColor: '#128C01', color: '#FFFFFF', maxWidth: '300px', marginTop: '10px' }}
                size='small'
                onClick={() => handleModalClose()}
              >
                close
              </Button>
          </div>
        </Fade>
      </Modal>
    </Container>
  )
}

export default AdminConfigurations
