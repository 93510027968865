import React, { useEffect, useState } from 'react'
import { Container, Grid, TableContainer, Button, Typography, Paper } from '@material-ui/core'
import { PersonAdd } from '@material-ui/icons'
import MUIDataTable from 'mui-datatables'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'
import { NavLink } from 'react-router-dom'
import hofs from '../../utility/hofs'
import UserExpandableRowDisplay from './UserExpandableRowDisplay'

import fetchData from '../../utility/endpointFetch'
import Footer from '../Footer'
import { activeUserColumns } from './UsersColumns'
import { getAppStyles } from '../App/AppStyles'


const userOptions = (accountUsers, setReloadData, currentRole) => ({
  filter: true,
  filterType: 'dropdown',
  responsive: 'vertical',
  selectableRows: 'none',
  expandableRows: true,
  expandableRowsHeader: false,
  expandableRowsOnClick: true,
  renderExpandableRow: (rowData, rowMeta) => {
    if (currentRole === 'client_admin')
      return <UserExpandableRowDisplay reloadUserList={setReloadData} userRowDetails={accountUsers[rowMeta.dataIndex]} />
    else
      return null;
  },
  onRowClick: (rowData, rowState) => {
    // link to key history?
    //log('top of onRowClick in keys enabled')
    //console.log(rowData, rowState)
  }
})

const UsersList = () =>  {
  const classes = getAppStyles()
  const currentRole = hofs.getRole()

  const [isLoading, setIsLoading] = useState(true)
  const [accountUsers, setAccountUsers] = useState([])

  // This is simply to trigger a reload of data.
  // The value itself does not matter, only that it reruns the fetch effect when changed
  const [reloadData, setReloadData] = useState({})

  useEffect(() => {
    setIsLoading(true)
    const { promise, cancel } = fetchData('/users/', 'Error retrieving users attached to account')
    ;(async () => {
      const response = await promise
      setAccountUsers(response)
      //setAccountUsersDisabled(response[1])
      setIsLoading(false)
    })()
    return cancel
  }, [reloadData])

  return (
    <>
      <Container maxWidth='xl' className={classes.container}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography align='left' variant='h5' style={{ fontWeight: '700' }}>Account Users</Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TableContainer component={Paper}>
                <LoadingOverlay
                  active={isLoading}
                  spinner
                  text='Loading Users...'
                  fadeSpeed={200}
                >
                  <MUIDataTable
                    title=''
                    data={accountUsers}
                    columns={activeUserColumns(setIsLoading, setReloadData)}
                    options={userOptions(accountUsers, setReloadData, currentRole)}
                  />
                </LoadingOverlay>
              </TableContainer>
              { currentRole === 'client_admin' ?
                <Button
                  variant='contained'
                  style={{ backgroundColor: '#128C01', color: '#FFFFFF', maxWidth: '300px', marginTop: '10px' }}
                  size='small'
                  component={NavLink}
                  className={classes.button}
                  href='/users/new'
                  to='/users/new'
                  startIcon={<PersonAdd />}
                >
                  add new user
                </Button>
              : null }
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Footer />
    </>
  )
}

export default UsersList
