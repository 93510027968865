import React from 'react'
import { Tooltip, IconButton } from '@material-ui/core'
import { AddCircle, Delete } from '@material-ui/icons'

import hofs from '../../utility/hofs'
import fetchData from '../../utility/endpointFetch'
import { getAppStyles } from '../App/AppStyles'

const handleMachineDisable = (setLoading, setReloadData, machineId) => () => {
  setLoading(true)
  const fetchURL = `/machines/machine/disable/${machineId}`
  ;(async () => {
    const response = await fetchData(fetchURL, 'Error disabling machine attached to account').promise
    if (response.machine_disabled) {
      // successfully disabled this machine
      setReloadData({})
    }
  })()
}

const handleMachineEnable = (setLoading, setReloadData, machineId) => () => {
  setLoading(true)
  const fetchURL = `/machines/machine/enable/${machineId}`
  ;(async () => {
    const response = await fetchData(fetchURL, 'Error enabling machine attached to account').promise
    if (response.machine_enabled) {
      // successfully enabled this machine
      setReloadData({})
    }
  })()
}

const MachineActions = ({ enabled, machineId, setLoading, setReloadData }) => {
  const classes = getAppStyles()
  const currentRole = hofs.getRole()
  return (
    <div className={classes.datatableCellAlignRight}>
      {/*<Tooltip
        title='Machine History'
        aria-label='History'
        component={Link}
        to={`/machines/history/${machineId}`}
      >
        <IconButton size='small' aria-label='Machine History'>
          <History style={{ color: '#0275E8' }} />
        </IconButton>
      </Tooltip>*/}
      { currentRole === "client_admin" ?
        enabled ?
          <Tooltip title='Suspend Machine'>
            <IconButton
              onClick={handleMachineDisable(setLoading, setReloadData, machineId)}
              size='small'
              aria-label='Suspend Machine'
            >
              <Delete style={{ fontSize: '1.2rem', color: '#E81202' }} />
            </IconButton>
          </Tooltip>
          :
          <Tooltip title='Enable Machine'>
            <IconButton
              onClick={handleMachineEnable(setLoading, setReloadData, machineId)}
              size='small'
              aria-label='Enable Machine'
            >
              <AddCircle style={{ fontSize: '1.2rem', color: '#038231' }} />
            </IconButton>
          </Tooltip>
        : null }
      {/*
      <Tooltip
        title='Security Report Details'
        aria-label='Security Report Details'
        component={Link}
        to={`/machines/details/${machineId}`}
      >
        <IconButton size='small' aria-label='Security Report Details'>
          <Security style={{ color: '#0275E8' }} />
        </IconButton>
      </Tooltip>*/}
    </div>
  )
}

export default MachineActions
