import React, { useEffect, useState } from 'react'
import { Table, TableRow, TableCell, Grid, Paper, Button, Tooltip } from '@material-ui/core'
import { History, Security } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'

import fetchData from '../../utility/endpointFetch'
import MachineRiskDial from './MachineRiskDial'
import { getAppStyles } from '../App/AppStyles'

const MachineExpandableRowDisplay = ({ machineRowDetails }) => {
  const history = useHistory()
  const classes = getAppStyles()

  const [machineDetails, setMachineDetails] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [securityRiskScore, setSecurityRiskScore] = useState(0)
  const [firstCoveredDate, setFirstCoveredDate] = useState('no date')

  useEffect(() => {
    const fetchURL = `/machines/${machineRowDetails.machine_id}`
    const { promise, cancel } = fetchData(fetchURL, 'Error retrieving details attached to machine')
    ;(async () => {
      const response = await promise
      if (response.first_covered !== null) setFirstCoveredDate(response.first_covered)
      setMachineDetails(response)
      setSecurityRiskScore(response.security_score / 100)
      setIsLoading(false)
    })()
    return cancel
  }, [machineRowDetails.machine_id])

  return (
    <TableRow className={classes.tableRow}>
      <TableCell colSpan={7}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={3} lx={3}>
            <div className={classes.chartContainer}>
              <div className={classes.containerLabel}>Security Score</div>
              <Paper className={classes.machineExpandableSection}>
                <div className={classes.expandableRiskDial}>
                  <MachineRiskDial
                    isLoading={isLoading}
                    last_report_date={machineRowDetails.last_report_date}
                    securityRiskScore={securityRiskScore}
                  />
                  <div className={classes.riskDialText}>Score for this machine</div>
                </div>
              </Paper>
            </div>
          </Grid>
          <Grid item xs={12} md={7} lg={7} lx={7}>
            <div className={classes.chartContainer}>
              <LoadingOverlay active={isLoading} spinner text='Loading Machines Details...' fadeSpeed={200}>
                <div className={classes.containerLabel}>Stats</div>
                {isLoading ? (
                  <div style={{ minHeight: '100px' }} />
                ) : (
                  <div className={classes.statsContainer}>
                    <Paper className={classes.machineExpandableSection}>
                      <Table size='small'>
                        <TableRow>
                          <TableCell style={{ borderBottom: 'none' }} align='right'>
                            Added:
                          </TableCell>
                          <TableCell style={{ borderBottom: 'none' }} align='left'>
                            {firstCoveredDate}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ borderBottom: 'none' }} align='right'>
                            Last Security Report Received:
                          </TableCell>
                          <TableCell style={{ borderBottom: 'none' }} align='left'>
                            {machineRowDetails.last_report_date === 0
                              ? 'none received yet'
                              : machineRowDetails.last_report_date}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ borderBottom: 'none' }} align='right'>
                            License:
                          </TableCell>
                          <TableCell style={{ borderBottom: 'none' }} align='left'>
                            {machineDetails.license_details.key}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ borderBottom: 'none' }} align='right'>
                            Unique Internal ID:
                          </TableCell>
                          <TableCell style={{ borderBottom: 'none' }} align='left'>
                            {machineRowDetails.client_machine_id}
                          </TableCell>
                        </TableRow>
                      </Table>
                    </Paper>
                  </div>
                )}
              </LoadingOverlay>
            </div>
          </Grid>
          <Grid item xs={12} md={2} lg={2} lx={2}>
            <div className={classes.chartContainer}>
              <div className={classes.containerLabel}>Actions</div>
              <Paper className={classes.machineExpandableSection}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12} lx={12} className={classes.actionBox}>
                    <Button
                      variant='contained'
                      color='primary'
                      size='small'
                      className={classes.button}
                      startIcon={<History />}
                      onClick={() => history.push(`/machines/history/${machineRowDetails.machine_id}`)}
                    >
                      View History
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} lx={12} className={classes.actionBox}>
                    {machineDetails.active === 1 ? (
                      <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        className={classes.button}
                        startIcon={<Security />}
                        onClick={() => history.push(`/machines/details/${machineRowDetails.machine_id}`)}
                      >
                        Scan / Mitigate
                      </Button>
                    ) : (
                      <Tooltip title='Can only scan/mitigate on active/live machines'>
                        <span>
                          <Button
                            variant='contained'
                            color='primary'
                            size='small'
                            disabled={true}
                            className={classes.button}
                            startIcon={<Security />}
                            onClick={() => history.push(`/machines/details/${machineRowDetails.machine_id}`)}
                          >
                            Scan / Mitigate
                          </Button>
                        </span>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
}

export default MachineExpandableRowDisplay
