import React, { useState } from 'react'
import { Avatar, Button, Typography, TextField, Link, Grid, Container } from '@material-ui/core'

import hofs from '../../utility/hofs'
import ResetMessage from './ResetMessage'
import fetchData from '../../utility/endpointFetch'
import { getLoginStyles } from './LoginStyles'

const PasswordReset = () => {
  const classes = getLoginStyles()

  const [email, setEmail] = useState()
  const [emailError, setEmailError] = useState(false)
  const [displayResetMessage, setDisplayResetMessage] = useState(false)

  const handleEmailChange = e => {
    setEmailError(false)
    setEmail(e.target.value)
  }

  const handleResetSubmit = e => {
    e.preventDefault()

    // first is to check if email and password are filled in
    const isEmailPopulated = hofs.isPopulated(email)
    setEmailError(!isEmailPopulated)

    if (isEmailPopulated) {
      // there are no errors...send the data to backend to complete registration
      const fetchParams = {
        method: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      }

      ;(async () => {
        const response = await fetchData('/login/reset', 'Error reseting your password.', fetchParams).promise
        if (response.password_reset === true) {
          // email was sent if email existed
          setDisplayResetMessage(true)
        } else {
          //console.log('password reset response was corrupted or something')
          // lets still do the same
        }
      })()
    } else {
      //console.log('there were errors in the password reset submission')
    }
  }

  return (
    <Container component='main' maxWidth='xs'>
      <div className={classes.paper}>
        <Avatar className={classes.avatar} src='/def-logix-d-larger.png' />
        <Typography component='h1' variant='h5'>
          Password Reset
        </Typography>
        {displayResetMessage ? <ResetMessage /> :
          <form className={classes.form} noValidate onSubmit={handleResetSubmit}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              autoFocus
              error={emailError}
              onChange={handleEmailChange}
              inputProps={{ maxLength: 255 }}
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
            >
              Send Password Reset Email
            </Button>
            <Grid container>
              <Grid item>
                <Link href='/register' variant='body2'>
                  Don't have an account? Sign Up!
                </Link>
              </Grid>
            </Grid>
          </form>
        }
      </div>
    </Container>
  )
}

export default PasswordReset
