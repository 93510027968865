import React from 'react'
import { Avatar, Typography, Container } from '@material-ui/core'

import { getLoginStyles } from './LoginStyles'

const PasswordResetError = () => {
  const classes = getLoginStyles()
  return (
    <Container component='main' maxWidth='xs'>
      <div className={classes.paper}>
        <Avatar className={classes.avatar} src='/def-logix-d-larger.png' />
        <Typography component='h1' variant='h5'>
          Password Reset Error
        </Typography>
        <p>
          Password could not be reset with the reset token submitted.  Please go through the password reset process and try again in case there were communication issues.
        </p>
      </div>
    </Container>
  )
}

export default PasswordResetError
