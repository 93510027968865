import { makeStyles } from '@material-ui/core/styles'

const commonStyles = theme => ({
  paper: {
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  appBarShift: {
    width: '100%'
  },
  table: {
    paddingTop: theme.spacing(8),
  },
  tableCell: {
    borderBottom: 'none',
  },
  checkicon: {
    color: 'green'
  },
  reporticon: {
    color: 'red'
  },
  warningicon: {
    color: '#FACC37'
  },
  captchaimage: {
    marginTop: '15px',
  },
  captchaRefresh: {
    verticalAlign: 'bottom'
  },
  accordionSpacing: {
    padding: '15px',
    paddingTop: '50px'
  },
})

export const getLoginStyles = makeStyles(commonStyles)

export const loginErrorMessageDisplayStyles = makeStyles(theme => ({
  ...commonStyles(theme),
  table: {
    paddingTop: theme.spacing(3)
  }
}))
