import React, { useEffect, useState } from 'react'
import { Container, Grid, TableContainer, Paper, Typography, Button } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'

import fetchData from '../../utility/endpointFetch'
import PageTitle from '../PageTitle'
import { getAppStyles } from '../App/AppStyles'
import { userHistoryColumns } from './UsersColumns'

const usersHistoryOptions = {
  filter: true,
  filterType: 'dropdown',
  responsive: 'vertical',
  selectableRows: 'none'
}

const tableTitle = (pathname, history) => (
  <Typography align='left'>
    <Button
      onClick={() => history.push(pathname.includes('admin') ? '/admin/users' : '/users')}
      startIcon={<FontAwesomeIcon icon={faChevronCircleLeft} style={{ color: '#010C80' }} />}
    >
      Return to Users List
    </Button>
  </Typography>
)

const UserHistory = () => {
  const classes = getAppStyles()
  const { user } = useParams()
  const history = useHistory()
  const { pathname } = useLocation()
  const [isLoading, setIsLoading] = useState(true)
  const [userHistory, setUserHistory] = useState([])

  useEffect(() => {
    const { promise, cancel } = fetchData(`/users/history/${user}`, 'Error retrieving history attached to user')
    ;(async () => {
      const response = await promise
      setUserHistory(response.user_history)
      setIsLoading(false)
    })()
    return cancel
  }, [user])

  return (
    <Container maxWidth='xl' className={classes.container}>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <PageTitle title='User History' />
          <Grid item xs={12}>
            <LoadingOverlay
              active={isLoading}
              spinner
              text='Loading Users...'
              fadeSpeed={200}
            >
              <TableContainer component={Paper}>
                <MUIDataTable
                  title={tableTitle(pathname, history)}
                  data={userHistory}
                  columns={userHistoryColumns}
                  options={usersHistoryOptions}
                />
              </TableContainer>
            </LoadingOverlay>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}

export default UserHistory
