import { makeStyles } from '@material-ui/core/styles'

// The Admin Dashboard is currently under construction and unused.  This may be deprecated.
// Use getAppStyles from '/src/Components/App/AppStyles.js' instead!
export const adminDashboardStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  fixedHeight: {
    height: 240
  }
}))
