const categoryInfo = [
  {}, {
    title: 'Software Restriction [Group] Policy to detect, track execution of applications in a secure controlled environment',
    content: 'Software restriction policies provide administrators with a Group Policy-driven mechanism to identify software and control its ability to run on the local computer. These policies can be used to protect computers running Microsoft Windows operating systems (beginning with Windows Server 2003 and Windows XP Professional) against known conflicts and safeguard the computers against security threats such as malicious viruses and Trojan horse programs. You can also use software restriction policies to create a highly restricted configuration for computers, in which you allow only specifically identified applications to run. Software restriction policies are integrated with Microsoft Active Directory and Group Policy. You can also create software restriction policies on stand-alone computers. Software restriction policies are trust policies, which are regulations set by an administrator to restrict scripts and other code that is not fully trusted from running.The Software Restriction Policies extension to the Local Group Policy Editor provides a single user interface through which the settings for restricting the use of applications can be managed on the local computer or throughout a domain.'
  }, {
    title: 'Windows System Security',
    content: 'Software restriction policies provide administrators with a Group Policy-driven mechanism to identify software and control its ability to run on the local computer. These policies can be used to protect computers running Microsoft Windows operating systems (beginning with Windows Server 2003 and Windows XP Professional) against known conflicts and safeguard the computers against security threats such as malicious viruses and Trojan horse programs. You can also use software restriction policies to create a highly restricted configuration for computers, in which you allow only specifically identified applications to run. Software restriction policies are integrated with Microsoft Active Directory and Group Policy. You can also create software restriction policies on stand-alone computers. Software restriction policies are trust policies, which are regulations set by an administrator to restrict scripts and other code that is not fully trusted from running.The Software Restriction Policies extension to the Local Group Policy Editor provides a single user interface through which the settings for restricting the use of applications can be managed on the local computer or throughout a domain.'
  }, {
    title: 'System configuration for secure software installations',
    content: 'These configurations, when set securely, ensure that the system does not give too much privilege or elevate privilege of users that want to install software locally or remotely.'
  }, {
    title: 'System configuration related to Windows OS kernel',
    content: 'These configurations, when set securely, ensure that the system does not give too much privilege or elevate privilege of users that want to install software locally or remotely.'
  }, {
    title: 'System configuration related to Windows enterprise and server',
    content: 'These configurations pertain to hardening the enterprise and network perimeters and hence apply to Windows Enterprise and Server editions only.'
  }, {
    title: 'Network Security',
    content: 'This section focuses on the security, protection and privacy aspects of the network system and Windows response to it.'
  }, {
    title: 'Security configurations for LAN Manager',
    content: 'This section focuses on tightening security of the Local Area Network via controlled behavior of the LAN Manager.'
  }, {
    title: 'Security configurations for network logon activity',
    content: 'This section focuses on tightening security for remote logon attempts over the network.'
  }, {
    title: 'Windows Remote Management Service',
    content: 'Windows Remote Management (WinRM) is the Microsoft implementation of WS-Management Protocol, a standard Simple Object Access Protocol (SOAP)-based, firewall-friendly protocol that allows hardware and operating systems, from different vendors, to interoperate. The WS-Management protocol specification provides a common way for systems to access and exchange management information across an IT infrastructure.'
  }, {
    title: 'Windows Defender security settings for system-wide protection',
    content: 'Windows Defender is a shielding mechanism within the operating system to protect resourses, prevent malicious activity, close security gaps, monitor or audit the system.'
  }, {
    title: 'Windows Defender security settings in the system real-time.',
    content: 'Defender\'s security measures to prevent, detect and secure real-time system states.'
  }, {
    title: 'Global Windows Defender security settings',
    content: 'Security configuration for Defender\'s main features such as SmartScreen, Spynet auditing, removable storage activity and potentially unwanted applications'
  }, {
    title: 'Security settings pertaining to the Defender Exploit Guard feature that detects attack surfaces and protects the system',
    content: 'Security configuration for Defender\'s Exploit Guard feature which elevates the guard\'s protection level and provides room for attack surface rules'
  }, {
    title: 'Privacy Protection',
    content: 'Configuring Windows to protect privacy over network via telemetrics and other means.'
  }, {
    title: 'Privacy protection via system diagnostics',
    content: 'Configuring Windows to protect privacy over network by tracking activity via Windows telemetrics and other means.'
  }, {
    title: 'Windows Remote Management Client',
    content: 'Security configurations of the Windows Remote Management client component to harden the system against remote access vulnerabilities by requiring strong access protocols and encryption of data across the network.'
  }, {
    title: 'Windows Remote Management Service',
    content: 'Security configurations of the Windows Remote Management service to harden the system against remote access vulnerabilities by requiring strong access protocols and encryption of data across the network.'
  }
]

export default categoryInfo
