import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import PasswordStrengthBar from 'react-password-strength-bar'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'
import { Visibility, VisibilityOff, OpenInNew, ThumbUpAltSharp } from '@material-ui/icons'
import {
  Avatar,
  Backdrop,
  Button,
  Typography,
  TextField,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Link,
  Grid,
  Box,
  Container,
  FormControl,
  MenuItem,
  Modal,
  Fade,
  InputAdornment,
  IconButton
} from '@material-ui/core'

import fetchData from '../../utility/endpointFetch'
import { stateNameList } from '../../StaticData/stateNameList'
import { stateAbbreviationList } from '../../StaticData/stateAbbreviationList'
import { industryList } from '../../StaticData/industryList'
import hofs from '../../utility/hofs'
import { getRegisterStyles } from './RegisterStyles'

import { parse, isValid } from 'psl'
const firstNameErrorMessage = 'First name is required'
const lastNameErrorMessage = 'Last name is required'
const emailErrorMessageBasic = 'Email address is required'
const emailErrorMessageDuplicate = 'That email is already within our system.  Please try another'
const emailErrorTLD = 'The email domain you have inserted is not valid by the comprehensive list we have.  Please check it.  If you feel it is not in error continue'
const emailVerifierErrorMessageBasic = 'Email address and email address verifier must match'
const passwordErrorMessage = 'Account password is required and must have a password score of at least \'good\' according to the strength meter'

const RegisterOneUser = ( { setRegistrationStep } ) => {
  const classes = getRegisterStyles()

  // const [phoneNumberError, setPhoneNumberError] = useState()
  // const [industryError, setIndustryError] = useState(false)
  // const [companyNameError, setCompanyNameError] = useState(false)

  const [firstName, setFirstName] = useState()
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastName, setLastName] = useState()
  const [lastNameError, setLastNameError] = useState(false)
  const [industry, setIndustry] = useState('')
  const [companyName, setCompanyName] = useState()
  const [email, setEmail] = useState()
  const [emailError, setEmailError] = useState(false)
  const [emailErrorMessage, setEmailErrorMessage] = useState(emailErrorMessageBasic)
  const [emailVerifier, setEmailVerifier] = useState()
  const [emailVerifierError, setEmailVerifierError] = useState(false)
  const [emailVerifierErrorMessage, setEmailVerifierErrorMessage] = useState(emailVerifierErrorMessageBasic)
  const [password, setPassword] = useState()
  const [passwordError, setPasswordError] = useState(false)
  const [agreement, setAgreement] = useState(false)
  const [agreementError, setAgreementError] = useState(false)
  const [agreementErrorMessage, setAgreementErrorMessage] = useState(
    'If you wish to view our terms click on blue button above to view the terms.  You need to agree with our terms to continue registration'
  )

  const [howYouHearAboutUs,setHowYouHearAboutUs] = useState('')
  const [registrationError, setRegistrationError] = useState(false)
  const [passwordVerifier, setPasswordVerifier] = useState()
  const [passwordVerifierError, setPasswordVerifierError] = useState()
  const [passwordVerifierErrorMessage, setPasswordVerifierErrorMessage] = useState('')
  const [passwordScore, setPasswordScore] = useState(0);
  const [modalOpen, setModalOpen] = useState(true)
  const [modalVerifyOpen, setModalVerifyOpen ] = useState(false)

  const [isLoading,setIsLoading] = useState(true)

  const [valuesPassword, setValuesPassword] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false
  })

  const [valuesPasswordVerifier, setValuesPasswordVerifier] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPasswordVerifier: false
  })

  const handleClickShowPassword = () =>
    setValuesPassword({ ...valuesPassword, showPassword: !valuesPassword.showPassword })

  const handleClickShowPasswordVerifier = () =>
    setValuesPasswordVerifier({
      ...valuesPasswordVerifier,
      showPasswordVerifier: !valuesPasswordVerifier.showPasswordVerifier
    })

  const handleMouseDownPassword = e => e.preventDefault()
  const handleMouseDownPasswordVerifier = e => e.preventDefault()
  const handleFirstNameChange = e => setFirstName(e.target.value)
  const handleLastNameChange = e => setLastName(e.target.value)
  const handleIndustryChange = e => setIndustry(e.target.value)
  const handleCompanyChange = e => setCompanyName(e.target.value)
  const handleEmailChange = e => setEmail(e.target.value)
  const handleEmailVerifierChange = e => setEmailVerifier(e.target.value)
  const handlePasswordChange = e => setPassword(e.target.value)
  const handlePasswordVerifierChange = e => setPasswordVerifier(e.target.value)
  const handleAgreementChange = e => {

    setAgreement(true)
    setModalOpen(false)

  }

  const verifyClick = e => {
    setRegistrationStep(1)
    setModalVerifyOpen(false)
  }

  const handleHowYouHearAboutUs = e => setHowYouHearAboutUs(e.target.value)

  const validateEmailTLD = () => {
    //get just the domain part of the email
    let full_email = email.split("@")  // is there only one @?
    var parsed_domain = parse(full_email[1])

    //email_domain.tld should have tld
    var validTLD = isValid(full_email[1])

    if (!validTLD) {
      //this is not a valid tld
      setEmailError(true)
      setEmailErrorMessage(emailErrorTLD)
    } else {

    }
  }

  const handleRegisterOneUserSubmit = e => {
    e.preventDefault()
    let errorCount = 0

    console.log("top of handleRegisterOneUserSubmit")

    // first is to check if all required forms are filled
    if (hofs.isPopulated(lastName)) {
      setLastNameError(false)
    } else {
      errorCount++
      setLastNameError(true)
    }


    if (passwordScore < 3) {
      //we want a stronger password than that
      errorCount++
      setPasswordError(true)
    } else {
      if (hofs.isPopulated(password)) {
        setPasswordError(false)
      } else {
        errorCount++
        setPasswordError(true)
      }
    }
    if (hofs.isPopulated(firstName)) {
      setFirstNameError(false)
    } else {
      errorCount++
      setFirstNameError(true)
    }

    if (hofs.isPopulated(lastName)) {
      setLastNameError(false)
    } else {
      errorCount++
      setLastNameError(true)
    }

    if (hofs.isPopulated(email)) {
      if (hofs.validateEmail(email)) {
        setEmailError(false)
        setEmailErrorMessage('')
      } else {
        setEmailError(false)
        setEmailError(true)
      }
      if (hofs.isPopulated(emailVerifier)) {
        if (emailVerifier == email) {
          setEmailVerifierErrorMessage('')
          setEmailVerifierError(false)
        } else {
          errorCount++
          setEmailVerifierError(true)
          //only one message so far...no need to change it
        }
      } else {
        errorCount++
        setEmailVerifierError(true)
      }
    } else {
      errorCount++
      setEmailError(true)
    }

    if (hofs.isPopulated(passwordVerifier)) {
      if (passwordVerifier === password) {
        setPasswordVerifierError(false)
        setPasswordVerifierErrorMessage('')
      } else {
        errorCount++
        setPasswordVerifierError(true)
        setPasswordVerifierErrorMessage(
          'Your password verifier must match the password inserted above.  Please check and make sure both match'
        )
      }
    } else {
      errorCount++
      setPasswordVerifierError(true)
      setPasswordVerifierErrorMessage(
        'You must insert a password verifier that matches your password to complete submission'
      )
    }

    if (agreement === false) {
      console.log("agreement is false")
      setModalOpen(true)
      errorCount++
    }

    // need to check if email is unique with backend
    if (errorCount === 0) {
      // there are no errors...send the data to backend to complete registration

      console.log("there are no errors in form...show verify email modal")


      const fetchParams = {
        method: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          industry: industry,
          company_name: companyName,
          email: email,
          password: password,
          howYouHearAboutUs: howYouHearAboutUs,
        })
      }
      console.log("registration parameters are:" )
      console.dir(fetchParams)
      const errorMessage = 'Error creating your account.  Please contact support to do so.'
      ;(async () => {
        const response = await fetchData('/registration/', errorMessage, fetchParams).promise

        console.log("registration response is:")
        console.dir(response)

        //new process does not warn user of duplicate emails...just acts as if new email and sends fake emails

        // new user inserted successfully
        setRegistrationError(response.error)
        if (!response.error) {
          // show new license key after email verification
          setRegistrationStep(1)
          setModalVerifyOpen(false)
        } else {
          //there is an error....what to do?
          console.log("registration error is: " + response.error_message)
        }
      })()



    }
  }

  const handleModalClose = ( event, reason ) => {
    if (reason !== 'backDropClick') {
      //agreement has been agreed to
      setAgreement(true)
      setModalOpen(false)
    } else {
      //backdrop clicked....only close on button click

      //alternatively....check that close button clicked...maybe if event is not "agree button click"
    }
  }

  const handleModalVerifyClose = (event, reason ) => {
    if (reason !== 'backDropClick') {
      //agreement has been agreed to
      setRegistrationStep(1)
      setModalVerifyOpen(false)
    } else {
      //backdrop clicked....only close on button click

      //alternatively....check that close button clicked...maybe if event is not "agree button click"
    }
  }

  const termsClick = () => {
    window.open(
      'https://def-logix.com/legal-2',
      '_blank',
      'location=yes,height=700,width=800,scrollbars=yes,status=yes'
    )
  }

  return (
    <>
    <Container component='main' maxWidth='sm'>
      <div className={classes.paper}>
        <Avatar className={classes.avatar} src='/def-logix-d-larger.png' />
        <Typography component='h1' variant='h5'>
          Sign up
        </Typography>

        <form className={classes.form} noValidate onSubmit={handleRegisterOneUserSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name='first_name'
                variant='outlined'
                required
                fullWidth={true}
                value={firstName}
                id='firstName'
                label='First Name'
                onChange={handleFirstNameChange}
                helperText={firstNameError ? firstNameErrorMessage : null}
                error={firstNameError}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant='outlined'
                required
                fullWidth
                id='lastName'
                label='Last Name'
                name='last_name'
                autoComplete='lname'
                onChange={handleLastNameChange}
                helperText={lastNameError ? lastNameErrorMessage : null}
                error={lastNameError}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='industry'
                name='industry'
                select
                label='Industry'
                value={industry}
                onChange={handleIndustryChange}
                variant='outlined'
                fullWidth
                // helperText={industryError ? industryErrorMessage : null}
                // error={industryError}
                inputProps={{ maxLength: 50 }}
              >
                {industryList.map((option, key) => (
                  <MenuItem key={key} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                fullWidth
                id='company_name'
                label='Company Name'
                name='company_name'
                autoComplete='company name'
                onChange={handleCompanyChange}
                // helperText={companyNameError ? companyNameErrorMessage : null}
                // error={companyNameError}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='howyouhearaboutus'
                name='howyouhearaboutus'
                label='How Did You Hear About Us'
                value={howYouHearAboutUs}
                onChange={handleHowYouHearAboutUs}
                variant='outlined'
                fullWidth
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                onChange={handleEmailChange}
                helperText={emailError ? emailErrorMessage : null}
                onBlur={validateEmailTLD}
                error={emailError}
                inputProps={{ maxLength: 255 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                id='email_verifier'
                label='Email Address Verifier'
                name='email_verifier'
                autoComplete='email'
                onChange={handleEmailVerifierChange}
                helperText={emailVerifierError ? emailVerifierErrorMessage : null}
                error={emailVerifierError}
                inputProps={{ maxLength: 255 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                type={valuesPassword.showPassword ? 'text' : 'password'}
                required
                fullWidth
                id='password'
                label='Password'
                name='password'
                autoComplete='password'
                onChange={handlePasswordChange}
                helperText={passwordError ? passwordErrorMessage : null}
                error={passwordError}
                inputProps={{ maxLength: 100 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {valuesPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <PasswordStrengthBar
                password={password}
                onChangeScore={(score,feedback) => {
                    setPasswordScore(score)
                  }
                } />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                type={valuesPasswordVerifier.showPasswordVerifier ? 'text' : 'password'}
                required
                fullWidth
                id='password_verifier'
                label='Password Verifier'
                name='password_verifier'
                autoComplete='password'
                onChange={handlePasswordVerifierChange}
                helperText={passwordVerifierError ? passwordVerifierErrorMessage : null}
                error={passwordVerifierError}
                inputProps={{ maxLength: 100 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password verifier visibility'
                        onClick={handleClickShowPasswordVerifier}
                        onMouseDown={handleMouseDownPasswordVerifier}
                      >
                        {valuesPasswordVerifier.showPasswordVerifier ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>



          </Grid>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
            // disabled={checkingForDuplicateEmail}
          >
            Click to sign up
          </Button>
          <Grid container justify='flex-end'>
            <Grid item>
              <Link href='/login' variant='body2'>
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Modal
        aria-labelledby='spring-modal-title-agreement'
        aria-describedby='spring-modal-description-agreement'
        className={classes.modal}
        open={modalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        disableEscapeKeyDown={true}
        disableBackdropClick
      >
        <Fade in={modalOpen}>
          <div className={classes.modalPaper}>
            <h2 id='spring-modal-title'>Def-Logix Security Manager Tool Registration </h2>
            <p id='spring-modal-description' style={{ fontSize: '1rem' }}>
              Please agree to our license agreement and end user policy
              <br /><br />
              <Grid container>
                <Grid item xs={12} style={{ paddingBottom: '25px'}}>
                  <Typography variant="subtitle2">{agreementErrorMessage}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button startIcon={<OpenInNew />} variant='outlined' onClick={termsClick} color='primary'>
                    Click to view the end user license agreement and and privacy policy
                  </Button>
                </Grid>

                <Grid item xs={12} style={{paddingTop: '25px'}}>
                  <Button startIcon={<ThumbUpAltSharp />} variant='contained' onClick={handleAgreementChange} color='primary'>
                    Click To Agree To Our Terms
                  </Button>
                </Grid>
              </Grid>
            </p>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby='spring-modal-title-verify-email'
        aria-describedby='spring-modal-description-verify-email'
        className={classes.modal}
        open={modalVerifyOpen}
        onClose={handleModalVerifyClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        disableEscapeKeyDown={true}
        disableBackdropClick
      >
        <Fade in={modalVerifyOpen}>
          <div className={classes.modalPaper}>
            <h2 id='spring-modal-title'>Def-Logix Security Manager Tool Registration </h2>
            <p id='spring-modal-description' style={{ fontSize: '1rem' }}>
              Please verify your email address to move to the next step in the process
              <br /><br />
              <Grid container>
                <Grid item xs={12} style={{ paddingBottom: '25px'}}>
                  <Typography variant="subtitle2">You've been sent an email to the email address included within your form.  Open that email and click the link within it.  After verification
                  you will be redirected here to complete your registration</Typography>
                </Grid>
                <Grid item xs={12} style={{paddingTop: '25px'}}>
                  <Button startIcon={<ThumbUpAltSharp />} variant='contained' onClick={verifyClick} color='primary'>
                    I've verified my email
                  </Button>
                </Grid>
              </Grid>
            </p>
          </div>
        </Fade>
      </Modal>
    </Container>
    </>
  )
}

export default RegisterOneUser
