import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'
import { Redirect } from 'react-router-dom'
import images from 'react-payment-inputs/images'
import {
  Table,
  Backdrop,
  Button,
  Typography,
  TextField,
  TableBody,
  TableRow,
  TableCell,
  Link,
  Grid,
  Avatar,
  Container,
  FormControl,
  MenuItem,
  Modal,
  Fade,
  InputAdornment,
  IconButton
} from '@material-ui/core'

import fetchData from '../../utility/endpointFetch'
import { stateNameList } from '../../StaticData/stateNameList'
import { stateAbbreviationList } from '../../StaticData/stateAbbreviationList'
import hofs from '../../utility/hofs'
import Footer from '../Footer'

import { getLoginStyles } from './LoginStyles'
import { usePaymentInputs, PaymentInputsWrapper } from 'react-payment-inputs/es/'


const address1ErrorMessage = 'Business address is required'
const cityErrorMessage = 'City is required'
const stateErrorMessage = 'State is required'
const postalCodeErrorMessage = 'Postal code is required'
const creditCardNumberErrorMessage = 'Insert your credit card number'
const cvcNumberErrorMessage = 'Insert the number from the back of your credit card'
const nameOnCardErrorMessage = 'Insert name on card'
const expiryErrorMessage = 'Insert your credit card expiration date'

const LoginCollectCCInfo = ( { fromRegistration=false }) => {
  const classes = getLoginStyles()

  const history = useHistory()

  const [creditCardNumber, setCreditCardNumber] = useState('')
  const [creditCardNumberError, setCreditCardNumberError] = useState(false)
  const [expiry, setExpiry] = useState('')
  const [expiryError, setExpiryError] = useState(false)
  const [cvcNumber, setcvcNumber] = useState('')
  const [cvcNumberError, setcvcNumberError] = useState(false)
  const [nameOnCard, setNameOnCard] = useState('')
  const [nameOnCardError, setNameOnCardError] = useState(false)

  const [address1, setAddress1] = useState()
  const [address1Error, setAddress1Error] = useState(false)
  const [address2, setAddress2] = useState()
  const [city, setCity] = useState()
  const [cityError, setCityError] = useState(false)
  const [state, setState] = useState('')
  const [stateError, setStateError] = useState(false)
  const [postalCode, setPostalCode] = useState()
  const [postalCodeError, setPostalCodeError] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState()
  const [processingPayment, setProcessingPayment] = useState(false)
  const [processingErrorMessage, setProcessingErrorMessage] = useState('')
  const [redirectTo, setRedirectTo] = useState(false)

  const [registrationError, setRegistrationError] = useState(false)

  const { wrapperProps, meta, getCardNumberProps, getExpiryDateProps, getCVCProps, getZIPProps, getCardImageProps } =
    usePaymentInputs()

  const [modalOpen, setModalOpen] = React.useState(false)

  const [isLoading,setIsLoading] = useState(true)

  const handleChangeCardNumber = e => setCreditCardNumber(e.target.value)
  const handleChangeExpiryDate = e => setExpiry(e.target.value)
  const handleChangeCVC = e => setcvcNumber(e.target.value)
  const handleChangeZip = e => setPostalCode(e.target.value)
  const handleNameOnCardChange = e => setNameOnCard(e.target.value)

  const handleAddress1Change = e => setAddress1(e.target.value)
  const handleAddress2Change = e => setAddress2(e.target.value)
  const handlePhoneNumberChange = e => setPhoneNumber(e.target.value)
  const handleCityChange = e => setCity(e.target.value)
  const handleStateChange = e => setState(e.target.value)
  const handlePostalCodeChange = e => setPostalCode(e.target.value)

  const handleRegisterPaymentSubmit = e => {
    e.preventDefault()
    let errorCount = 0

    //need to check name and address fields before submission
    if (hofs.isPopulated(nameOnCard)) setNameOnCardError(false)
    else {
      setNameOnCardError(true)
      errorCount++
    }

    if (hofs.isPopulated(address1)) {
      setAddress1Error(false)
    } else {
      errorCount++
      setAddress1Error(true)
    }

    if (hofs.isPopulated(city)) {
      setCityError(false)
    } else {
      errorCount++
      setCityError(true)
    }

    if (hofs.isPopulated(state)) {
      setStateError(false)
    } else {
      errorCount++
      setStateError(true)
    }

    if (hofs.isPopulated(postalCode)) {
      setPostalCodeError(false)
    } else {
      errorCount++
      setPostalCodeError(true)
    }

    if (hofs.isPopulated(creditCardNumber)) setCreditCardNumberError(false)
    else {
      setCreditCardNumberError(true)
      errorCount++
    }

    if (hofs.isPopulated(expiry)) setExpiryError(false)
    else {
      setExpiryError(true)
      errorCount++
    }

    if (hofs.isPopulated(cvcNumber)) setcvcNumberError(false)
    else {
      setcvcNumberError(true)
      errorCount++
    }

    if (errorCount === 0) {
      // there are no errors...send the data to backend to complete registration

      setProcessingPayment(true)

      const fetchParams = {
        method: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          card: creditCardNumber,
          expiration: expiry,
          cvc: cvcNumber,
          postal_code_card: postalCode,
          billing_address_1: address1,
          billing_address_2: address2,
          billing_city: city,
          billing_state: state,
          name_on_card: nameOnCard,
          phone_number: phoneNumber,
        })
      }

      const errorMessage = 'Error setting up customer account for stripe'
      ;(async () => {
        const response = await fetchData('/payments', errorMessage, fetchParams).promise
        console.log("response from sending in cc info is:")
        console.dir(response)
        if (response.processing_error) {
          // there was an error processing payment
          setProcessingPayment(false)
          setModalOpen(true)
          setProcessingErrorMessage(response.processing_error_message)
        } else {
          setProcessingErrorMessage(false)
          setProcessingPayment(false)
          //history.push('/')
          //we're already there...see if this works
          console.log("trying to redirect in processing payment error false ...set to false")
          window.location.reload(false);

          //setRedirectTo(true)
        }
      })()



    }
  }

  const handleModalClose = () => {
    if (registrationError) {
      // there was an error....what to do
      setModalOpen(false)

    } else {
      setModalOpen(false)
    }
  }

  const formInstructions = (fromRegistration) => {
    if (fromRegistration) {
      return "Your registration is complete.  You have an account with us.  If you cannot complete this step now you only need to return to this site, login with your user credentials and complete your payment details. <br /><br /> "
    } else {
      return null
    }
  }

  //if (redirectTo) {
  //  console.log("redirectTo is true....redirect attempt...to /login")
  //  return window.location.reload(true);
  //  //return <Redirect to='/login' />
  //} else {
    return (
      <>
        <Container maxWidth='sm' className={classes.container}>
          <div className={classes.paper}>
            <Avatar className={classes.avatar} src='/def-logix-d-larger.png' />
            <Typography component='h1' variant='h5'>
              Complete Your Account's Payment Details
            </Typography>
            <Grid container spacing={3}>
              <Grid item>
              <Typography>
                {formInstructions}
                To complete your account information, please insert your credit card details below. Once submitted, we will setup your subscription details with the Stripe payment gateway to automatically bill each month for your service once your free trial has ended.
              </Typography>
              </Grid>
              <Grid item>
                <form onSubmit={handleRegisterPaymentSubmit} className={classes.paymentform}>
                  <LoadingOverlay
                    active={processingPayment}
                    spinner
                    text='Processing Your Payment...Do not hit refresh or leave the screen until done...'
                    fadeSpeed={200}
                  >
                  <Table >
                    <TableBody>
                      <TableRow>
                        <TableCell align='center' className={classes.tableCell}>
                          <PaymentInputsWrapper {...wrapperProps}>
                            <svg {...getCardImageProps({ images })} />
                            <input
                              {...getCardNumberProps({ onChange: handleChangeCardNumber })}
                              value={creditCardNumber}
                            />
                            <input {...getExpiryDateProps({ onChange: handleChangeExpiryDate })} value={expiry} />
                            <input {...getCVCProps({ onChange: handleChangeCVC })} value={cvcNumber} />
                            <input {...getZIPProps({ onChange: handleChangeZip })} value={postalCode} />
                            {meta.isTouched && meta.error && <span>Error: {meta.error}</span>}
                          </PaymentInputsWrapper>
                          <br />
                          <br />
                          { creditCardNumberError ? <Typography className={classes.cardErrorMessages}>{creditCardNumberError}</Typography> : null }
                          { expiryError ? <Typography className={classes.cardErrorMessages}>{expiryErrorMessage}</Typography> : null }
                          { cvcNumberError ? <Typography className={classes.cardErrorMessages}>{cvcNumberErrorMessage}</Typography> : null }
                          { postalCodeError ? <Typography className={classes.cardErrorMessages}>{postalCodeErrorMessage}</Typography> : null }


                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          <Grid container spacing={2}>
                            <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                              <TextField
                                name='name_on_card'
                                variant='outlined'
                                fullWidth={true}
                                value={nameOnCard}
                                id='nameOnCard'
                                label='Name On Card'
                                onChange={handleNameOnCardChange}
                                helperText={nameOnCardError ? nameOnCardErrorMessage : null}
                                error={nameOnCardError}
                                inputProps={{ maxLength: 100 }}
                              />
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                              <TextField
                                variant='outlined'
                                fullWidth
                                id='address_1'
                                label='Address Line 1'
                                name='address_1'
                                onChange={handleAddress1Change}
                                value={address1}
                                helperText={address1Error ? address1ErrorMessage : null}
                                error={address1Error}
                                inputProps={{ maxLength: 100 }}
                              />
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                              <TextField
                                variant='outlined'
                                fullWidth
                                id='address_2'
                                label='Address Line 2'
                                name='address_2'
                                autoComplete='address_2 name'
                                value={address2}
                                onChange={handleAddress2Change}
                                inputProps={{ maxLength: 100 }}
                              />
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                              <TextField
                                name='city'
                                variant='outlined'
                                fullWidth
                                id='city'
                                label='City'
                                onChange={handleCityChange}
                                value={city}
                                helperText={cityError ? cityErrorMessage : null}
                                error={cityError}
                                inputProps={{ maxLength: 50 }}
                              />
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                              <TextField
                                id='state'
                                name='state'
                                select
                                label='State'
                                value={state}
                                onChange={handleStateChange}
                                variant='outlined'
                                fullWidth
                                helperText={stateError ? stateErrorMessage : null}
                                error={stateError}
                                inputProps={{ maxLength: 25 }}
                              >
                                {stateNameList.map((option, key) => (
                                  <MenuItem key={key} value={stateAbbreviationList[key]}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                              <TextField
                                variant='outlined'
                                fullWidth
                                id='phone_number'
                                label='Phone Number'
                                name='phone_number'
                                onChange={handlePhoneNumberChange}
                                // error={phoneNumberError}
                                inputProps={{ maxLength: 25 }}
                              />
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          <Button type='submit' variant='contained' color='primary'>
                            Submit Payment Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  </LoadingOverlay>
                </form>
              </Grid>
            </Grid>
          </div>
        </Container>
        <Footer />
        <Modal
          aria-labelledby='spring-modal-title'
          aria-describedby='spring-modal-description'
          className={classes.modal}
          open={modalOpen}
          onClose={() => {
            setModalOpen(false)
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <Fade in={modalOpen}>
            <div className={classes.modalPaper}>
              <h2 id='spring-modal-title'>There Was An Error With Your Payment Attempt</h2>
              <p id='spring-modal-description' style={{ fontSize: '1rem' }}>
                The specific error returned is:
                <br /><br />
                <Typography variant="h6">{processingErrorMessage}</Typography>
                <br /><br />
                If you feel you made an error or wish to try another card click try again and reinsert you card details
              </p>
              <Button
                variant='contained'
                style={{ backgroundColor: '#128C01', color: '#FFFFFF', maxWidth: '300px', marginTop: '10px' }}
                size='small'
                onClick={() => {
                  setModalOpen(false)
                  setProcessingErrorMessage('')
                }}
              >
                Return To Payment
              </Button>
            </div>
          </Fade>
        </Modal>
      </>
    )
  //}
}

export default LoginCollectCCInfo
