import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Paper, Button, Typography, Grid, Container, TextField } from '@material-ui/core'

import fetchData from '../../../utility/endpointFetch'
import hofs from '../../../utility/hofs'
import { getAppStyles } from '../../App/AppStyles'

const emailErrorMessageBasic = 'A valid Email address is required'
const emailErrorMessageDuplicate =
  'That email already exists within our system attached to a registered account.  Please try another'
const emailErrorMessageDuplicateCode =
  'That email is already attached to a invitation code within our system.  Return to invitation codes to find it'

const AdminInvitationCodesNew = () => {
  const classes = getAppStyles()
  const history = useHistory()

  const [email, setEmail] = useState()
  const [emailError, setEmailError] = useState(false)
  const [emailErrorMessage, setEmailErrorMessage] = useState(emailErrorMessageBasic)

  const handleEmailChange = e => setEmail(e.target.value)

  const handleNewCodeSubmit = e => {
    e.preventDefault()
    let errorCount = 0
    setEmailErrorMessage('')

    if (hofs.isPopulated(email)) {
      if (hofs.validateEmail(email)) {
        setEmailError(false)
      } else {
        errorCount++
        setEmailErrorMessage(emailErrorMessageBasic)
        setEmailError(true)
      }
    } else {
      errorCount++
      setEmailError(true)
    }

    if (errorCount === 0) {
      // there are no errors...send the data to backend to complete email check
      const errorMessage = 'Error creating your invitation code.'
      const fetchParams = {
        method: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      }
      ;(async () => {
        const response = await fetchData('/registration/admin/invitationcodes', errorMessage, fetchParams).promise
        if (response.duplicate_email) {
          // this email already exists in our system
          setEmailError(true)
          setEmailErrorMessage(emailErrorMessageDuplicate)
        } else if (response.duplicate_email_invitation_code) {
          setEmailError(true)
          setEmailErrorMessage(emailErrorMessageDuplicateCode)
        } else {
          // insertion of new invitation code successful
          history.push('/admin/invitationcodes')
        }
      })()
    }
  }

  return (
    <Container maxWidth='xl' className={classes.container}>
      <Paper className={classes.paper}>
        <Typography align='left' variant='h5' style={{ fontWeight: '700' }}>
          Add New Invitation Code For Potential Client
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleNewCodeSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                value={email}
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                onChange={handleEmailChange}
                helperText={emailError ? emailErrorMessage : null}
                error={emailError}
                inputProps={{ maxLength: 255 }}
              />
            </Grid>
          </Grid>
          <Button type='submit' variant='contained' color='primary' className={classes.submit}>
            Add New Invitation Code
          </Button>
        </form>
      </Paper>
    </Container>
  )
}

export default AdminInvitationCodesNew
