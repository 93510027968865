import React from "react";
import { Typography, Container } from '@material-ui/core'
import QRCode from "react-qr-code";

const ShowTOTP = ({ totpProvisioningURI, totpCode }) => {

  if ((totpProvisioningURI == "") || (totpProvisioningURI == undefined)) {
    return <Typography variant="caption">Turn ON time-based password use by clicking button to left</Typography>
  } else {
    return (
      <Container>
        <QRCode value={totpProvisioningURI} size={110} /><br />
        <Typography variant="caption">TOTP key if needed:<br />{totpCode}</Typography>
      </Container>
    )
  }
}

export default ShowTOTP